// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';
export const CHANGE_CONTENT_FIXED = 'change-content-fixed';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';
export const CHANGE_HOME_NAVIGATION_STYLE = 'change_home_navigation_style';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

//Contact Module const

export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';















export const ON_TOGGLE_DRAWER = 'on_toggle_drawer';
export const HANDLE_REQUEST_CLOSE = 'handle_request_close';


//Auth const













export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';
export const USER_DATA = 'user_data';
export const USER_DATA_UPDATE = 'user_data_update';
export const USER_TOKEN_SET = 'user_token_set';
export const USER_TOKEN_EXPIRE = 'user_token_expire';
export const TWO_STEP_UPDATING = 'two_step_updating';
export const TWO_STEP_UPDATED = 'two_step_updated';


export const SHOW_ALERT = 'show_alert';
export const PASSWORD_CHANGING = 'password_changing';
export const PASSWORD_CHANGED = 'password_changes';
export const PASSWORD_CHANGING_FAILED = 'password-changing_failed';
export const COMMON_WALLETS_LIST_FETCHED = 'common_wallets_list_fetched';
export const FETCHING_WALLET_ADDRESSES_LIST = 'fetching_wallet_addresses_list';
export const COMMON_WALLET_ADDING = 'common_wallet_adding';
export const COMMON_WALLET_ADDED = 'common_wallet_added';
export const COMMON_WALLET_ADD_DONE = 'common_wallet_add_done';
export const COMMON_WALLET_REMOVING = 'common_wallet_removing';
export const COMMON_WALLET_REMOVED = 'common_wallet_removed';
export const COMMON_WALLET_REMOVE_DONE = 'common_wallet_remove_done';
export const SWITCH_DELETE_WALLET_DIALOG = 'switch_delete_wallet_dialog';
export const SWITCH_ADD_WALLET_DIALOG = 'switch_add_wallet_dialog';
export const SIGNING_IN = 'signing_in';
export const SIGNIN_FAILED = 'signin_failed';
export const SIGNIN_SUCCESS = 'signin_success';



export const SET_SIGNIN_STEP = 'set_login_step';
export const SIGNING_UP = 'signing_up';
export const SIGNUP_FAILED = 'signup_failed';
export const SIGNUP_SUCCESS = 'signup_success';



export const SET_SIGNUP_STEP = 'set_register_step';
export const PASSWORD_RESET_SUCCESS = "password_reset_success";
export const PASSWORD_RESET_FAILED = "password_reset_failed";
export const SET_FORGET_PASSWORD_STEP = "set_forget_password_step";
export const SENDING_RESET_PASSWORD_REQUEST = "sending_reset_password_request";
export const UPDATE_CAPTCHA_VERSION = "update_captcha_version";
export const UPDATE_TERMINAL_INFO = "update_terminal_info";
export const FETCHING_USER_SCORES = "fetching_user_scores";
export const USER_SCORES_FETCHED = "user_scores_fetched";
export const UPDATE_USER_SCORES = "update_user_scores";
export const FETCHING_USER_SCORES_FAILED = "fetching_user_scores_failed";

export const SWITCH_AUTH_DIALOG = "switch_auth_dialog";
export const AUTH_MODE_SELECTOR = "auth_mode_selector"


//Shopping
export const REQUESTING_SHOPPING_BUY = 'requesting_shopping_buy';
export const SHOPPING_BUY_REQUESTED = 'shopping_buy_requested';
export const SHOPPING_BUY_FAILED = 'shopping_buy_failed';
export const REQUESTING_SHOPPING_SELL = 'requesting_shopping_sell';
export const SHOPPING_SELL_REQUESTED = 'shopping_sell_requested';
export const SHOPPING_SELL_FAILED = 'shopping_sell_failed';
export const REQUESTING_SHOPPING_CHANGE = 'requesting_shopping_change';
export const SHOPPING_CHANGE_REQUESTED = 'shopping_change_requested';
export const SHOPPING_CHANGE_FAILED = 'shopping_change_failed';
export const REQUESTING_CHECKOUT_BUY = 'requesting_checkout_buy';
export const CHECKOUT_BUY_REQUESTED = 'checkout_buy_requested';
export const CHECKOUT_BUY_FAILED = 'checkout_buy_failed';
export const REQUESTING_CHECKOUT_SELL = 'requesting_checkout_sell';
export const CHECKOUT_SELL_REQUESTED = 'checkout_sell_requested';
export const CHECKOUT_SELL_FAILED = 'checkout_sell_failed';
export const ORDER_FETCHED = 'order_fetched';
export const FETCHING_ORDER = 'fetching_order';
export const UPDATE_NETWORK = 'update_network';
export const NEW_NETWORK = 'new_network';
export const NETWORKS_FETCHED = 'networks_fetch';
export const FETCHING_NETWORKS_FAILED = 'fetching_networks_failed';
export const FETCHING_NETWORKS = 'fetching_networks';
export const FETCHING_ORDER_INFO = 'etching_order_info';
export const ORDER_INFO_FETCHED = 'order_info_fetched';
export const ORDER_INFO_FAILED = 'order_info_failed';
export const REQUESTING_ORDER_CANCEL = 'requesting_order_cancel';
export const CANCEL_REQUEST_FAILED = 'cancel_request_failed';
export const CANCEL_REQUEST_FETCHED = 'cancel_request_fetched';


//CreditCards
export const CREDIT_CARDS_FETCHED = 'credit_cards_fetched';
export const CREDIT_CARD_ADDED = 'credit_card_added';
export const CREDIT_CARD_REMOVED = 'credit_card_removed';
export const CREDIT_CARD_ADDING = 'credit_card_adding';
export const CREDIT_CARD_REMOVING = 'credit_card_removing';
export const CREDIT_CARD_ADD_DONE = 'credit_card_add_done';
export const CREDIT_CARD_REMOVE_DONE = 'credit_card_remove_done';
export const SWITCH_DELETE_DIALOG = 'switch_delete-dialog';

//Wallet
export const REQUESTING_ONLINE_DEPOSIT = 'requesting_online_deposit';
export const ONLINE_DEPOSIT_REQUESTED = 'online_deposit_requested';
export const REQUESTING_C2C_DEPOSIT = 'requesting_c2c_deposit';
export const C2C_DEPOSIT_REQUESTED = 'c2c_deposit_requested';
export const REQUESTING_OFFLINE_DEPOSIT = 'requesting_offline_deposit';
export const OFFLINE_DEPOSIT_REQUESTED = 'offline_deposit_requested';
export const REQUESTING_WITHDRAW = 'requesting_withdraw';
export const WITHDRAW_REQUESTED = 'withdraw_requested';
export const REQUESTING_PM_DEPOSIT = 'requesting_pm_deposit';
export const PM_DEPOSIT_REQUESTED = 'pm_deposit_requested';
export const PM_DEPOSIT_FAILED = 'pm_deposit_failed';
export const REQUESTING_CHANGE_CHECKOUT = 'requesting_change_checkout';
export const CHANGE_CHECKOUT_REQUESTED = 'change_checkout_requested';
export const CHANGE_CHECKOUT_FAILED = 'change_checkout_failed';
export const ONLINE_DEPOSIT_FAILED = 'online_deposit_failed';
export const C2C_DEPOSIT_FAILED = 'c2c_deposit_failed';
export const OFFLINE_DEPOSIT_FAILED = 'offline_deposit_failed';
export const WITHDRAW_FAILED = 'withdraw_deposit_failed';
export const FETCHING_TRANS_INFO = 'fetching_trans_info';
export const TRANS_INFO_FETCHED = 'trans_info_fetched';
export const TRANS_INFO_FAILED = 'trans_info_failed';
export const SWITCH_DEPOSIT_DIALOG = 'switch_deposit_dialog';
export const SWITCH_WITHDRAW_DIALOG = 'switch_withdraw_dialog';
export const REQUESTING_GENERATE_IDENTITY = 'requesting_generate_identity';
export const DEPOSIT_IDENTITY_GENERATED = 'deposit_identity_generated';
export const GENERATE_IDENTITY_FAILED = 'generate_identity_failed'

export const USE_GATEWAY = 'use_gateway';
export const FETCHING_GATEWAYS = 'fetching_gateways';
export const GATEWAYS_FETCHED = 'gateways_fetched';
export const FETCHING_GATEWAYS_FAILED = 'fetching_gateways_failed';


// export const CREDIT_CARD_ADD_DONE = 'credit_card_add_done';

//AUTHORIZATION const
export const EMAIL_CODE_SENT = 'email_code_sent';
export const EMAIL_VERIFIED = 'email_verified';
export const FETCHING_DATA = 'fetching_data';
export const FETCH_DONE = 'fetch_done';
export const PHONE_CODE_SENT = 'phone_code_sent';
export const PHONE_VERIFIED = 'phone_verified';
export const SET_AUTH_STEP = 'set_auth_step';
export const USER_INFO_SUBMITTED = 'user_info_submitted';
export const USER_SELFIE_SUBMITTED = 'user_selfie_submitted';
export const UNSELECT_TICKET = 'unselect_ticket';
export const USER_RESIDENTIAL_INFO_SUBMITTED = 'user_residential_info-submitted';
export const UPLOAD_PROGRESS = 'upload_progress';
export const LAST_AUTH_FETCHED = 'last_auth_fetched';

// Chat Module const








export const ON_HIDE_LOADER = 'on_hide_loader';
export const USER_INFO_STATE = 'user_info_state';






/// Wallet
export const TRANSACTIONS_LIST_FETCHED = 'transactions_list_fetched';
export const DEPOSITS_LIST_FETCHED = 'deposits_list_fetched';
export const WITHDRAWS_LIST_FETCHED = 'withdraws_list_fetched';
export const FETCHING_TRANSACTIONS_LIST = 'fetching_transactions_list';
export const FETCHING_DEPOSITS_LIST = 'fetching_deposits_list';
export const FETCHING_WITHDRAWS_LIST = 'fetching_withdraws_list';
export const REDRAW_TRANSACTIONS_LIST = 'redraw_transactions_list';
export const SWITCH_TRANS_INFO_DIALOG = 'switch_trans_info_dialog';

// Currency
export const PRICE_UPDATE_SETTINGS_FETCHED = 'price_update_settings_fetched';
export const CURRENCIES_FETCHED = 'currencies_fetched';
export const SUPPLIES_FETCHED = 'supplies_fetched';
export const SWITCH_BUY_DIALOG = 'switch_buy_dialog';
export const SWITCH_SELL_DIALOG = 'switch_sell_dialog';
export const SWITCH_CHANGE_DIALOG = 'switch_change_dialog';
export const SWITCH_DEPOSIT_CRYPTO_DIALOG = 'switch_deposit_crypto_dialog';
export const SWITCH_WITHDRAW_CRYPTO_DIALOG = 'switch_withdraw_crypto_dialog';
export const SWITCH_CANCEL_DIALOG = 'switch_cancel_dialog';
export const TRANSFERS_LIST_FETCHED = 'transfers_list_fetched';
export const FETCHING_TRANSFERS_LIST = 'fetching_transfers_list';
export const REDRAW_TRANSFERS_LIST = 'redraw_transfers_list';
export const AVAIL_CURRENCIES_FETCHED = 'avail_currencies_fetched';
export const BASE_CURRENCIES_FETCHED = 'base_currencies_fetched';
export const TRANSLATES_FETCHED = 'translates_fetched';
export const INSTANT_BANKS_FETCHED = 'instant_banks_fetched';


//Support
export const SUBMITTING_TICKET = 'submitting_ticket';
export const TICKET_SUBMITTED = 'ticket_submitted';
export const TICKET_SUBMIT_FAILED = 'ticket_submit_failed';
export const FETCHING_LAST_LIVE_CHAT = 'fetching_last_live_chat';
export const LAST_LIVE_CHAT_FETCHED = 'last_live_chat_fetched';
export const LAST_LIVE_CHAT_FAILED = 'last_live_chat_failed';
export const FETCHING_TICKETS_LIST = 'fetching_tickets_list';
export const TICKETS_LIST_FETCHED = 'tickets_list_fetched';
export const FETCHING_TICKET_MESSAGES = 'fetching_ticket_messages';
export const SUBMITTING_TICKET_MESSAGE = 'submitting_ticket_message';
export const TICKET_MESSAGES_FETCHED = 'ticket_messages_fetched';
export const TICKET_MESSAGE_SUBMITTED = 'ticket_message_submitted';
export const MESSAGE_SUBMIT_FAILED = 'message_submit_failed';
export const SWITCH_COMPOSE_DIALOG = 'switch_compose_dialog';
export const SWITCH_TICKET_DRAWER = 'switch_ticket_drawer';
export const CLOSING_TICKET = 'closing_ticket';
export const TICKET_CLOSED = 'ticket_closed';
export const TICKET_SEEN = 'ticket_seen';
export const TICKET_TYPING = 'ticket_typing';
export const NEW_TICKET = 'new_ticket';
export const NEW_TICKET_MESSAGE = 'new_ticket_message';
export const NEW_CHATBOX_MESSAGE = 'new_chatbox_message';
export const START_CHATBOX_TICKET = 'start_chatbox_ticket';
export const RATING_TICKET = "rating_ticket";
export const TICKET_RATED = "ticket_rated"

//// Mail Module const



export const GET_ALL_MAIL = 'get_all_mail';
export const FETCH_ALL_MAIL = 'fetch_all_mail';

export const GET_IMPORTANT_MAIL = 'get_important_mail';
export const GET_NAV_FILTERS = 'get_nav_filters';
export const GET_NAV_LABELS = 'get_nav_labels';
export const GET_NAV_FOLDER = 'get_nav_folder';
export const GET_READ_MAIL = 'get_read_mail';
export const GET_STARRED_MAIL = 'get_starred_mail';
export const GET_UNIMPORTANT_MAIL = 'get_unimportant_mail';
export const GET_UNREAD_MAIL = 'get_unread_mail';
export const GET_UNSELECTED_ALL_MAIL = 'get_unselected_all_mail';
export const GET_UNSTARRED_MAIL = 'get_unstarred_mail';
export const ON_ALL_MAIL_SELECT = 'on_all_mail_select';
export const ON_FOLDER_MENU_ITEM_SELECT = 'on_folder_menu_item_select';
export const ON_FOLDER_SELECT = 'on_folder_select';
export const ON_IMPORTANT_SELECT = 'on_important_select';
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select';

export const ON_LABEL_SELECT = 'on_label_select';
export const ON_MAIL_CHECKED = 'on_mail_checked';
export const ON_MAIL_SELECT = 'on_mail_select';
export const ON_MAIL_SEND = 'on_mail_send';
export const ON_OPTION_MENU_ITEM_SELECT = 'on_option_menu_item_select';
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select';
export const ON_START_SELECT = 'on_start_select';
export const SEARCH_MAIL = 'search_mail';
export const ON_DELETE_MAIL = 'on_delete_mail';
export const SET_CURRENT_MAIL_NULL = 'set_current_mail_null';
export const ON_COMPOSE_MAIL = 'on_compose_mail';

//// TO-DO Module const
























export const UPDATE_SEARCH = 'update_search';

//Notice System
export const PANEL_USER_NOTICES_FETCHED = 'panel_user_notices_fetched';
export const NEW_PANEL_USER_NOTICE = 'new_panel_user_notice';
export const SEEN_USER_NOTICE = 'seen_user_notice';
export const SYSTEM_NOTICES_FETCHED = 'system_notices_fetched';
export const NEW_SYSTEM_NOTICE = 'new_system_notice';
export const SEEN_SYSTEM_NOTICE = 'seen_system_notice';


export const FETCHING_NOTICES_LIST = 'fetching_notices_list';
export const FETCHING_SYSTEM_NOTICES_LIST = 'fetching_system_notices_list';
export const SYSTEM_NOTICES_LIST_FETCHED = 'system_notices_list_fetched';
export const NOTICES_LIST_FETCHED = 'notices_list_fetched';
export const SWITCH_NOTIFICATION_DIALOG = 'switch_notification_dialog';

//Referral System
export const FETCHING_INVITED_LIST = 'fetching_invited_list';
export const INVITED_LIST_FETCHED = 'invited_list_fetched';
export const FETCHING_REFERRAL_PROFIT_LIST = 'fetching_referral_profit_list';
export const REFERRAL_PROFIT_LIST_FETCHED = 'referral_profit_list_fetched';
export const REQUESTING_CHECKOUT_REFERRAL = 'requesting_checkout_referral';
export const CHECKOUT_REFERRAL_REQUESTED = 'checkout_referral_requested';
export const CHECKOUT_REFERRAL_FAILED = 'checkout_referral_failed';
export const FETCHING_REFERRAL_STATS = 'fetching_referral_stats';
export const REFERRAL_STATS_FETCHED = 'referral_stats_fetched';


//Live Chat System
export const SWITCH_CHATBOX = "switch_live_chat_wrapper";
export const SWITCH_LIVE_CHAT_CONTENT_STEPPER = "switch_live_chat_content_stepper";
export const SET_CHAT_CONVERSATION_INFORMATION = "set_chat_conversation_information"
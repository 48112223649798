import React from "react";
import {TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import {idSelected, isInt, lengthBetween} from "../../../../util/Validator";
import IntlMessages from "../../../../util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import {showError} from "../../../components/Notifier";
import {WALLET_OFFLINE_DEPOSIT} from "../../../../constants/Units";
import {connect} from "react-redux";
import {offlineDeposit, switchDepositDialog, useGateway} from "../../../../actions/Wallet";
import {Link} from "react-router-dom";
import {DIGITAL_CURRENCIES, GATEWAYS} from "../../data/constants"
import {clearCommas, fa2enNumbers} from "../../../../util/Utilities";
import {findBankByPAN} from "@alibaba-aero/griphook";
import {bankIcons} from "../../../../constants/Banks";
import {switchBuyDialog} from "../../../../actions/CurrencyResource";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";


class OfflineForm extends React.Component {

    constructor(props) {
        super(props);
        const {gateways} = this.props;
        this.state = {
            openDepositDescription: false,
            cards: this.props.cards,
            offlineMethodInfo: {},
            minimumDeposit: "",
            inputs: {
                amount: props.amount ?? '',
                followUpNo: "",
                selectCard: (this.props.cards.length > 0 ? this.props.cards[0]['id'] : 0),
                gateway: this.getFirstGatewayID(this.props.gateways),
                currencyType: DIGITAL_CURRENCIES.IRR,
                rulesChecked: true,
            },
            formErrors: {
                amount: '',
                followUpNo: '',
                selectCard: "",
                rulesChecked: '',
            }
        };

        // this.handleRequestCloseDepositDialog = this.handleRequestCloseDepositDialog.bind(this);

        if (gateways.length > 0) {
            let filteredGateways = gateways.filter((gateway) => gateway.gate_type === GATEWAYS.OFFLINE && gateway.depositable > 0);
            if(filteredGateways.length > 0) {
                this.state.offlineMethodInfo = filteredGateways[0]['display_info'];
                this.state.minimumDeposit = filteredGateways[0]['min_deposit'];
            }
        }

    }

    getFirstGatewayID = (gateways) => {

        if (gateways) {
            let filteredGateways = gateways.filter((gateway) => gateway.gate_type === GATEWAYS.OFFLINE && gateway.depositable > 0);
             if(filteredGateways.length)
                return filteredGateways[0]['id'];
        }
        return 0;
    };

    formValidated = () => {
        const {inputs} = this.state;
        const context = this;
        let isValid = true;
        Object.keys(inputs).map(function (key, index) {
            isValid &= context.validateFormValue(key, inputs[key]);
        });
        const {formErrors} = this.state;
        Object.keys(formErrors).map(function (key, index) {
            isValid &= !formErrors[key];
        });
        return isValid;
    };

    validateFormValue = (name, value) => {
        value = fa2enNumbers(value);
        let {formErrors, inputs, minimumDeposit} = this.state;

        switch (name) {
            case 'amount':
                // value = clearCommas(value) ;
                var pRegex = /^[\u0600-\u06FF\s]+$/;
                var EnRegex = /^[a-zA-Z]+$/;

                value = value.replace(pRegex, '');
                value = value.replace(EnRegex, '');
                if (!isInt({input: value}))
                    formErrors.amount = <IntlMessages id="error.content.amount.numeric"/>;
                else if (value < minimumDeposit)
                    formErrors.amount = <><IntlMessages id={"error.content.amount.min.value"}/>
                        {minimumDeposit.toLocaleString()}<IntlMessages id="error.content.amount-min-value-so-on"/></>
                else if (value > 200000000)
                    formErrors.amount = <><IntlMessages id={"error.content.amount.max.value"}/>
                        {(200000000).toLocaleString()}<IntlMessages id="error.content.amount-max-value-so-on"/></>
                else {
                    formErrors.amount = "";
                }
                value = value.toString().length ? parseInt(value) : '';
                break;

            case 'followUpNo':
                value = value.replace(/[^a-zA-Z0-9-—]/gi, '');
                if (!lengthBetween({input: value, max: 22, min: 3}))
                    formErrors.followUpNo = <IntlMessages id="error.content.followUpNo.min.value"/>;
                else
                    formErrors.followUpNo = "";
                break;

            case 'selectCard':
                if (!idSelected({input: value}))
                    formErrors.selectCard = <IntlMessages id="error.content.select_card.null"/>;
                else
                    formErrors.selectCard = "";
                break;

        }
        inputs[name] = value;
        this.setState({formErrors, inputs});
        return !formErrors[name];
    };

    handleChange = e => {
        const {name, value} = e.target;
        this.validateFormValue(name, value);
    };

    componentDidMount() {

    }

    postForm(link, params = []) {
        return (
            <form style={{display: 'none'}} action={link} method="POST" ref={(ref) => this.state.postFormRef = ref}>
                {
                    // params.map((param, id) => {
                    //     <input name={id} value={param}/>
                    // })
                }
            </form>
        )
    }

    componentWillReceiveProps(nextProps, nextContext) {
      const {inputs} = this.state
        if (!inputs.gateway && nextProps.gateways) {
            inputs.gateway = this.getFirstGatewayID(nextProps.gateways);
            let filteredGateways = nextProps.gateways.filter((gateway) => gateway.gate_type === GATEWAYS.OFFLINE);
            if(filteredGateways.length > 0) {
                this.state.offlineMethodInfo = filteredGateways[0]['display_info'];
                this.state.minimumDeposit = filteredGateways[0]['min_deposit'];
            }
        }
        if (nextProps.amount && nextProps.amount !== this.props.amount) {
            inputs.amount = nextProps.amount;
        }
        this.setState({
            cards: nextProps.cards,
            inputs: inputs
        });
        if (!inputs.selectCard) {
            this.validateFormValue('selectCard', nextProps.cards[0] ? nextProps.cards[0]['id'] : 0);
        }
        if (!nextProps.gatewayUsed && nextProps.gateway.link) {
            if (nextProps.gateway.method === 'GET') {
                window.location.href = nextProps.gateway.link;
            } else if (nextProps.gateway.method === 'POST') {

            }
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const {amount, selectCard, gateway, currencyType, followUpNo} = this.state.inputs;
        if (this.formValidated()) {

            this.props.offlineDeposit({
                o_amount: clearCommas(amount)*10,
                card_id: selectCard,
                gateway: gateway,
                gate_type: 7,
                o_currency: currencyType,
                transaction_id: followUpNo,
                transfer_id: this.props.transferID
            });
            this.props.switchDepositDialog({state: false})
        } else
            showError(WALLET_OFFLINE_DEPOSIT, 'form.invalid');

    };

    handleRequestClose = () => {
        this.props.switchDepositDialog({state: false});
        this.props.switchBuyDialog({state: false});
    };

    handleClick = () => {
        const {openDepositDescription} = this.state;
        this.setState({openDepositDescription: !openDepositDescription});

    };

    handleKeyPress = e => {
        if (e.key === 'Enter') {
            this.handleSubmit(e)
        }
    }

    handleCheck = e => {
        const {name, checked} = e.target;
        const{formErrors, inputs} = this.state;
        inputs[name] = !!checked;
        formErrors.rulesChecked =
            !checked ? "پذیرش قوانین الزامی است." : "";
        this.setState({inputs, formErrors});

    };

    render() {
        const {inputs, formErrors, cards,  offlineMethodInfo, openDepositDescription} = this.state;
        const {requestingOffline, loadingCards, fetchingGateways} = this.props;
        return (
            <>
            {
                fetchingGateways ? (
                    <div className="warning-box">
                        <IntlMessages id="button.loading_gateways"/>
                        <CircularProgress size={24}/>
                    </div>
                ) : !inputs.gateway ? (
                    <div className="offline-form-wrapper">
                        <div className="warning-box">
                            <IntlMessages id="error.content.gateway.closed"/>
                        </div>
                    </div>

                ) : (
                    <>

                        <form className="offline-form-wrapper" onSubmit={this.handleSubmit}>
                            <div className="desc-wrapper">
                                <div className="offline-desc-wrapper">
                                    <div className="offline-desc-inner-wrapper">
                                        <IntlMessages id="contents.deposit.offline.description1"/>

                                        <br/>
                                        <span className="text-secondary font-weight-bold">1:  </span>
                                        <IntlMessages id="contents.deposit.offline.description2"/>
                                        {"  " + offlineMethodInfo.card_number }
                                        <br/>
                                        <span className="text-secondary font-weight-bold">2:  </span>
                                        <IntlMessages id="contents.deposit.offline.description6"/>
                                        {"  " + offlineMethodInfo.account_number}
                                        <br/>
                                        <span className="text-secondary font-weight-bold">3:  </span>
                                        <IntlMessages id="contents.deposit.offline.description7"/>
                                        {"  " + offlineMethodInfo.sheba}
                                        <br/>
                                        <IntlMessages id="contents.deposit.offline.description3"/>  {offlineMethodInfo.bank} <IntlMessages id="contents.deposit.offline.description4"/> {offlineMethodInfo.account_name}
                                        <br/>
                                        <IntlMessages id="contents.deposit.offline.description8"/>

                                    </div>

                                </div>
                                <div className="offline-desc-wrapper little">
                                    <div className="offline-desc-inner-wrapper little">
                                        حداقل مبلغ واریز {this.state.minimumDeposit.toLocaleString()} تومان بوده و تا 15 دقیقه پس از ثبت درخواست تائید خواهد شد.(ساعت 07:00 الی 24:00)

                                    </div>
                                </div>
                            </div>

                                    <div className="inputs-wrapper">
                                        <div className="card-amount-input">
                                            <div className="select-card-input">
                                                <div className="desc">
                                                    کارت بانکی تایید شده ی خود را انتخاب کنید.
                                                </div>
                                                <TextField
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    label="انتخاب کارت بانکی"
                                                    select
                                                    onChange={this.handleChange}
                                                    name="selectCard"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={inputs.selectCard}
                                                    disabled={(!loadingCards && !cards.length)}
                                                    error={!!formErrors.selectCard}
                                                    className={formErrors.selectCard ? "error" : null}
                                                    size="small"
                                                    style={cards.length ? {textAlign: 'left ', direction: 'ltr'} : {}}
                                                >
                                                    {
                                                        loadingCards ?
                                                            <MenuItem key={0} value={0} >
                                                                {<IntlMessages id="button.loading_card"/>}
                                                                <CircularProgress size={24}/>
                                                            </MenuItem>
                                                            :
                                                            (
                                                                cards.length === 0 ?
                                                                    <MenuItem key={0} value={0} style={{textAlign: 'right ', direction: 'rtl'}}>
                                                                        {/*{<IntlMessages id="error.field.no_cards_exist"/>}*/}
                                                                        <Link to="/panel/creditcard" onClick={this.handleRequestClose}>
                                                                            <IntlMessages id="title.message.add-credit-card"/>
                                                                        </Link>
                                                                    </MenuItem> :
                                                                    cards.map((option) => (
                                                                        <MenuItem  key={option.id} value={option.id} style={{textAlign: 'left ', direction: 'ltr'}}>
                                                                            <div className="credit-card-wrapper">
                                                                                {findBankByPAN(option.card_number).icon ?
                                                                                    <img alt="alt" className="credit-card-img"
                                                                                         src={bankIcons[findBankByPAN(option.card_number).icon.replace('.svg', '')]}/> : ""}
                                                                                <div className="account-num">
                                                                                    {option.card_number.substring(0, 4) +
                                                                                    option.card_number.substring(4, option.card_number.length - 8).replace(/\d/g, "") +
                                                                                    option.card_number.substring(8, option.card_number.length - 4).replace(/\d/g, "*")
                                                                                    + option.card_number.substring(option.card_number.length - 4)}</div>
                                                                                <div className="credit-card-num">
                                                                                    ({option.account_number})
                                                                                </div>

                                                                            </div>
                                                                        </MenuItem>)
                                                                    ))
                                                    }

                                                </TextField>
                                            </div>
                                            <div className="amount-input">
                                                <div className="desc">
                                                    مبلغ مورد نظر خود را وارد کنید.
                                                </div>
                                                <TextField
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    id="amount"
                                                    label={<IntlMessages id="title.price"/>}
                                                    onChange={this.handleChange}
                                                    fullWidth
                                                    variant="outlined"
                                                    type="number"
                                                    name="amount"
                                                    error={!!formErrors.amount}
                                                    className={formErrors.amount ? "error" : null}
                                                    size="small"
                                                    value={(inputs.amount)}
                                                    InputProps={{
                                                        inputMode: 'numeric',
                                                        endAdornment: <span className="input-end-adornment">{' تومان '}</span>,
                                                    }}
                                                />
                                                <div className="amount-equal">معادل: {((inputs.amount ?? 0) * 10).toLocaleString()} ریال</div>
                                                {formErrors.amount && (
                                                    <div className="error-box">
                                                        <img alt="alt" src={require("../../../../assets/images/V2/error.svg")}/>
                                                        {formErrors.amount}
                                                    </div>
                                                )}

                                            </div>
                                        </div>

                                        <div className="follow-up-input">
                                            <div className="desc">
                                                <IntlMessages id="form.description.transaction_deposit"/>
                                            </div>
                                            <TextField
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                id="followUpNo"
                                                label={<IntlMessages id="title.transaction_number"/>}
                                                onChange={this.handleChange}
                                                onKeyPress={this.handleKeyPress}
                                                fullWidth
                                                variant="outlined"
                                                name="followUpNo"
                                                error={!!formErrors.followUpNo}
                                                className={formErrors.followUpNo ? "error" : null}
                                                size="small"
                                                value={inputs.followUpNo}

                                            >
                                                {formErrors.followUpNo && (
                                                    <div className="error-box">
                                                        <img alt="alt" src={require("../../../../assets/images/V2/error.svg")}/>
                                                        {formErrors.followUpNo}
                                                    </div>
                                                )}
                                            </TextField>
                                        </div>
                                    </div>
                            {/*<div className="rules-check-wrapper">*/}
                            {/*  */}
                            {/*  */}


                            {/*</div>*/}
                            {this.state.formErrors.rulesChecked.length > 0 && (
                                <div className="error-box">
                                <img alt="alt" src={require("../../../../assets/images/V2/error.svg")}/>
                            {this.state.formErrors.rulesChecked}
                                </div>

                            )}
                                    <div className="bottom-wrapper">
                                        <div className="buttons-wrapper">
                                            <div className="confirm-btn-wrapper">
                                                <Button
                                                    variant="contained"
                                                    className="confirm-btn"
                                                    fullWidth
                                                    disabled={requestingOffline}
                                                    onClick={this.handleSubmit}
                                                    type="submit"
                                                >
                                                    {requestingOffline ? <><IntlMessages id="button.requesting_payment"/>
                                                        <CircularProgress size={24} color="inherit"/></> : <IntlMessages id="button.deposit_online"/>}
                                                </Button>
                                            </div>
                                            <div className="cancel-btn-wrapper">
                                                <Button
                                                    variant="contained"
                                                    onClick={this.handleRequestClose}
                                                    fullWidth
                                                    className="cancel-btn"
                                                >
                                                    <IntlMessages id="button.cancel"/>
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="rules-wrapper">
                                            <List
                                                component="nav"
                                                aria-labelledby="nested-list-subheader"
                                                className="rules-list"
                                            >
                                                <ListItem button onClick={() => {
                                                    this.handleClick()
                                                }} className="rules-list-item">
                                                    <ListItemText>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox className="rules-check" tabIndex={5}
                                                                          checked={inputs.rulesChecked} onChange={this.handleCheck}
                                                                          name="rulesChecked"/>
                                                            }
                                                            label={<> <a  className="rules-desc">
                            <span>
                                قوانین واریز وجه

                            </span>

                                                            </a>
                                                                {" "}
                                                                <span>
                               را می پذیرم
                           </span>
                                                            </>}
                                                        />
                                                    </ListItemText>
                                                    {openDepositDescription ? <ExpandLess/> : <ExpandMore/>}
                                                </ListItem>
                                                <Collapse in={openDepositDescription} timeout="auto" unmountOnExit>
                                                    <div className="rules-card">
                                                        <div className="rule-row">
                                                            <IntlMessages id={"contents.deposit.rules1"}/>
                                                        </div>
                                                        <div className="rule-row">
                                                            <IntlMessages id={"contents.deposit.rules2"}/>
                                                        </div>
                                                        <div className="rule-row">
                                                            <IntlMessages id={"contents.deposit.rules3"}/>
                                                        </div>
                                                    </div>
                                                </Collapse>
                                            </List>
                                        </div>
                                    </div>
                                    <TextField
                                        className="d-none"
                                        type="hidden"
                                        name="gateway"

                                    >
                                    </TextField>
                                    <TextField
                                        className="d-none"
                                        type="hidden"
                                        name="currencyType"
                                    > </TextField>


                                </form>
                    </>
                )

            }
            </>
        )
    }
}

const mapStateToProps = ({commonData, wallet, settings}) => {
    const {width} = settings;
    const {hasErrors} = commonData;
    const {requestingOffline, paymentURL, gatewayUsed, gateway, fetchingGateways} = wallet;
    return {requestingOffline, paymentURL, gatewayUsed, gateway, hasErrors, fetchingGateways, width};
};
export default connect(mapStateToProps, {offlineDeposit, useGateway, switchDepositDialog, switchBuyDialog})(OfflineForm);
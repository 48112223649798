import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import {switchNotificationDialog} from "../../../actions/Notice";
import IntlMessages from "../../../util/IntlMessages";
import {toPersianDate} from "../../../util/Utilities";
import {NOTICE_TYPES, NOTICE_UNITS} from "../../../panel/routes/data/constants";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import TextsmsOutlinedIcon from "@material-ui/icons/TextsmsOutlined";
import RepeatOutlinedIcon from "@material-ui/icons/RepeatOutlined";


class NotificationDialog extends React.Component {


    handleRequestClose = (e) => {
        this.props.switchNotificationDialog(false, this.props.selectedNotice.id);
    };

    handleClickOpen = () => {
        this.setState({open: true});
    };
    createUnitIcons(type, unit) {
        if ([
            NOTICE_UNITS.VERIFY_INFO,
            NOTICE_UNITS.AUTH_REJECTED,
            NOTICE_UNITS.AUTH_GRANTED,
            NOTICE_UNITS.VERIFY_MOBILE,
            NOTICE_UNITS.VERIFY_EMAIL,
            NOTICE_UNITS['2FA_CHANGED'],
            NOTICE_UNITS.PASSWORD_CHANGED,
            NOTICE_UNITS.AFFILIATION,
            NOTICE_UNITS.EMAIL_VERIFIED
        ].includes(unit))
            return <SettingsOutlinedIcon className={
                [NOTICE_TYPES.NOTICE_TYPE_DANGER].includes(type) ? "text-danger" : [NOTICE_TYPES.NOTICE_TYPE_NORMAL].includes(type)
                    ? "text-primary" : [NOTICE_TYPES.NOTICE_TYPE_WARNING].includes(type) ? "text-warning" : ""
            }/>;
        else if (
            [
                NOTICE_UNITS.WITHDRAW_SENT,
                NOTICE_UNITS.WITHDRAW_TIME_CHANGED,
                NOTICE_UNITS.WITHDRAW_CANCELED,
                NOTICE_UNITS.CARD_GRANTED,
            ].includes(unit))
            return <AccountBalanceWalletOutlinedIcon className={
                [NOTICE_TYPES.NOTICE_TYPE_DANGER].includes(type) ? "text-danger" : [NOTICE_TYPES.NOTICE_TYPE_NORMAL].includes(type)
                    ? "text-primary" : [NOTICE_TYPES.NOTICE_TYPE_WARNING].includes(type) ? "text-warning" : ""
            }/>;
        else if ([
            NOTICE_UNITS.TICKET_ANSWERED,
            NOTICE_UNITS.TICKET_CLOSED,
            NOTICE_UNITS.ADMIN_NOTICE
        ].includes(unit))
            return <TextsmsOutlinedIcon className={
                [NOTICE_TYPES.NOTICE_TYPE_DANGER].includes(type) ? "text-danger" : [NOTICE_TYPES.NOTICE_TYPE_NORMAL].includes(type)
                    ? "text-primary" : [NOTICE_TYPES.NOTICE_TYPE_WARNING].includes(type) ? "text-warning" : ""
            }/>;
        else if ([
            NOTICE_UNITS.SELL_ADMIN_PROGRESS,
            NOTICE_UNITS.BUY_ORDER_DONE,
            NOTICE_UNITS.MAX_BUY_INCREASED,
            NOTICE_UNITS.SELL_ORDER_DONE,
        ].includes(unit))
            return <RepeatOutlinedIcon className={
                [NOTICE_TYPES.NOTICE_TYPE_DANGER].includes(type) ? "text-danger" : [NOTICE_TYPES.NOTICE_TYPE_NORMAL].includes(type)
                    ? "text-primary" : [NOTICE_TYPES.NOTICE_TYPE_WARNING].includes(type) ? "text-warning" : ""
            }/>
    }

    render() {
        const {notificationDialogOpened, selectedNotice} = this.props;
        return (
            <Dialog className="notification-dialog" open={notificationDialogOpened} onClose={this.handleRequestClose} fullWidth>
                <div className="notification-dialog-title">
                    {this.createUnitIcons(selectedNotice.notice_type, selectedNotice.unit)}
                    {selectedNotice.title}
                </div>
                <div className="notification-dialog-content-wrapper">
                    <div className="notification-dialog-content">
                        {selectedNotice.content}
                    </div>
                    <div className="content-date">
                        {toPersianDate(selectedNotice.created_at)}
                    </div>
                </div>
                <div className="button-wrapper">
                    <div className="confirm-button-wrapper">
                        <Button
                            variant="contained"
                            className="confirm-btn"
                            onClick={this.handleRequestClose}
                            // onClick={(e) => {
                            //     seenUserNotice(selectedNotice.id);
                            //     e.preventDefault();
                            //     e.stopPropagation();
                            // }}
                            // style={{ marginTop: "10px"}}
                            fullWidth
                        >
                            <IntlMessages id="title.understand"/>
                        </Button>
                    </div>
                </div>
            </Dialog>
        )
    }
}

const mapStateToProps = ({notice}) => {
    const {notificationDialogOpened, selectedNotice} = notice;
    return {
        notificationDialogOpened, selectedNotice
    }
};

export default connect(mapStateToProps, {
    switchNotificationDialog,
})(NotificationDialog);


export const isInt = ({input}) => {
    return (/^\d+$/).test(input);
};

export const isFloat = ({input}) => {
    return (/^-?\d*(\.\d+)?$/).test(input);
};

export const isNumeric = ({input}) => {
    return (/^\d+$/).test(input);
};

export const lengthBetween = ({input, min, max}) => {
    return (input.length >= min && input.length <= max);
};

export const charExists = ({input}) => {
    return (typeof input === "string" && input.length > 0);
};

export const isMobile = ({input, type = "IR"}) => {
    let isValid = false;
    let val = input;
    if(type === 'IR')
        isValid = (input.substring(0, 3) === '+98' && (val = input.substring(3))) || (input.substring(0, 2) === '09' && (val = input.substring(2)));
        return isValid && RegExp('^\\d+$').test(val) && val.length >= 9 && val.length <= 13;
};

export const idSelected = ({input}) => {
    return input > 0;
}

export const isEmail = ({input}) => {
    return RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(input);
};
export const minimumAmount = ({input}) => {
    return (input >= 1000)
};
export const isName = ({input}) => {
    return (/^[\u0600-\u06FF\s]+$/).test(input);
};
export const isset = (input) =>  {
    return typeof input !== 'undefined';
}

import Ansar from '../assets/images/banks/Ansar.svg';
import Ayandeh from '../assets/images/banks/Ayandeh.svg';
import Bank_Markazi from '../assets/images/banks/Bank_Markazi.svg';
import Caspian from '../assets/images/banks/Caspian.svg';
import Dey from '../assets/images/banks/Dey.svg';
import Eghtesad_Novin from '../assets/images/banks/Eghtesad_Novin.svg';
import Futurebank from '../assets/images/banks/Futurebank.svg';
import Gardeshgari from '../assets/images/banks/Gardeshgari.svg';
import Ghavamin from '../assets/images/banks/Ghavamin.svg';
import Hekmat from '../assets/images/banks/Hekmat.svg';
import Iran_Europe from '../assets/images/banks/Iran_Europe.svg';
import Iran_Venezuela from '../assets/images/banks/Iran_Venezuela.svg';
import Iran_Zamin from '../assets/images/banks/Iran_Zamin.svg';
import Karafarin from '../assets/images/banks/Karafarin.svg';
import Keshavarzi from '../assets/images/banks/Keshavarzi.svg';
import Khavar_Mianeh from '../assets/images/banks/Khavar_Mianeh.svg';
import Kosar from '../assets/images/banks/Kosar.svg';
import Maskan from '../assets/images/banks/Maskan.svg';
import Mehr_Eghtesad from '../assets/images/banks/Mehr_Eghtesad.svg';
import Mehr_Iran from '../assets/images/banks/Mehr_Iran.svg';
import Melall from '../assets/images/banks/Melall.svg';
import Mellat from '../assets/images/banks/Mellat.svg';
import Melli from '../assets/images/banks/Melli.svg';
import Noor from '../assets/images/banks/Noor.svg';
import Parsian from '../assets/images/banks/Parsian.svg';
import Pasargad from '../assets/images/banks/Pasargad.svg';
import Postbank from '../assets/images/banks/Postbank.svg';
import Refah from '../assets/images/banks/Refah.svg';
import Resalat from '../assets/images/banks/Resalat.svg';
import Saderat from '../assets/images/banks/Saderat.svg';
import Saman from '../assets/images/banks/Saman.svg';
import Sanat_Madan from '../assets/images/banks/Sanat_Madan.svg';
import Sarmayeh from '../assets/images/banks/Sarmayeh.svg';
import Sepah from '../assets/images/banks/Sepah.svg';
import Shahr from '../assets/images/banks/Shahr.svg';
import Sina from '../assets/images/banks/Sina.svg';
import Standard_Chartered from '../assets/images/banks/Standard_Chartered.svg';
import Taavon_Eslami from '../assets/images/banks/Taavon_Eslami.svg';
import Tejarat from '../assets/images/banks/Tejarat.svg';
import Tosee from '../assets/images/banks/Tosee.svg';
import Tosee_Saderat from '../assets/images/banks/Tosee_Saderat.svg';
import Tosee_Taavon from '../assets/images/banks/Tosee_Taavon.svg';
import Blue from '../assets/images/banks/blue.svg';

export const bankIcons = {
    'Ansar' : Ansar,
    'Ayandeh' : Ayandeh,
    'Bank_Markazi' : Bank_Markazi,
    'Caspian' : Caspian,
    'Dey' : Dey,
    'Eghtesad_Novin' : Eghtesad_Novin,
    'Futurebank' : Futurebank,
    'Gardeshgari' : Gardeshgari,
    'Ghavamin' : Ghavamin,
    'Hekmat' : Hekmat,
    'Iran_Europe' : Iran_Europe,
    'Iran_Venezuela' : Iran_Venezuela,
    'Iran_Zamin' : Iran_Zamin,
    'Karafarin' : Karafarin,
    'Keshavarzi' : Keshavarzi,
    'Khavar_Mianeh' : Khavar_Mianeh,
    'Kosar' : Kosar,
    'Maskan' : Maskan,
    'Mehr_Eghtesad' : Mehr_Eghtesad,
    'Mehr_Iran' : Mehr_Iran,
    'Melall' : Melall,
    'Mellat' : Mellat,
    'Melli' : Melli,
    'Noor' : Noor,
    'Parsian' : Parsian,
    'Pasargad' : Pasargad,
    'Postbank' : Postbank,
    'Refah' : Refah,
    'Resalat' : Resalat,
    'Saderat' : Saderat,
    'Saman' : Saman,
    'Sanat_Madan' : Sanat_Madan,
    'Sarmayeh' : Sarmayeh,
    'Sepah' : Sepah,
    'Shahr' : Shahr,
    'Sina' : Sina,
    'Standard_Chartered' : Standard_Chartered,
    'Taavon_Eslami' : Taavon_Eslami,
    'Tejarat' : Tejarat,
    'Tosee' : Tosee,
    'Tosee_Saderat' : Tosee_Saderat,
    'Tosee_Taavon' : Tosee_Taavon,
    'Blue': Blue,
};


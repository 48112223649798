import axios from '../util/Api';
import {
    CREDIT_CARD_ADD_DONE,
    CREDIT_CARD_ADDED,
    CREDIT_CARD_ADDING,
    CREDIT_CARD_REMOVE_DONE,
    CREDIT_CARD_REMOVED,
    CREDIT_CARD_REMOVING,
    CREDIT_CARDS_FETCHED,
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    SWITCH_DELETE_DIALOG,
    USER_TOKEN_EXPIRE
} from "../constants/ActionTypes";
import {ADD_CREDIT_CARD, DELETE_CREDIT_CARD, FETCH_CREDIT_CARD} from "../constants/Units";

const resourcePath = 'credit_card/';

export const getCardsList = (props) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        axios.get(resourcePath, props
        ).then(({data}) => {
            if (data.result) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({type: CREDIT_CARDS_FETCHED, payload: data.cards});
            } else {
                dispatch({type: FETCH_ERROR, payload: data.errors});
            }
        }).catch(function (error) {
            if(error.response && error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else
                dispatch({type: FETCH_ERROR, payload: {errors: {serverError :error.message}, unit: FETCH_CREDIT_CARD}});
        });
    }

};

export const addCard = (props) => {
    return (dispatch) => {
        dispatch({type: CREDIT_CARD_ADDING});
        axios.post(resourcePath, props
        ).then(({data}) => {

            if (data.result) {
                dispatch({type: FETCH_SUCCESS, payload: {messages: {'add_card': data.status}, unit: ADD_CREDIT_CARD}});
                dispatch({type: CREDIT_CARD_ADDED, payload: data.card});
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: ADD_CREDIT_CARD}});
            }
            dispatch({type: CREDIT_CARD_ADD_DONE});
        }).catch(function (error) {
            dispatch({type: CREDIT_CARD_ADD_DONE});
            if(error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else
                dispatch({type: FETCH_ERROR, payload: {errors: {serverError :error.message}, unit: ADD_CREDIT_CARD}});
        });
    }

};
export const deleteCard = (id) => {
    return (dispatch) => {
        dispatch({type: CREDIT_CARD_REMOVING, payload: {card_id: id}});
        axios.post(resourcePath + 'deactivate', {card_id: id}
        ).then(({data}) => {

            if (data.result) {
                dispatch({type: FETCH_SUCCESS, payload: {messages: {'remove_card': data.status}, unit: DELETE_CREDIT_CARD}});
                dispatch({type: CREDIT_CARD_REMOVED, payload: data.card});
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: DELETE_CREDIT_CARD}});
            }
            dispatch({type: CREDIT_CARD_REMOVE_DONE});
        }).catch(function (error) {
            dispatch({type: CREDIT_CARD_REMOVE_DONE});
            if(error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else
                dispatch({type: FETCH_ERROR, payload: {errors: {serverError :error.message}, unit: DELETE_CREDIT_CARD}});
        });
    }

};

export const switchDeleteDialog = (state) => {
    return (dispatch) => {
        dispatch({type: SWITCH_DELETE_DIALOG, payload: state});
    }
};


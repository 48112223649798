import axios from '../util/Api';
import {
    C2C_DEPOSIT_FAILED,
    C2C_DEPOSIT_REQUESTED,
    CANCEL_REQUEST_FAILED,
    CANCEL_REQUEST_FETCHED,
    DEPOSIT_IDENTITY_GENERATED,
    DEPOSITS_LIST_FETCHED,
    FETCH_ERROR,
    FETCH_SUCCESS,
    FETCHING_DEPOSITS_LIST,
    FETCHING_GATEWAYS,
    FETCHING_GATEWAYS_FAILED,
    FETCHING_TRANS_INFO,
    FETCHING_TRANSACTIONS_LIST,
    FETCHING_WITHDRAWS_LIST,
    GATEWAYS_FETCHED,
    GENERATE_IDENTITY_FAILED,
    OFFLINE_DEPOSIT_FAILED,
    OFFLINE_DEPOSIT_REQUESTED,
    ONLINE_DEPOSIT_FAILED,
    ONLINE_DEPOSIT_REQUESTED,
    ORDER_INFO_FAILED,
    PM_DEPOSIT_FAILED,
    PM_DEPOSIT_REQUESTED,
    REDRAW_TRANSACTIONS_LIST,
    REQUESTING_C2C_DEPOSIT,
    REQUESTING_GENERATE_IDENTITY,
    REQUESTING_OFFLINE_DEPOSIT,
    REQUESTING_ONLINE_DEPOSIT,
    REQUESTING_ORDER_CANCEL,
    REQUESTING_PM_DEPOSIT,
    REQUESTING_WITHDRAW,
    SWITCH_CANCEL_DIALOG,
    SWITCH_DEPOSIT_DIALOG,
    SWITCH_TRANS_INFO_DIALOG,
    SWITCH_WITHDRAW_DIALOG,
    TRANS_INFO_FAILED,
    TRANS_INFO_FETCHED,
    TRANSACTIONS_LIST_FETCHED,
    USE_GATEWAY,
    USER_TOKEN_EXPIRE,
    WITHDRAW_FAILED,
    WITHDRAW_REQUESTED,
    WITHDRAWS_LIST_FETCHED,
} from "../constants/ActionTypes";
import {
    CHECKOUT,
    DEPOSITS_LIST,
    TRANSACTION_INFO,
    TRANSACTIONS_LIST,
    WALLET_C2C_DEPOSIT,
    WALLET_OFFLINE_DEPOSIT,
    WALLET_ONLINE_DEPOSIT,
    WALLET_PM_DEPOSIT,
    WALLET_WITHDRAW,
    WITHDRAWS_LIST
} from "../constants/Units";
import {getUser} from "./Auth";

const resourcePath = 'transfers/';

export const redrawList = () => {
    return (dispatch) => {
        dispatch({type: FETCHING_TRANSACTIONS_LIST});
    }
};

export const getDepositsList = ({ page, count, currency}) => {
    return (dispatch) => {
        dispatch({type: FETCHING_DEPOSITS_LIST});
        axios.get('payment/deposits' , {
                params: {
                    currency: currency,
                    page: page,
                    count: count
                }
            }
        ).then(({data}) => {
            if (data.result) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({type: DEPOSITS_LIST_FETCHED, payload: {deposits: data.records, totalDeposits: data.count, depositsStats: data.deposit_stats}});
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: DEPOSITS_LIST}});
            }
        }).catch(function (error) {
            if(error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else {
                dispatch({
                    type: FETCH_ERROR,
                    payload: {errors: {serverError: error.message}, unit: DEPOSITS_LIST}
                });
                dispatch({
                    type: DEPOSITS_LIST_FETCHED,
                    payload: {deposits: []}
                });
            }
        });
    }

};

export const getWithdrawsList = ({ page, count, currency}) => {
    return (dispatch) => {
        dispatch({type: FETCHING_WITHDRAWS_LIST});
        axios.get('transfer/withdraws' , {
                params: {
                    // type: type,
                    currency: currency,
                    page: page,
                    count: count
                }
            }
        ).then(({data}) => {
            if (data.result) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({type: WITHDRAWS_LIST_FETCHED, payload: {withdraws: data.records, totalWithdraws: data.count, withdrawsStats: data.withdraw_stats}});
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: WITHDRAWS_LIST}});
            }
        }).catch(function (error) {
            if(error.response && error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else {
                dispatch({
                    type: FETCH_ERROR,
                    payload: {errors: {serverError: error.message}, unit: WITHDRAWS_LIST}
                });
                dispatch({
                    type: WITHDRAWS_LIST_FETCHED,
                    payload: {withdraws: []}
                });}
        });
    }

};


export const getWalletTransactionsList = ({page, count, currency}) => {
    return (dispatch) => {
        dispatch({type: FETCHING_TRANSACTIONS_LIST});
        axios.get('wallet/transactions' , {
            params: {
                // type: type,
                currency: currency,
                page: page,
                count: count
            }
        }
        ).then(({data}) => {
            if (data.result) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({type: TRANSACTIONS_LIST_FETCHED, payload: {transactions: data.records, totalTransactions: data.count, transactionsStats: {depositsStats : data.deposit_stats, withdrawsStats: data.withdraw_stats}}});
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: TRANSACTIONS_LIST}});
            }
        }).catch(function (error) {
            if(error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else {
                dispatch({
                    type: FETCH_ERROR,
                    payload: {errors: {serverError: error.message}, unit: TRANSACTIONS_LIST}
                });
                dispatch({
                    type: TRANSACTIONS_LIST_FETCHED,
                    payload: {transactions: []}
                });}
        });
    }

};

export const requestWithdraw = (props) => {
    return (dispatch) => {
        dispatch({type: REQUESTING_WITHDRAW});
        axios.post(resourcePath + 'withdraw', props
        ).then(({data}) => {

            if (data.result) {
                dispatch({type: FETCH_SUCCESS, payload: {messages: {'withdraw': data.message}, unit: WALLET_WITHDRAW}});
                dispatch({type: WITHDRAW_REQUESTED, payload: {link: data.link, method: data.method, params: data.params, stayDialogOpen: false}});
                dispatch(getUser());
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: WALLET_WITHDRAW}});
                dispatch({type: WITHDRAW_FAILED});
            }
        }).catch(function (error) {
            if(error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else
                dispatch({type: FETCH_ERROR, payload: {errors: {serverError :error.message}, unit: WALLET_WITHDRAW}});
            dispatch({type: WITHDRAW_FAILED});
        });
    }

};

export const cryptoWithdraw = (props) => {
    return (dispatch) => {
        dispatch({type: REQUESTING_WITHDRAW});
        axios.post(resourcePath + 'withdraw', props
        ).then(({data}) => {

            if (data.result) {
                dispatch({type: FETCH_SUCCESS, payload: {messages: {'withdraw': data.message}, unit: WALLET_WITHDRAW}});
                dispatch({type: WITHDRAW_REQUESTED, payload: {
                    gateway: {
                        link: data.link,
                        method: data.method,
                        params: data.params
                    },
                    transaction: data,
                    stayDialogOpen: false}
                });
                dispatch(getUser());
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: WALLET_WITHDRAW}});
                dispatch({type: WITHDRAW_FAILED});
            }
        }).catch(function (error) {
            if(error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else
                dispatch({type: FETCH_ERROR, payload: {errors: {serverError :error.message}, unit: WALLET_WITHDRAW}});
            dispatch({type: WITHDRAW_FAILED});
        });
    }

};

export const onlineDeposit = (props) => {
    return (dispatch) => {
        dispatch({type: REQUESTING_ONLINE_DEPOSIT});
        axios.post(resourcePath + 'deposit', props
        ).then(({data}) => {

            if (data.result) {
                dispatch({type: FETCH_SUCCESS, payload: {messages: {'online_deposit': data.status}, unit: WALLET_ONLINE_DEPOSIT}});
                dispatch({type: ONLINE_DEPOSIT_REQUESTED, payload: {link: data.link, method: data.method, params: data.params}});
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: WALLET_ONLINE_DEPOSIT}});
                dispatch({type: ONLINE_DEPOSIT_FAILED});
            }
        }).catch(function (error) {
            if(error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else
                dispatch({type: FETCH_ERROR, payload: {errors: {serverError :error.message}, unit: WALLET_ONLINE_DEPOSIT}});
            dispatch({type: ONLINE_DEPOSIT_FAILED});
        });
    }

};

export const c2cDeposit = (props) => {
    return (dispatch) => {
        dispatch({type: REQUESTING_C2C_DEPOSIT});
        axios.post(resourcePath + 'deposit', props
        ).then(({data}) => {

            if (data.result) {
                dispatch({type: FETCH_SUCCESS, payload: {messages: {'c2c_deposit': data.status}, unit: WALLET_C2C_DEPOSIT}});
                dispatch({type: C2C_DEPOSIT_REQUESTED, payload: {link: data.link, method: data.method, params: data.params}});
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: WALLET_C2C_DEPOSIT}});
                dispatch({type: C2C_DEPOSIT_FAILED});
            }
        }).catch(function (error) {
            if(error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else
                dispatch({type: FETCH_ERROR, payload: {errors: {serverError :error.message}, unit: WALLET_C2C_DEPOSIT}});
            dispatch({type: C2C_DEPOSIT_FAILED});
        });
    }

};

export const offlineDeposit = (props) => {
    return (dispatch) => {
        dispatch({type: REQUESTING_OFFLINE_DEPOSIT});
        axios.post(resourcePath + 'deposit', props
        ).then(({data}) => {

            if (data.result) {
                dispatch({type: FETCH_SUCCESS, payload: {messages: {'offline_deposit': data.status}, unit: WALLET_OFFLINE_DEPOSIT}});
                dispatch({type: OFFLINE_DEPOSIT_REQUESTED, payload: {link: data.link, method: data.method, params: data.params}});
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: WALLET_OFFLINE_DEPOSIT}});
                dispatch({type: OFFLINE_DEPOSIT_FAILED});
            }
        }).catch(function (error) {
            if(error.response && error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else
                dispatch({type: FETCH_ERROR, payload: {errors: {serverError :error.message}, unit: WALLET_OFFLINE_DEPOSIT}});
            dispatch({type: OFFLINE_DEPOSIT_FAILED});
        });
    }
};

export const pmDeposit = (props) => {
    return (dispatch) => {
        dispatch({type: REQUESTING_PM_DEPOSIT});
        axios.post(resourcePath + 'deposit', props
        ).then(({data}) => {

            if (data.result) {
                dispatch({type: FETCH_SUCCESS, payload: {messages: {'pm_deposit': data.status}, unit: WALLET_PM_DEPOSIT}});
                dispatch({type: PM_DEPOSIT_REQUESTED, payload: {transaction: data, gateway: (data.form_data ?? {})}});
                if(data.factor.status > 10)
                    dispatch(getUser());
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: WALLET_PM_DEPOSIT}});
                dispatch({type: PM_DEPOSIT_FAILED});
            }
        }).catch(function (error) {
            if(error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else
                dispatch({type: FETCH_ERROR, payload: {errors: {serverError :error.message}, unit: WALLET_PM_DEPOSIT}});
            dispatch({type: PM_DEPOSIT_FAILED});
        });
    }

};

export const generateIdentity = (props) => {
    console.log("generateIdentity", props)
    return (dispatch) => {
        dispatch({type: REQUESTING_GENERATE_IDENTITY});
        axios.post('deposits/generate_identity',
            {

                    gateway_id : props

            }).then(({data}) => {

            if (data.result) {
                dispatch({type: FETCH_SUCCESS, payload: {messages: {'identified_deposit': data.message}, unit: WALLET_OFFLINE_DEPOSIT}});
                dispatch({type: DEPOSIT_IDENTITY_GENERATED, payload: data.data});
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: WALLET_OFFLINE_DEPOSIT}});
                dispatch({type: GENERATE_IDENTITY_FAILED});
            }
        }).catch(function (error) {
            if(error.response && error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else
                dispatch({type: FETCH_ERROR, payload: {errors: {serverError :error.message}, unit: WALLET_OFFLINE_DEPOSIT}});
            dispatch({type: GENERATE_IDENTITY_FAILED});
        });
    }
};


export const getGateways = (props) => {
    return (dispatch) => {
        dispatch({type: FETCHING_GATEWAYS});
        axios.get('gates/list',
            {params: props}
        ).then(({data}) => {
            if (data.result) {
                dispatch({type: GATEWAYS_FETCHED, payload: {gateways: data.gates, depositIdentities: data.deposit_identities }});
            } else {
                dispatch({type: FETCHING_GATEWAYS_FAILED});
            }
        }).catch(function (error) {
            if(error.response && error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }
            dispatch({type: FETCHING_GATEWAYS_FAILED});
        });
    }
};

export const getTransaction = (id) => {
    return (dispatch) => {
        dispatch({type: FETCHING_TRANS_INFO});
        axios.get('transfer/' + id, {}
        ).then(({data}) => {
            if (data.result) {
                dispatch({type: TRANS_INFO_FETCHED, payload: {transaction: data, gateway: (data.form_data ?? {})}});
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: TRANSACTION_INFO}});
                dispatch({type: TRANS_INFO_FAILED});
            }
        }).catch(function (error) {
            if(error.response && error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else
                dispatch({type: FETCH_ERROR, payload: {errors: {serverError :error.message}, unit: TRANSACTION_INFO}});
            dispatch({type: TRANS_INFO_FAILED});
        });
    }
};

export const CancelOrder = (transferID) => {
    return (dispatch) => {
        dispatch({type: REQUESTING_ORDER_CANCEL});
        axios.put(resourcePath + 'transfers/cancel/' + transferID
        ).then(({data}) => {
            if (data.result) {
                dispatch({type: CANCEL_REQUEST_FETCHED, payload: {order: data.factor}});
                dispatch(getUser());
            } else {
                dispatch({type: CANCEL_REQUEST_FAILED});
            }
        }).catch(function (error) {
            if(error.response && error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else
                dispatch({type: FETCH_ERROR, payload: {errors: {serverError :error.message}, unit: CHECKOUT}});
            dispatch({type: ORDER_INFO_FAILED});
        });
    }

};

export const switchTransInfoDialog = (state) => {
    return (dispatch) => {
        dispatch({type: SWITCH_TRANS_INFO_DIALOG, payload: {state: state}});
    }
};

export const switchCancelDialog = (state) => {
    return (dispatch) => {
        dispatch({type: SWITCH_CANCEL_DIALOG, payload: state});
    }
};

export const switchDepositDialog = (state) => {
    return (dispatch) => {
        dispatch({type: SWITCH_DEPOSIT_DIALOG, payload: state});
    }
};

export const switchWithdrawDialog = (state) => {
    return (dispatch) => {
        dispatch({type: SWITCH_WITHDRAW_DIALOG, payload: state});
    }
};

export const redrawTransactions = () => {
    return (dispatch) => {
        dispatch({type: REDRAW_TRANSACTIONS_LIST});
    }
};

export const useGateway = () => {
    return (dispatch) => {
        dispatch({type: USE_GATEWAY});
    }
};

import React from 'react';
import NotificationItem from './NotificationItem';
import {connect} from "react-redux";
import NotificationDialog from "./NotificationDialog";
import {Link} from "react-router-dom";

class AppNotification extends React.Component {
    render() {
        return (
            <>
                <div className="notification-pop-up-wrapper">
                   <div className="menu-wrapper">
                       <div className="menu-header">
                           اعلان ها
                       </div>
                       <div className="menu-body">
                           {this.props.panelUserNotices.map((notification, index) => <NotificationItem key={index} notification={notification}/>)}
                       </div>
                       <div className="menu-bottom">
                           <Link to='/panel/notices'>مشاهده همه</Link>
                       </div>
                   </div>
                </div>
                <NotificationDialog/>
            {/*<div className="messages-list scrollbar" style={{overflow: 'auto', backgroundColor: "#efefff"}}>*/}
            {/*    <ul className="list-unstyled">*/}
            {/*        {this.props.panelUserNotices.map((notification, index) => <NotificationItem key={index} notification={notification}/>)*/}
            {/*        }*/}
            {/*    </ul>*/}
            {/*</div>*/}
            {/*    */}
                </>
        )
    }
}

const mapStateToProps = ({notice}) => {
    const {hasUnseenUserNotice, panelUserNotices, loadingPanelUserNotices} = notice;
    return {hasUnseenUserNotice, panelUserNotices, loadingPanelUserNotices}
};

export default connect(mapStateToProps, {})(AppNotification);


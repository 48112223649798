import {
    FETCHING_NOTICES_LIST,
    FETCHING_SYSTEM_NOTICES_LIST,
    NEW_PANEL_USER_NOTICE,
    NEW_SYSTEM_NOTICE,
    NOTICES_LIST_FETCHED,
    PANEL_USER_NOTICES_FETCHED,
    SEEN_SYSTEM_NOTICE,
    SEEN_USER_NOTICE,
    SWITCH_NOTIFICATION_DIALOG,
    SYSTEM_NOTICES_FETCHED,
    SYSTEM_NOTICES_LIST_FETCHED
} from "../constants/ActionTypes";


const INIT_STATE = {
    panelUserNotices: [],
    loadingPanelUserNotices: true,
    hasUnseenUserNotice: false,
    systemNotices: [],
    loadingNotices: false,
    notices: [],
    redraw: false,
    totalNotices: 0,
    notificationDialogOpened: false,
    selectedNotice: {},
    seenSystemNotices: JSON.parse(localStorage.getItem('seen_system_notices') )?? {},
    loadingSystemNotices: false,
    redrawSystemNotices: false,
    systemNoticesTable: [],
    totalSystemNotices: 0

};

const unseenNoticesCount = (notices) => {
    return notices.filter((notice) => notice.seen === 0).length;
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        // case USER_NOTICES_FETCHED: {
        //     return {...state, loadingUserNotices: false, userNotices: action.payload};
        // }

        case PANEL_USER_NOTICES_FETCHED: {
            let unSeenNoticesCount = unseenNoticesCount(action.payload.user_notices ?? []);
            return {...state, loadingPanelUserNotices: false, panelUserNotices: action.payload.user_notices ?? [],
                    hasUnseenUserNotice: !!unSeenNoticesCount, unSeenNoticesCount: unSeenNoticesCount};
        }
        case SYSTEM_NOTICES_FETCHED: {
            return {...state, systemNotices: action.payload.system_notices ?? []};
        }
        case SYSTEM_NOTICES_LIST_FETCHED: {
            return {...state, loadingSystemNotices: false, redrawSystemNotices: false, systemNoticesTable: action.payload.systemNoticesTable ?? [], totalSystemNotices: action.payload.totalSystemNotices};
        }
        case FETCHING_SYSTEM_NOTICES_LIST: {
            return {...state, loadingSystemNotices: true, redrawSystemNotices: false};
        }
        case NOTICES_LIST_FETCHED: {
            return {...state, loadingNotices: false, redraw: false, notices: action.payload.notices ?? [], totalNotices: action.payload.totalNotices};
        }
        case FETCHING_NOTICES_LIST: {
            return {...state, loadingNotices: true, redraw: false};
        }
        case NEW_PANEL_USER_NOTICE: {
            let panelUserNotices = state.panelUserNotices.slice();
            if(panelUserNotices.length > 0 && panelUserNotices[0].id !== action.payload.user_notice.id) {
                panelUserNotices.unshift(action.payload.user_notice);
                if(panelUserNotices.length >= 7)
                    panelUserNotices.pop();
            }
            let unSeenNoticesCount = unseenNoticesCount(panelUserNotices);
            return {...state, panelUserNotices: panelUserNotices,
                hasUnseenUserNotice: !!unSeenNoticesCount, unSeenNoticesCount: unSeenNoticesCount};
        }
        case NEW_SYSTEM_NOTICE: {
            let systemNotices = state.systemNotices.slice();
            if(systemNotices.length > 0 && systemNotices[0].id !== action.payload.system_notice.id) {
                systemNotices.unshift(action.payload.system_notice);
            }
            return {...state, systemNotices: systemNotices};
        }
        case SEEN_USER_NOTICE: {
            let seenNotice = action.payload;
            let panelUserNotices = state.panelUserNotices.slice().map((notice) => {
                if(seenNotice.notice_id === -1 || notice.id === seenNotice.notice_id)
                    notice.seen = seenNotice.seen;
                return notice;
            });
            let userNotices = state.notices.slice().map((notice) => {
                if(seenNotice.notice_id === -1 || notice.id === seenNotice.notice_id)
                    notice.seen = seenNotice.seen;
                return notice;
            });
            let unSeenNoticesCount = unseenNoticesCount(panelUserNotices);
            return {...state, panelUserNotices: panelUserNotices, notices: userNotices,
                hasUnseenUserNotice: !!unSeenNoticesCount, unSeenNoticesCount: unSeenNoticesCount};
        }
        case SEEN_SYSTEM_NOTICE: {
            let noticeID = action.payload;
            let now = (new Date().getTime() / 1000);
            let seenSystemNotices = Object.assign({}, state.seenSystemNotices);
            seenSystemNotices[noticeID] = now;
            localStorage.setItem('seen_system_notices', JSON.stringify(seenSystemNotices));
            return {...state, seenSystemNotices: seenSystemNotices};
        }
        case SWITCH_NOTIFICATION_DIALOG: {
            let notice = state.panelUserNotices.filter((notice) => notice.id === action.payload.id)[0];
            return {...state, notificationDialogOpened: action.payload.state, selectedNotice: notice};
        }

        default:
            return state;
    }
}


import {
    CREDIT_CARD_ADD_DONE,
    CREDIT_CARD_ADDED,
    CREDIT_CARD_ADDING,
    CREDIT_CARD_REMOVE_DONE,
    CREDIT_CARD_REMOVED,
    CREDIT_CARD_REMOVING,
    CREDIT_CARDS_FETCHED,
    SWITCH_DELETE_DIALOG
} from "../constants/ActionTypes";

const INIT_STATE = {
    cards: [],
    loadingCards: true,
    addingCard: false,
    deletingCard: false,
    cardID: -1,
    deleteDialogOpened : false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case CREDIT_CARD_ADDING: {
            return {...state, addingCard: true};
        }
        case CREDIT_CARD_ADD_DONE: {
            return {...state, addingCard: false};
        }
        case CREDIT_CARD_REMOVING: {
            return {...state, deletingCard: true, cardID: action.payload.card_id};
        }
        case CREDIT_CARD_REMOVE_DONE: {
            return {...state, deletingCard: false};
        }
        case CREDIT_CARD_REMOVED: {
            let newCards = state.cards.filter(card => card.id !== state.cardID);

            return {...state, deletingCard: false, cards: newCards};
        }
        case CREDIT_CARDS_FETCHED: {
            return {...state, loadingCards: false, cards: action.payload};
        }

        case CREDIT_CARD_ADDED: {
            return {...state, addingCard: false, cards: [...state.cards, action.payload]};
        }
        case SWITCH_DELETE_DIALOG: {
            return {...state, deleteDialogOpened: action.payload.state, selectedOrder: {}, deletableID: action.payload.deletableID,
                selectedCurrency: action.payload.selectedCurrency, selectedBase: action.payload.selectedBase,
                initialAmount: action.payload.initialAmount ?? ''};
        }

        default:
            return state;
    }
}


import React from 'react';

import {connect} from 'react-redux';
import {NotificationContainer, NotificationManager} from "react-notifications";
import SweetAlert from 'sweetalert-react';
import 'sweetalert/dist/sweetalert.css';
import IntlMessages from "../../../util/IntlMessages";

class NotifierContainer extends React.Component{


    constructor(props, context) {
        super(props, context);
        this.state = {
            alarm: {
                show: false,
                title: '',
                message: ''
            }
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {errorRound, messageRound} = this.props;
        if(errorRound !== nextProps.errorRound && Object.keys(nextProps.errors) ){
            let errors = nextProps.errors;
            Object.keys(errors).map((key) => {
                showError(nextProps.messageUnit, key + '.' + errors[key]);
            })
        }

        if(messageRound !== nextProps.messageRound && Object.keys(nextProps.messages) ){
            let messages = nextProps.messages;
            Object.keys(messages).map((key) => {
                showSuccess(nextProps.messageUnit, key + '.' + messages[key]);
            })
        }

        this.setState({
            alarm: {
                show: Object.keys(nextProps.alarm).length > 0,
                title: nextProps.alarm.title ?? '',
                message: nextProps.alarm.message ?? '',
            }
        });

    }

    render() {
        const {alarm} = this.state;
        return (
            <>
                <SweetAlert
                    className="rtl"
                    style={{direction:"rtl"}}
                    show= {alarm.show}
                    title={alarm.title}
                    text={alarm.message}
                    onConfirm={() => this.setState({ alarm: {show: false} })}
                />
                <NotificationContainer/>
            </>

        )
    }
}

const showSuccess = (unitKey, messageKey) => {
    let titleID = "message.title." + unitKey;
    let contentID = "success.content." + messageKey;
    createNotification('success', <IntlMessages id={titleID}/>, <IntlMessages id={contentID}/>);
}

const showError = (unitKey, messageKey) => {
    let titleID = "message.title." + unitKey;
    let contentID = "error.content." + messageKey;
    createNotification('error', <IntlMessages id={titleID}/>, <IntlMessages id={contentID}/>);
}

const createNotification = (type, title, message) => {
    switch (type) {
        case 'info':
            NotificationManager.info('Info message');
            break;
        case 'success':
            NotificationManager.success(message, title, 5000);
            break;
        case 'warning':
            NotificationManager.warning('Warning message', 'Close after 3000ms', 5000);
            break;
        case 'error':
            NotificationManager.error(message, title, 5000, () => {
                // alert('callback');
            });
            break;
    }
};

const mapStateToProps = ({commonData, auth}) => {
    const {errors, messages, alarm, messageUnit, errorRound, messageRound} = commonData;
    const {currentUser} = auth;
    return {errors, messages, alarm, messageUnit, errorRound, messageRound, currentUser}
};
export default connect(mapStateToProps, {})(NotifierContainer);
export {showError, showSuccess};
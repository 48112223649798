import {NotificationManager} from "react-notifications";

const createNotification = (type, title, message) => {

    switch (type) {
        case 'info':
            NotificationManager.info('Info message');
            break;
        case 'success':
            NotificationManager.success(message, title);
            break;
        case 'warning':
            NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
            break;
        case 'error':
            NotificationManager.error(message, title, 3000, () => {
                // alert('callback');
            });
            break;
    }
};
export default createNotification;


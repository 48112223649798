// import Swiper core and required modules
import {Autoplay, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import React from "react";
// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/modules/free-mode/free-mode.min.css';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/thumbs/thumbs.min.css';
// import 'swiper/swiper-bundle.css';
import {Card} from "@material-ui/core";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {MOBILE_MAX_WIDTH} from "../../../panel/routes/data/constants";
import {authModeSelector, switchAuthDialog} from "../../../actions";


class GreetingSlider extends React.Component{
    render(){
        const {width} = this.props;
        return(
            <Swiper
                modules={[Pagination, Autoplay]}
                pagination={width < MOBILE_MAX_WIDTH ? false : (true, {clickable: true})}
                autoplay
                className="swiper"
                // pagination={{
                //     clickable: true,
                // }}

            >
                <SwiperSlide>
                    <Card className="swiper-card">
                        <div className="heading">
                            چنجینی پلتفرم هوشمند تبادل ارزهای دیجیتال و الکترونیک

                        </div>
                        <div className="text">
                            از سال 2013 افتخار ارائه خدمات به شما را داریم، با چند کلیک هم‌خانواده ما شده و با معرفی به دوستان خود، کسب درآمد کنید
                        </div>
                        <div className="slider-btn-wrapper">
                            <Link to="#" className="sign-up-btn" onClick={() => {
                                this.props.switchAuthDialog({state: true});
                                this.props.authModeSelector("register");
                            }}
                            >
                                ثبت نام
                            </Link>
                            <Link to="#" className="login-btn" onClick={() => {
                                this.props.switchAuthDialog({state: true});
                                this.props.authModeSelector("login");
                            }}
                            >
                                ورود
                            </Link>
                        </div>
                    </Card>
                </SwiperSlide>
                <SwiperSlide>
                    <Card className="swiper-card">
                        <div className="heading">
                            چنجینی تبادل ارزهای دیجیتال شما را هوشمندانه پردازش و با سرعت انجام می‌دهد
                        </div>
                        <div className="text">
                            چنجینی از هوش مصنوعی قدرت گرفته و انتقال دارائی‌های دیجیتال شما به کیف پول‌های اشتباه را به حداقل می‌رساند
                        </div>
                        <div className="slider-btn-wrapper">
                            <Link to="#" className="sign-up-btn" onClick={() => {
                                this.props.switchAuthDialog({state: true});
                                this.props.authModeSelector("register");
                            }}
                            >
                                ثبت نام
                            </Link>
                            <Link to="#" className="login-btn" onClick={() => {
                                this.props.switchAuthDialog({state: true});
                                this.props.authModeSelector("login");
                            }}
                            >
                                ورود
                            </Link>
                        </div>
                    </Card>
                </SwiperSlide>
                {/*<SwiperSlide>*/}
                {/*    <Card className="swiper-card">*/}
                {/*        <div className="heading">*/}
                {/*            چنجینی  نماینده رسمی پرفکت‌مانی در ایران می‌باشد*/}
                {/*        </div>*/}
                {/*        <div className="text">*/}
                {/*            ارائه خدمات انتقال دلار و یورو پرفکت‌مانی، بدون کارمزد و در نهایت سرعت، جرئی از خدمات ماست*/}
                {/*        </div>*/}
                {/*        <div className="slider-btn-wrapper">*/}
                {/*            <a href="https://irexchanger.org/buy-perfect-money/" target='_blank' className="sign-up-btn">*/}
                {/*                خرید پرفکت‌ مانی*/}
                {/*            </a>*/}
                {/*            <Link to="panel" className="login-btn">*/}
                {/*                ورود*/}
                {/*            </Link>*/}
                {/*        </div>*/}
                {/*    </Card>*/}
                {/*</SwiperSlide>*/}
                {/*<SwiperSlide>*/}
                {/*    <Card className="swiper-card">*/}
                {/*        <div className="heading">*/}
                {/*            چنجینی  نماینده رسمی وب‌مانی و صادرکننده پاسپورت وب‌مانی در ایران می‌باشد*/}
                {/*        </div>*/}
                {/*        <div className="text">*/}
                {/*            آماده ارائه خدمات صدور اینیشیال پاسپورت و شارژ وب‌مانی در حساب بروکر شما هستیم*/}
                {/*        </div>*/}
                {/*        <div className="slider-btn-wrapper">*/}
                {/*            <a href="https://irexchanger.org/buy-webmoney/" target='_blank' className="sign-up-btn">*/}
                {/*                خرید وب‌مانی*/}
                {/*            </a>*/}
                {/*            <Link to="panel" className="login-btn">*/}
                {/*                ورود*/}
                {/*            </Link>*/}
                {/*        </div>*/}
                {/*    </Card>*/}
                {/*</SwiperSlide>*/}

            </Swiper>
        )
    }
}

const mapStateToProps = ({settings}) => {
    const {width} = settings;
    return {width}
};
export default withRouter(connect(mapStateToProps, {switchAuthDialog, authModeSelector})(GreetingSlider));
import axios from '../util/Api';
import ResourceManager from "../util/ResourceManager";

// import {customDispatch} from "../MainApp";



class Login extends ResourceManager{

    constructor() {
        super('SignIn/');
    }

    login = (fields= {}, callback = null) => {
        axios.post('auth/login', fields).then(({data, errors}) => {
            if (data.result===true){
                if(data.status === 'SUCCESSFUL_LOGIN'){
                    localStorage.setItem("token", (data.token.access_token));
                    if(data.user) {
                        localStorage.setItem("userInfo", JSON.stringify(data.user));
                        // customDispatch({type: USER_DATA, payload: data.user});
                    }
                    axios.defaults.headers.common['Authorization'] = "Bearer " + data.token.access_token;
                }
                if(typeof callback === 'function')
                    callback(data);
            } else {
                if(typeof callback === 'function')
                    callback(data);
            }
        }).catch(function (error) {
            if(typeof callback === 'function')
                callback({result: false, errors: {server: [error.message]}});
        });
    };

    verify = (token, code, callback = null) => {
        axios.post('auth/two-step-login', {
                token: token,
                code: code,
            }
        ).then(({data, errors}) => {
            if (data.result===true){
                // {
                //     window.location = '/';
                localStorage.setItem("token", (data.token.access_token));
                localStorage.setItem("userInfo", JSON.stringify(data.user));
                // customDispatch({type: USER_DATA, payload: data.user});
                axios.defaults.headers.common['Authorization'] = "Bearer " + data.token.access_token;
                if(typeof callback === 'function')
                    callback(data);
                // window.location.reload();


            } else {
                if(typeof callback === 'function')
                    callback(data);
            }

        }).catch(function (error) {
            if(typeof callback === 'function')
                callback({result: false, errors: {server: [error.message]}});
        });
    };

    verifyResend = (fields= {}, callback = null) => {
        axios.post('auth/login', fields).then(({data, errors}) => {
            if (data.result===true){
                if(typeof callback === 'function')
                    callback(data);
            } else {
                if(typeof callback === 'function')
                    callback(data);
            }
        }).catch(function (error) {
            if(typeof callback === 'function')
                callback({result: false, errors: {server: [error.message]}});
        });
    };

}

export default Login;

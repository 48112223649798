import {
    FETCH_ERROR,
    FETCH_SUCCESS,
    FETCHING_NOTICES_LIST,
    FETCHING_SYSTEM_NOTICES_LIST,
    NOTICES_LIST_FETCHED,
    SEEN_SYSTEM_NOTICE,
    SWITCH_NOTIFICATION_DIALOG,
    SYSTEM_NOTICES_LIST_FETCHED,
    USER_TOKEN_EXPIRE,
} from "../constants/ActionTypes";
import axios from "../util/Api";
import {NOTICES} from "../constants/Units";


export const seenSystemNotice = (noticeID) => {
    return (dispatch) => {
        dispatch({type: SEEN_SYSTEM_NOTICE, payload: noticeID});
    }
};

export const getNoticesList = ({ page, count}) => {
    return (dispatch) => {
        dispatch({type: FETCHING_NOTICES_LIST});
        axios.get('notice/user_notices' , {
                params: {
                    // type: type,
                    page: page,
                    count: count
                }
            }
        ).then(({data}) => {
            if (data.result) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({type: NOTICES_LIST_FETCHED, payload: {notices: data.records, totalNotices: data.count}});
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: NOTICES}});
            }
        }).catch(function (error) {
            if(error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else {
                dispatch({
                    type: FETCH_ERROR,
                    payload: {errors: {serverError: error.message}, unit: NOTICES}
                });
                dispatch({
                    type: NOTICES_LIST_FETCHED,
                    payload: {deposits: []}
                });}
        });
    }

};

export const getSystemNoticesList = ({ page, count}) => {
    return (dispatch) => {
        dispatch({type: FETCHING_SYSTEM_NOTICES_LIST});
        axios.get('notice/system_notices' , {
                params: {
                    // type: type,
                    page: page,
                    count: count
                }
            }
        ).then(({data}) => {
            if (data.result) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({type: SYSTEM_NOTICES_LIST_FETCHED, payload: {systemNoticesTable: data.records, totalSystemNotices: data.count}});
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: NOTICES}});
            }
        }).catch(function (error) {
            if(error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else {
                dispatch({
                    type: FETCH_ERROR,
                    payload: {errors: {serverError: error.message}, unit: NOTICES}
                });
                dispatch({
                    type: SYSTEM_NOTICES_LIST_FETCHED,
                    payload: {deposits: []}
                });}
        });
    }

};

export const switchNotificationDialog = (state, id) => {
    return (dispatch) => {
        dispatch({type: SWITCH_NOTIFICATION_DIALOG, payload: {state, id}});
    }
};

import axios from '../util/Api';
import ResourceManager from "../util/ResourceManager";
import {
    CLOSING_TICKET,
    FETCH_ERROR,
    FETCH_SUCCESS,
    FETCHING_LAST_LIVE_CHAT,
    FETCHING_TICKET_MESSAGES,
    FETCHING_TICKETS_LIST,
    LAST_LIVE_CHAT_FAILED,
    LAST_LIVE_CHAT_FETCHED,
    MESSAGE_SUBMIT_FAILED,
    RATING_TICKET,
    SET_CHAT_CONVERSATION_INFORMATION,
    START_CHATBOX_TICKET,
    SUBMITTING_TICKET,
    SUBMITTING_TICKET_MESSAGE,
    SWITCH_CHATBOX,
    SWITCH_COMPOSE_DIALOG,
    SWITCH_LIVE_CHAT_CONTENT_STEPPER,
    SWITCH_TICKET_DRAWER,
    TICKET_CLOSED,
    TICKET_MESSAGE_SUBMITTED,
    TICKET_MESSAGES_FETCHED,
    TICKET_RATED,
    TICKET_SUBMIT_FAILED,
    TICKET_SUBMITTED,
    TICKETS_LIST_FETCHED,
    UNSELECT_TICKET,
    UPLOAD_PROGRESS,
    USER_TOKEN_EXPIRE,
} from "../constants/ActionTypes";
import {SUPPORT} from "../constants/Units";


const resourcePath = 'tickets/';

class SupportResource extends ResourceManager {

    constructor() {
        super('Signup/');
    }

    ticketList = (fields = {}, callback = null) => {
        console.log('sendind Data', 11);
        axios.post('tickets/get', fields).then(({data, errors}) => {
            if (data.result === true) {
                if (typeof callback === 'function')
                    callback(data);
            } else {
                if (typeof callback === 'function')
                    callback(data);
            }
        }).catch(function (error) {
            if (typeof callback === 'function')
                callback({result: false, errors: {server: [error.message]}});
        });
    };

    ticketMessages = (fields = {}, callback = null) => {
        axios.post('ticket/messages/get', fields).then(({data, errors}) => {
            if (data.result === true) {
                if (typeof callback === 'function')
                    callback(data);
            } else {
                if (typeof callback === 'function')
                    callback(data);
            }
        }).catch(function (error) {
            if (typeof callback === 'function')
                callback({result: false, errors: {server: [error.message]}});
        });
    };

}

export default SupportResource;

export const submitTicketMessage = (formData = {}) => {
    return (dispatch) => {
        dispatch({type: SUBMITTING_TICKET_MESSAGE});
        axios.post(resourcePath + 'message/', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                // console.log('Progress Event: ', progressEvent);
                dispatch({
                    type: UPLOAD_PROGRESS,
                    payload: {progress: parseInt(progressEvent.loaded * 100 / progressEvent.total)}
                });
            }
        }).then(({data}) => {
                if (data.result) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: TICKET_MESSAGE_SUBMITTED, payload: data.data});
                } else {
                    dispatch({type: MESSAGE_SUBMIT_FAILED, payload: {errors: data.errors, unit: SUPPORT}});
                    dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: SUPPORT}});
                }
            }).catch(function (error) {
            if(error.response && error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else
                dispatch({type: FETCH_ERROR, payload: {errors: {serverError :error.message}, unit: SUPPORT}});
            dispatch({type: MESSAGE_SUBMIT_FAILED});
            console.log("Error****:", error.message);
        });
    }

};

export const getTicketMessages = ({ticket}, smooth = false) => {

    return (dispatch) => {
        if(!ticket || !ticket.id)
            return;
        if(!smooth)
            dispatch({type: FETCHING_TICKET_MESSAGES});
        axios.get('ticket/messages/' + ticket.id, {}
        ).then(({data}) => {
            if (data.result) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                    type: TICKET_MESSAGES_FETCHED,
                    payload: {messages: data.messages, selectedTicket: data.ticket, totalMessages: data.messages.length}
                });
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: SUPPORT}});
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            } else {
                dispatch({
                    type: FETCH_ERROR,
                    payload: {errors: {serverError: error.message}, unit: SUPPORT}
                });
                dispatch({
                    type: TICKET_MESSAGES_FETCHED,
                    payload: {messages: []}
                });
            }
            console.log("Error****:", error.message);
        });
    }

};

export const closeTicket = (id) => {
    return (dispatch) => {
        dispatch({type: CLOSING_TICKET});
        axios.post('tickets/close/'+ id, {}
        ).then(({data}) => {
            if (data.result) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                    type: TICKET_CLOSED,
                    payload: {ticketID: id}
                });
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: SUPPORT}});
            }
        }).catch(function (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            } else {
                dispatch({
                    type: FETCH_ERROR,
                    payload: {errors: {serverError: error.message}, unit: SUPPORT}
                });
                dispatch({
                    type: TICKET_CLOSED,
                    payload: {messages: []}
                });
            }
            console.log("Error****:", error.message);
        });
    }

};



export const getTicketsList = ({}) => {
    return (dispatch) => {
        dispatch({type: FETCHING_TICKETS_LIST});
        axios.get(resourcePath, {}
        ).then(({data}) => {
            if (data.result) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                    type: TICKETS_LIST_FETCHED,
                    payload: {tickets: data.tickets, totalTickets: data.count, stats: data.stats}
                });
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: SUPPORT}});
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            } else {
                dispatch({
                    type: FETCH_ERROR,
                    payload: {errors: {serverError: error.message}, unit: SUPPORT}
                });
                dispatch({
                    type: TICKETS_LIST_FETCHED,
                    payload: {tickets: []}
                });
            }
            console.log("Error****:", error.message);
        });
    }

};

export const submitTicket = (formData = {}, callback = null) => {
    return (dispatch) => {
        dispatch({type: SUBMITTING_TICKET});
        axios.post(resourcePath, formData, {
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                console.log('Progress Event: ', progressEvent);
                dispatch({
                    type: UPLOAD_PROGRESS,
                    payload: {progress: parseInt(progressEvent.loaded * 100 / progressEvent.total)}
                });
            }
        })
            .then(({data}) => {
                if (data.result) {
                    // dispatch({type: FETCH_SUCCESS, payload: {messages: {'new_ticket': data.status}, unit: SUPPORT}});
                    dispatch({type: TICKET_SUBMITTED, payload: data.data});
                } else {
                    dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: SUPPORT}});
                    dispatch({type: TICKET_SUBMIT_FAILED, payload: {errors: data.errors, unit: SUPPORT}});
                }
            }).catch(function (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            } else
                dispatch({type: FETCH_ERROR, payload: {errors: {serverError: error.message}, unit: SUPPORT}});
            dispatch({type: TICKET_SUBMIT_FAILED});
            console.log("Error****:", error.message);
        });
    }

};

export const getLastLiveChat = (ticketID, callback = null) => {
    return (dispatch) => {
        dispatch({type: FETCHING_LAST_LIVE_CHAT});
        axios.get(resourcePath + 'last_live_chat/' + ticketID)
            .then(({data}) => {
                if (data.result) {
                    // dispatch({type: FETCH_SUCCESS, payload: {messages: {'new_ticket': data.status}, unit: SUPPORT}});
                    dispatch({type: LAST_LIVE_CHAT_FETCHED, payload: data.data});
                } else {
                    dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: SUPPORT}});
                    dispatch({type: LAST_LIVE_CHAT_FAILED, payload: {errors: data.errors, unit: SUPPORT}});
                }
            }).catch(function (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            } else
                dispatch({type: FETCH_ERROR, payload: {errors: {serverError: error.message}, unit: SUPPORT}});
            dispatch({type: TICKET_SUBMIT_FAILED});
            console.log("Error****:", error.message);
        });
    }

};

export const rateTicket = (id, rateScore) => {
    return (dispatch) => {
        dispatch({type: RATING_TICKET});
        axios.post('tickets/rate/'+ id, {rate: rateScore}
        ).then(({data}) => {
            if (data.result) {
                dispatch({type: FETCH_SUCCESS, payload: {messages: {'ticket': data.status}, unit: SUPPORT}});
                dispatch({
                    type: TICKET_RATED,
                    payload: {ticketID: id}
                });
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: SUPPORT}});
            }
        }).catch(function (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            } else {
                dispatch({
                    type: FETCH_ERROR,
                    payload: {errors: {serverError: error.message}, unit: SUPPORT}
                });
                dispatch({
                    type: TICKET_RATED,
                    payload: {messages: []}
                });
            }
            console.log("Error****:", error.message);
        });
    }

};

export const switchComposeDialog = (state) => {
    return (dispatch) => {
        dispatch({type: SWITCH_COMPOSE_DIALOG, payload: {state: state}});
    }
};

export const unselectTicket = () => {
    return (dispatch) => {
        dispatch({type: UNSELECT_TICKET, payload: {}});
    }
};

export const switchTicketsDrawer = (state) => {
    return (dispatch) => {
        dispatch({type: SWITCH_TICKET_DRAWER, payload: {state: state}});
    }
};
export const switchChatBox = (opened) => {
    return (dispatch) => {
        dispatch({type: SWITCH_CHATBOX, payload: {state: opened}});
    }
};
export const switchLiveChatContentStepper = (stepIndex) => {
    return (dispatch) => {
        dispatch({type: SWITCH_LIVE_CHAT_CONTENT_STEPPER, payload: stepIndex});
    }
};
export const getConversationInformation = (chatSubject, chatManagementUnit ) => {
    return (dispatch) => {
        dispatch({type: SET_CHAT_CONVERSATION_INFORMATION, payload: {chatSubject , chatManagementUnit}})
    }
}

export const startChatBoxTicket = (title, management_unit ) => {
    return (dispatch) => {
        dispatch({type: START_CHATBOX_TICKET, payload: {title, management_unit}})
    }
}

import React from "react";
import {Radio, RadioGroup, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {idSelected, isInt, lengthBetween} from "../../../../util/Validator";
import IntlMessages from "../../../../util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import {showError} from "../../../components/Notifier";
import {WALLET_OFFLINE_DEPOSIT} from "../../../../constants/Units";
import {connect} from "react-redux";
import {generateIdentity, offlineDeposit, switchDepositDialog, useGateway} from "../../../../actions/Wallet";
import {DIGITAL_CURRENCIES, GATEWAYS} from "../../data/constants"
import {clearCommas, fa2enNumbers} from "../../../../util/Utilities";
import {switchBuyDialog} from "../../../../actions/CurrencyResource";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";



class IdDeposit extends React.Component {

    constructor(props) {
        super(props);
        const {gateways} = this.props;
        this.state = {
            openDepositDescription: false,
            cards: this.props.cards,
            offlineMethodInfo: {},
            minimumDeposit: 20000,
            generateButton: false,
            inputs: {
                amount: props.amount ?? '',
                followUpNo: "",
                selectCard: (this.props.cards.length > 0 ? this.props.cards[0]['id'] : 0),
                gateway: this.getFirstGatewayID(this.props.gateways),
                currencyType: DIGITAL_CURRENCIES.IRR,
                rulesChecked: true,
            },
            formErrors: {
                amount: '',
                followUpNo: '',
                selectCard: "",
                rulesChecked: '',
            }
        };

        // this.handleRequestCloseDepositDialog = this.handleRequestCloseDepositDialog.bind(this);

        if (gateways.length > 0) {
            let filteredGateways = gateways.filter((gateway) => gateway.gate_type === GATEWAYS.OFFLINE && gateway.depositable > 0);
            if(filteredGateways.length > 0) {
                this.state.offlineMethodInfo = filteredGateways[0]['display_info'];
                this.state.minimumDeposit = 20000;
            }
        }

    }

    getFirstGatewayID = (gateways) => {

        if (gateways) {
            let filteredGateways = gateways.filter((gateway) => gateway.gate_type === GATEWAYS.OFFLINE && gateway.depositable > 0);
            if(filteredGateways.length)
                return filteredGateways[0]['id'];
        }
        return 0;
    };

    formValidated = () => {
        const {inputs} = this.state;
        const context = this;
        let isValid = true;
        Object.keys(inputs).map(function (key, index) {
            isValid &= context.validateFormValue(key, inputs[key]);
        });
        const {formErrors} = this.state;
        Object.keys(formErrors).map(function (key, index) {
            isValid &= !formErrors[key];
        });
        return isValid;
    };

    validateFormValue = (name, value) => {
        value = fa2enNumbers(value);
        let {formErrors, inputs, minimumDeposit} = this.state;

        switch (name) {
            case 'amount':
                // value = clearCommas(value) ;
                var pRegex = /^[\u0600-\u06FF\s]+$/;
                var EnRegex = /^[a-zA-Z]+$/;

                value = value.replace(pRegex, '');
                value = value.replace(EnRegex, '');
                if (!isInt({input: value}))
                    formErrors.amount = <IntlMessages id="error.content.amount.numeric"/>;
                else if (value < minimumDeposit)
                    formErrors.amount = <><IntlMessages id={"error.content.amount.min.value"}/>
                        {minimumDeposit.toLocaleString()}<IntlMessages id="error.content.amount-min-value-so-on"/></>
                else if (value > 200000000)
                    formErrors.amount = <><IntlMessages id={"error.content.amount.max.value"}/>
                        {(200000000).toLocaleString()}<IntlMessages id="error.content.amount-max-value-so-on"/></>
                else {
                    formErrors.amount = "";
                }
                value = value.toString().length ? parseInt(value) : '';
                break;

            case 'followUpNo':
                value = value.replace(/[^a-zA-Z0-9-—]/gi, '');
                if (!lengthBetween({input: value, max: 22, min: 3}))
                    formErrors.followUpNo = <IntlMessages id="error.content.followUpNo.min.value"/>;
                else
                    formErrors.followUpNo = "";
                break;

            case 'selectCard':
                if (!idSelected({input: value}))
                    formErrors.selectCard = <IntlMessages id="error.content.select_card.null"/>;
                else
                    formErrors.selectCard = "";
                break;

        }
        inputs[name] = value;
        this.setState({formErrors, inputs});
        return !formErrors[name];
    };

    handleChange = e => {
        const {name, value} = e.target;
        this.validateFormValue(name, value);

        // depositIdentities.length === 0 && depositIdentities.find((identity) => identity.terminal_gateway === value).length === 0 ?
        //     this.setState({generateButton: true}) : this.setState({generateButton: false})
    };

    componentDidMount() {

    }

    postForm(link, params = []) {
        return (
            <form style={{display: 'none'}} action={link} method="POST" ref={(ref) => this.state.postFormRef = ref}>
                {
                    // params.map((param, id) => {
                    //     <input name={id} value={param}/>
                    // })
                }
            </form>
        )
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {inputs} = this.state
        if (!inputs.gateway && nextProps.gateways) {
            inputs.gateway = this.getFirstGatewayID(nextProps.gateways);
            let filteredGateways = nextProps.gateways.filter((gateway) => gateway.gate_type === GATEWAYS.OFFLINE);
            if(filteredGateways.length > 0) {
                this.state.offlineMethodInfo = filteredGateways[0]['display_info'];
                this.state.minimumDeposit = 20000;//filteredGateways[0]['min_deposit'];
            }
        }
        if (nextProps.amount && nextProps.amount !== this.props.amount) {
            inputs.amount = nextProps.amount;
        }
        this.setState({
            cards: nextProps.cards,
            inputs: inputs
        });
        if (!inputs.selectCard) {
            this.validateFormValue('selectCard', nextProps.cards[0] ? nextProps.cards[0]['id'] : 0);
        }
        if (!nextProps.gatewayUsed && nextProps.gateway.link) {
            if (nextProps.gateway.method === 'GET') {
                window.location.href = nextProps.gateway.link;
            } else if (nextProps.gateway.method === 'POST') {

            }
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const {amount, selectCard, gateway, currencyType, followUpNo} = this.state.inputs;
        if (this.formValidated()) {

            this.props.offlineDeposit({
                o_amount: clearCommas(amount)*10,
                card_id: selectCard,
                gateway: gateway,
                gate_type: 7,
                o_currency: currencyType,
                transaction_id: followUpNo,
                transfer_id: this.props.transferID
            });
            this.props.switchDepositDialog({state: false})
        } else
            showError(WALLET_OFFLINE_DEPOSIT, 'form.invalid');

    };

    handleRequestClose = () => {
        this.props.switchDepositDialog({state: false});
        this.props.switchBuyDialog({state: false});
    };

    handleClick = () => {
        const {openDepositDescription} = this.state;
        this.setState({openDepositDescription: !openDepositDescription});

    };

    handleKeyPress = e => {
        if (e.key === 'Enter') {
            this.handleSubmit(e)
        }
    }

    handleCheck = e => {
        const {name, checked} = e.target;
        const{formErrors, inputs} = this.state;
        inputs[name] = !!checked;
        formErrors.rulesChecked =
            !checked ? "پذیرش قوانین الزامی است." : "";
        this.setState({inputs, formErrors});

    };


    generateDepositID = (gateID) => {
        this.props.generateIdentity(gateID)
    }

    render() {
        const {inputs, formErrors, openDepositDescription} = this.state;
        const {fetchingGateways, gateways,
            depositIdentities, generatingID} = this.props;
        const selectedIdentity = depositIdentities.find((identity) => identity.terminal_gateway == inputs.gate_id);
        // console.log("deposit identities", inputs.gate_id, depositIdentities, depositIdentities.find((identity) => identity.terminal_gateway == inputs.gate_id))
        return (
            <>
                <div className="offline-wrapper">
                    <div className="gate-way-wrapper">
                        <div className="desc">
                            حساب مقصد مورد نظر خود را انتخاب کنید:
                        </div>
                        <div className="gate-way-radios-wrapper">
                            <div className="gate-ways-inner-wrapper">
                                <FormControl    error={!!formErrors.gate_id} component="fieldset">
                                    <RadioGroup aria-label="gate_id" name="gate_id" value={inputs.gate_id} onChange={this.handleChange}>
                                        {fetchingGateways ?
                                            <FormControlLabel labelPlacement="top" value={0}  control={<Radio/>} label={
                                                <div className="gate-way-label">
                                                    {/*<img alt="alt" className="gate-way-logos"*/}
                                                    {/*     src={gateWaysIcon[option.gateway]}/>*/}
                                                    <div className="gate-way-title">
                                                        {<IntlMessages id="button.loading_gateways"/>}
                                                        <CircularProgress size={24}/>
                                                    </div>
                                                </div>} />
                                            :

                                            (
                                                gateways.filter((gateway) => gateway.identified_deposit === 1).length === 0 ?
                                                    (
                                                        <FormControlLabel  labelPlacement="top" value={0} control={<Radio/>} label={
                                                            <div className="gate-way-label" onClick={this.props.goToOffline}>
                                                                درگاه های شناسه دار موقتا غیر فعال می باشد. لطفا از دیگر روش های پرداختی استفاده نمائید.
                                                            </div>

                                                        }/>


                                                    ) :
                                                    gateways.filter((gateway) => gateway.identified_deposit === 1).map((option) => (

                                                            <FormControlLabel labelPlacement="top" value={option.id.toString()}  control={<Radio/>} label={
                                                                <div className="gate-way-label">
                                                                    {/*<img alt="alt" className="gate-way-logos"*/}
                                                                    {/*     src={gateWaysIcon[option.gateway]}/>*/}
                                                                    <div className="gate-way-title">
                                                                        {option.title}
                                                                    </div>
                                                                </div>} />

                                                        )
                                                    ))

                                        }
                                    </RadioGroup>
                                </FormControl>
                            </div>

                        </div>
                        {inputs.gate_id > 0  ? depositIdentities.length === 0 || !selectedIdentity ?
                            <div className="buttons-wrapper">
                                <div className="confirm-btn-wrapper">
                                    <Button
                                        variant="contained"
                                        className="confirm-btn mt-2"
                                        fullWidth
                                        disabled={generatingID}
                                        onClick={() => this.generateDepositID(inputs.gate_id)}
                                        type="button"
                                    >
                                        {generatingID ? <><IntlMessages id="button.generating_id"/>
                                            <CircularProgress
                                                size={24} color="inherit"/></> : "ایجاد شناسه واریز"}
                                    </Button>
                                </div>
                            </div>

                                :
                                <div className="offline-wrapper px-0 pt-2">
                                    <form className="offline-form-wrapper px-0" >
                                        <div className="" style={{backgroundImage: 'var(--fight-bg)', fontSize: '20px', fontWeight: '600', padding: '4px'}}>
                                            <div style={{display: 'inline-flex', gap: '8px'}}>
                                                <div style={{ fontSize: '18px'}}>شناسه واریز:</div>
                                                <div style={{color: 'var(--secondary-color)',fontSize: '22px', fontWeight: '600'}}>{selectedIdentity.code}</div>
                                            </div>
                                        </div>
                                        <div className="desc-wrapper">
                                            <div className="offline-desc-wrapper">
                                                <div className="offline-desc-inner-wrapper">
                                                    <IntlMessages id="contents.deposit.identified.description1"/>

                                                    <br/>
                                                    <span className="text-secondary font-weight-bold">1:  </span>
                                                    <IntlMessages id="contents.deposit.identified.description6"/>
                                                    <div style={{direction: 'ltr', display: 'inline-flex'}}>{"  " + selectedIdentity.meta.destination.sheba + ' '}</div>
                                                    <IntlMessages id="contents.deposit.identified.description6.1"/>
                                                    <br/>
                                                    <span className="text-secondary font-weight-bold">2:  </span>
                                                    <IntlMessages id="contents.deposit.identified.description7"/>
                                                    <div style={{direction: 'ltr', display: 'inline-flex'}}>{"  " + selectedIdentity.meta.destination.sheba + ' '}</div>
                                                    <IntlMessages id="contents.deposit.identified.description7.1"/>
                                                    <br/>
                                                    <span className="text-secondary font-weight-bold">3:  </span>
                                                    <IntlMessages id="contents.deposit.identified.description8"/>
                                                    <div style={{direction: 'ltr', display: 'inline-flex'}}>{"  " + selectedIdentity.meta.destination.account_number + ' '}</div>
                                                    <br/>
                                                    <div style={{fontSize: '15px', textAlign: 'center'}}>
                                                        <IntlMessages id="contents.deposit.identified.description8.1"/>
                                                        {"  " + selectedIdentity.meta.destination.account_name + ' '}
                                                        <br/>
                                                    </div>


                                                </div>

                                            </div>
                                            <div className="offline-desc-wrapper little">
                                                <div className="offline-desc-inner-wrapper little" style={{display: 'flex'}}>
                                                    <div style={{color: 'var(--danger-text)', fontWeight: '600'}}>اخطار: </div>
                                                     <IntlMessages id="contents.deposit.identified.description9"/>
                                                </div>
                                            </div>
                                            <div className="offline-desc-wrapper little">
                                                <div className="offline-desc-inner-wrapper little" style={{display: 'flex'}}>
                                                    <div style={{color: 'var(--warning-text)', fontWeight: '600'}}>هشدار: </div>
                                                     <IntlMessages id="contents.deposit.identified.description10"/>
                                                </div>
                                            </div>
                                            <div className="offline-desc-wrapper little">
                                                <div className="offline-desc-inner-wrapper little">
                                                    حداقل مبلغ واریز {this.state.minimumDeposit.toLocaleString()} تومان بوده و تا 15 دقیقه پس از تایید تراکنش توسط بانک، حساب شما بصورت اتوماتیک شارژ خواهد شد. لطفا پس از زمان های تعیین شده درصورتیکه بصورت خودکار تراکنش شما ثبت نگردید به پشتیبانی سایت اطلاع دهید.

                                                </div>
                                            </div>
                                        </div>

                                        <TextField
                                            className="d-none"
                                            type="hidden"
                                            name="gateway"

                                        >
                                        </TextField>
                                        <TextField
                                            className="d-none"
                                            type="hidden"
                                            name="currencyType"
                                        > </TextField>


                                    </form>



                                </div>
                            : ""}
                        {this.state.formErrors.rulesChecked.length > 0 && (
                            <div className="error-box">
                                <img alt="alt" src={require("../../../../assets/images/V2/error.svg")}/>
                                {this.state.formErrors.rulesChecked}
                            </div>

                        )}
                        <div className="bottom-wrapper mt-2">
                            <div className="buttons-wrapper">
                                <div className="cancel-btn-wrapper">
                                    <Button
                                        variant="contained"
                                        onClick={this.handleRequestClose}
                                        fullWidth
                                        className="cancel-btn"
                                    >
                                        <IntlMessages id="button.cancel"/>
                                    </Button>
                                </div>
                            </div>
                            <div className="rules-wrapper">
                                <List
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                    className="rules-list"
                                >
                                    <ListItem button onClick={() => {
                                        this.handleClick()
                                    }} className="rules-list-item">
                                        <ListItemText>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox className="rules-check" tabIndex={5}
                                                              checked={inputs.rulesChecked} onChange={this.handleCheck}
                                                              name="rulesChecked"/>
                                                }
                                                label={<> <a  className="rules-desc">
                            <span>
                                قوانین واریز وجه

                            </span>

                                                </a>
                                                    {" "}
                                                    <span>
                               را می پذیرم
                           </span>
                                                </>}
                                            />
                                        </ListItemText>
                                        {openDepositDescription ? <ExpandLess/> : <ExpandMore/>}
                                    </ListItem>
                                    <Collapse in={openDepositDescription} timeout="auto" unmountOnExit>
                                        <div className="rules-card">
                                            <div className="rule-row">
                                                <IntlMessages id={"contents.deposit.rules1"}/>
                                            </div>
                                            <div className="rule-row">
                                                <IntlMessages id={"contents.deposit.rules2"}/>
                                            </div>
                                            <div className="rule-row">
                                                <IntlMessages id={"contents.deposit.rules3"}/>
                                            </div>
                                        </div>
                                    </Collapse>
                                </List>
                            </div>
                        </div>


                    </div>


                </div>
            </>

        )
    }
}

const mapStateToProps = ({commonData, wallet, settings}) => {
    const {width} = settings;
    const {hasErrors} = commonData;

    const {requestingOffline, paymentURL, gatewayUsed, gateway, fetchingGateways, generatingID} = wallet;
    return {requestingOffline, paymentURL, gatewayUsed, gateway, hasErrors, fetchingGateways, generatingID, width};
};
export default connect(mapStateToProps, {offlineDeposit, useGateway, switchDepositDialog, switchBuyDialog, generateIdentity})(IdDeposit);
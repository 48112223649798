import SVGS from "./SVGS";

const ImageStorage = {
    authLine: (active, svgClasses = '') => (
        active ? SVGS.AUTH_BLUE(svgClasses) : SVGS.AUTH_RED(svgClasses)
    ),
    authIcons: {
        authMobileIcon: (darkMode, svgClasses = '') => (
            darkMode ? SVGS.AUTH_ICONS.MOBILE_LIGHT_ICON(svgClasses) : SVGS.AUTH_ICONS.MOBILE_DARK_ICON(svgClasses)
        ),
        authEmailIcon: (darkMode, svgClasses = '') => (
            darkMode ? SVGS.AUTH_ICONS.EMAIL_LIGHT_ICON(svgClasses) : SVGS.AUTH_ICONS.EMAIL_DARK_ICON(svgClasses)
        ),
        authUserIcon: (darkMode, svgClasses = '') => (
            darkMode ? SVGS.AUTH_ICONS.USER_LIGHT_ICON(svgClasses) : SVGS.AUTH_ICONS.USER_DARK_ICON(svgClasses)
        ),
        authDocsIcon: (darkMode, svgClasses = '') => (
            darkMode ? SVGS.AUTH_ICONS.DOCS_LIGHT_ICON(svgClasses) : SVGS.AUTH_ICONS.DOCS_DARK_ICON(svgClasses)
        ),
        authResidentialIcon: (darkMode, svgClasses = '') => (
            darkMode ? SVGS.AUTH_ICONS.RESIDENTIAL_LIGHT_ICON(svgClasses) : SVGS.AUTH_ICONS.RESIDENTIAL_DARK_ICON(svgClasses)
        ),
    },
    coinViewIcons: {
        converter: (isSelected, darkMode, svgClasses = '') => (
            SVGS.COIN_VIEW_ICON.CONVERTER(svgClasses, (isSelected ? 'selected' : !darkMode ? 'dark' : 'light'))
        ),
        classic: (isSelected, darkMode, svgClasses = '') => (
            SVGS.COIN_VIEW_ICON.CLASSIC(svgClasses, (isSelected ? 'selected' : !darkMode ? 'dark' : 'light'))
        ),
        card: (isSelected, darkMode, svgClasses = '') => (
            SVGS.COIN_VIEW_ICON.CARD(svgClasses, (isSelected ? 'selected' : !darkMode ? 'dark' : 'light'))
        ),
    },
    walletBadge: (svgClasses = '') => (
        SVGS.WALLET_BADGE(svgClasses)
    ),
    walletBadgeBorder: (svgClasses = '') => (
        SVGS.WALLET_BADGE_BORDER(svgClasses)
    ),
    greetingsBorder: (svgClasses = '') => (
        SVGS.GREETING_BORDERS_URL(svgClasses)
    ),
    homeBorder: (svgClasses = '') => (
        SVGS.HOME_BORDERS_URL(svgClasses)
    ),
    advantageBorder: (svgClasses = '') => (
        SVGS.ADVANTAGE_BORDERS_URL(svgClasses)
    ),
    advantageIcons: {
        autoChashout: (svgClasses = '') => (
            SVGS.ADVANTAGE_ICONS.AUTO_CASHOUT(svgClasses)
        ),
        authenticate: (svgClasses = '') => (
            SVGS.ADVANTAGE_ICONS.AUTHENTICATE(svgClasses)
        ),
        unauthenticated: (svgClasses = '') => (
            SVGS.ADVANTAGE_ICONS.UNAUTHENTICATED(svgClasses)
        ),
        exchange: (svgClasses = '') => (
            SVGS.ADVANTAGE_ICONS.EXCHANGE(svgClasses)
        ),
        blockchains: (svgClasses = '') => (
            SVGS.ADVANTAGE_ICONS.BLOCKCHAINS(svgClasses)
        ),
        security: (svgClasses = '') => (
            SVGS.ADVANTAGE_ICONS.SECURITY(svgClasses)
        ),
    },
    miniSocialMedia: {
        aparat: (svgClasses = '') => (
            SVGS.SOCIAL_MEDIA.APARAT_MINI(svgClasses)
        ),
        youtube: (svgClasses = '') => (
            SVGS.SOCIAL_MEDIA.YOUTUBE_MINI(svgClasses)
        ),
        instagram: (svgClasses = '') => (
            SVGS.SOCIAL_MEDIA.INSTAGRAM_MINI(svgClasses)
        ),
        telegram: (svgClasses = '') => (
            SVGS.SOCIAL_MEDIA.TELEGRAM_MINI(svgClasses)
        ),

    },
    coinViewConnector: (active, svgClasses = '') => (
        active ? SVGS.COIN_VIEWS_CONNECTOR_BLUE(svgClasses) : SVGS.COIN_VIEWS_CONNECTOR_RED(svgClasses)
    ),
    header: {
        darkMode: (active, svgClasses = '') => (
            active ? SVGS.HEADER.DAY_MODE(svgClasses) : SVGS.HEADER.NIGHT_MODE(svgClasses)
        ),
        account: (darkMode, svgClasses = '') => (
            darkMode ? SVGS.HEADER.ACCOUNT_LIGHT(svgClasses) : SVGS.HEADER.ACCOUNT_DARK(svgClasses)
        ),
        notification: (darkMode, svgClasses = '') => (
            darkMode ? SVGS.HEADER.NOTIFICATION_LIGHT(svgClasses) : SVGS.HEADER.NOTIFICATION_DARK(svgClasses)
        ),
        logo: (darkMode, svgClasses = '') => (
            darkMode ? SVGS.HEADER.DARK_LOGO(svgClasses) : SVGS.HEADER.LIGHT_LOGO(svgClasses)
        ),

    },
    liveChatIcon: (darkMode, svgClasses = '') => (
        darkMode ? SVGS.LIVE_CHAT_ICON_LIGHT(svgClasses) : SVGS.LIVE_CHAT_ICON_DARK(svgClasses)
    ),
    referralVector: (darkMode, svgClasses = '') => (
        darkMode ? SVGS.REFERRAL_VECTOR(svgClasses) : SVGS.REFERRAL_VECTOR(svgClasses)
    ),


};

export default ImageStorage;
import React, {useEffect, useRef, useState} from 'react';


import AdminTyping from "./adminTyping";
import ClientMessageCell from "./clientMessageCell";
import AdminMessageCell from "./adminMessageCell";
import {useSelector} from "react-redux";

const Conversation = ({ selectedTicket, messages, hashedToken, currentUser}) => {
    let messagesEndRef = useRef(null);
    const {submittingMessage, ticketSubmitted} = useSelector(({support}) => support);
    const[content, setContent] = useState('')
    const scrollToBottom = () => {
        if(messagesEndRef.current)
            messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'end'  });
    }
    let submittingMessage1 = false;
    useEffect(() => {
        setTimeout(() => {
            scrollToBottom()
        }, 100);
    }, [messages]);

    useEffect(() => {
        if (submittingMessage1 && !submittingMessage || ticketSubmitted){
            setContent('');
            //TODO setFile
        }
        submittingMessage1 = submittingMessage;
    }, [submittingMessage, ticketSubmitted]);


    return (
        <div className="chat-main-content" >
            <div className="d-flex flex-nowrap chat-item flex-row-reverse">
                <img alt="alt" className="chat-profile"
                     src={require("../../../../../assets/images/header/userHeader.svg")}
                />
                <div className="chat-bubble admin-chat">
                    <div className="chat-message" style={{overflowWrap: 'break-word', whiteSpace: "pre-wrap"}}>
                        سلام کاربر گرامی زارعی هستم!

                        <br/>
                        چطوری میتونم کمکتون کنم؟
                    </div>

                </div>

            </div>
            {messages.map((conv, index) => conv.is_admin === 0 ?
                <ClientMessageCell key={index} conversation={conv} seen={conv.seen} hashedToken={hashedToken} currentUser={currentUser}/>
                :
                <AdminMessageCell key={index} conversation={conv}  hashedToken={hashedToken}/>

            )}
            <AdminTyping ticket={selectedTicket}/>
            <div id={'last'} ref={messagesEndRef}/>

        </div>
    )
};

export default Conversation;
import React from 'react';
import "../../../../../styles/ui/_typingdots.css"
import {connect} from "react-redux";

// import {
//     checkoutBuy,
//     getNetworks,
//     orderBuy,
//     switchBuyDialog,
//     switchSellDialog
// } from "../../../../actions/CurrencyResource";

class AdminTyping extends React.Component{
    constructor(props, context) {
        super(props, context);
        this.now = (new Date().getTime() / 1000);
        this.timer = 0;
        this.state = {
            showTyping: false
        };
    }


    componentWillReceiveProps(nextProps, nextContext) {
        clearTimeout(this.timer);
        if(nextProps.selectedTicket.isAdminTyping && ((new Date().getTime()) - nextProps.selectedTicket.adminTypingTime * 1000 <= 1800)) {
            this.timer = setTimeout(() => {
                this.setState({showTyping: false})
            }, 1700);
            this.setState({showTyping: true})
        }
    }

    render() {
        return (
            (this.props.selectedTicket.isAdminTyping && this.state.showTyping) ? (
                <div className="d-flex flex-nowrap chat-item flex-row-reverse">

                    <img alt="alt" className="chat-profile"
                         src={require("../../../../../assets/images/header/userHeader.svg")}
                    />
                    <div className="chat-bubble admin-chat">

                        <div className="chat-message d-inline-flex">
                            <div className="typing">
                                <div className="typing__dot"></div>
                                <div className="typing__dot"></div>
                                <div className="typing__dot"></div>
                            </div>
                            Support is Typing

                        </div>
                    </div>
                </div>
            ) : ''
        )
    }

};

const mapStateToProps = ({support}) => {
    const {selectedTicket} = support;
    return {selectedTicket, adminTypingTime: selectedTicket.adminTypingTime};

};

export default connect(mapStateToProps, {
})(AdminTyping);

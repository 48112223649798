import React, {useEffect} from 'react';
// import {toPersianDate} from "../../../../util/Utilities";
import {Link} from "react-router-dom";
import CheckIcon from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';
// import {STATUS} from "../../data/constants";
// import userAvatar from "../../../../assets/images/user_avatar.png";
import {toPersianDate} from "../../../../../util/Utilities";
import {DOMAIN} from "../../../../../constants/Path";


const ClientMessageCell = ({conversation, seen, hashedToken, currentUser, key}) => {

    // const [seen, setSeen] = useState(conversation.seen)
    // //
    useEffect(() => {
        // setSeen(conversation.seen)
    }, [seen]);
    return (
        <div className="d-flex flex-nowrap chat-item ">

            <img alt="alt" className="chat-profile"
                 src={require("../../../../../assets/images/header/userHeader.svg")}     />

            <div className="chat-bubble">
                {conversation.file_attached ? ['jpg', 'jpeg', 'png'].includes(conversation.file_extension) ?
                        <img alt="alt" src={'/core/api/storage/ticket/' + conversation.file_id + "/" + hashedToken}  className="chat-img"/> :
                        ['rar', 'zip', 'pdf'].includes(conversation.file_extension) ?
                            <Link target="_blank" to={'/core/api/storage/ticket/' + conversation.file_id + "/" + hashedToken}>
                                <div className="d-inline-flex" style={{backgroundColor: "rgba(126,126,125,0.23)", borderRadius: "15px"}}>
                                    <span className="zmdi zmdi-download m-2 jr-fs-2xl"></span>
                                    <div className="small text-left text-black-80 m-2">{conversation.file_name}</div>
                                </div>

                            </Link> : ""
                    : ""}
                <div className="chat-message" style={{overflowWrap: 'break-word', whiteSpace: "pre-wrap"}}>{conversation.content}</div>
                <div className="chat-footer">
                    {seen ? <DoneAllIcon className="chat-seen"/> : <CheckIcon className="chat-seen"/> }
                    <div className="chat-time">
                        {toPersianDate(conversation.created_at)}
                    </div>

                </div>

            </div>

        </div>

    )
};

export default ClientMessageCell;
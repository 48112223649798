import axios from "./Api";
import {
    CREDIT_CARD_ADDED,
    CREDIT_CARDS_FETCHED,
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS
} from "../constants/ActionTypes";

class ResourceManager {
    constructor(resourcePath) {
        this.resourcePath = resourcePath;
        this.dataChangeCallback = null;
    }
    setOnDataChange(dataChangeCallback) {
        this.dataChangeCallback = dataChangeCallback;
    }
    list(props) {
        return (dispatch) => {
            dispatch({type: FETCH_START});
            axios.get(this.resourcePath, props
            ).then(({data}) => {

                if (data.result) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: CREDIT_CARDS_FETCHED, payload: data.cards});
                } else {
                    dispatch({type: FETCH_ERROR, payload: data.error});
                }
            }).catch(function (error) {
                dispatch({type: FETCH_ERROR, payload: error.message});

            });
        }

    }
    add(props, execution = null) {
        return (dispatch) => {
            dispatch({type: FETCH_START});
            axios.post(this.resourcePath, props
            ).then(({data}) => {
                if(typeof execution === 'function')
                    execution(data);
                if (data.result) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({type: CREDIT_CARD_ADDED, payload: data.card});
                } else {
                    dispatch({type: FETCH_ERROR, payload: data.error});
                }
            }).catch(function (error) {
                dispatch({type: FETCH_ERROR, payload: error.message});

            });
        }

    }
    edit(props, execution = null) {
        axios.put(this.resourcePath + props._id, props
        ).then(({data}) => {
            if(typeof execution === 'function')
                execution(data);

        }).catch(error => {
            if(typeof execution === 'function')
                execution({result: false, errors: {server: [error.message]}})
        });
    }
    get(id, execution = null) {
        axios.get(this.resourcePath + id, {}
        ).then(({data}) => {
            if(typeof execution === 'function')
                execution(data);
        }).catch(error => {
            if(typeof execution === 'function')
                execution({result: false, errors: {server: [error.message]}})
        });
    }
    delete(id, execution = null) {
        axios.delete(this.resourcePath + id, {}
        ).then(({data}) => {
            if(typeof execution === 'function')
                execution(data);

        }).catch(error => {
            if(typeof execution === 'function')
                execution({result: false, errors: {server: [error.message]}})
        });
    }


}

export default ResourceManager;

import React from 'react';
import {toPersianDate} from "../../../util/Utilities";
import {seenUserNotice} from "../../../util/Socket";
import {NOTICE_TYPES, NOTICE_UNITS} from "../../../panel/routes/data/constants";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import TextsmsOutlinedIcon from "@material-ui/icons/TextsmsOutlined";
import RepeatOutlinedIcon from "@material-ui/icons/RepeatOutlined";
import Badge from '@material-ui/core/Badge';
import {useDispatch} from "react-redux";
import {switchNotificationDialog} from "../../../actions/Notice";


const NotificationItem = ({notification}) => {
    const {id, title, content, seen, unit, notice_type, created_at} = notification;
    const dispatch = useDispatch();
    const switchDialog = (e) => {
        dispatch(switchNotificationDialog(true, id));
        seenUserNotice(id);
        e.preventDefault();
        e.stopPropagation();
    }

    function createUnitIcons(type) {
        if ([

            NOTICE_UNITS.VERIFY_INFO,
            NOTICE_UNITS.AUTH_REJECTED,
            NOTICE_UNITS.AUTH_GRANTED,
            NOTICE_UNITS.VERIFY_MOBILE,
            NOTICE_UNITS.VERIFY_EMAIL,
            NOTICE_UNITS['2FA_CHANGED'],
            NOTICE_UNITS.PASSWORD_CHANGED,
            NOTICE_UNITS.AFFILIATION,
            NOTICE_UNITS.EMAIL_VERIFIED
        ].includes(unit))
            return <SettingsOutlinedIcon className={
                [NOTICE_TYPES.NOTICE_TYPE_DANGER].includes(type) ? "text-danger" : [NOTICE_TYPES.NOTICE_TYPE_NORMAL].includes(type)
                    ? "text-primary" : [NOTICE_TYPES.NOTICE_TYPE_WARNING].includes(type) ? "text-warning" : ""
            }/>;
        else if (
            [
                NOTICE_UNITS.WITHDRAW_SENT,
                NOTICE_UNITS.WITHDRAW_TIME_CHANGED,
                NOTICE_UNITS.WITHDRAW_CANCELED,
                NOTICE_UNITS.CARD_GRANTED,
            ].includes(unit))
            return <AccountBalanceWalletOutlinedIcon className={
                [NOTICE_TYPES.NOTICE_TYPE_DANGER].includes(type) ? "text-danger" : [NOTICE_TYPES.NOTICE_TYPE_NORMAL].includes(type)
                    ? "text-primary" : [NOTICE_TYPES.NOTICE_TYPE_WARNING].includes(type) ? "text-warning" : ""
            }/>;
        else if ([
            NOTICE_UNITS.TICKET_ANSWERED,
            NOTICE_UNITS.TICKET_CLOSED,
            NOTICE_UNITS.ADMIN_NOTICE
        ].includes(unit))
            return <TextsmsOutlinedIcon className={
                [NOTICE_TYPES.NOTICE_TYPE_DANGER].includes(type) ? "text-danger" : [NOTICE_TYPES.NOTICE_TYPE_NORMAL].includes(type)
                    ? "text-primary" : [NOTICE_TYPES.NOTICE_TYPE_WARNING].includes(type) ? "text-warning" : ""
            }/>;
        else if ([
            NOTICE_UNITS.SELL_ADMIN_PROGRESS,
            NOTICE_UNITS.BUY_ORDER_DONE,
            NOTICE_UNITS.MAX_BUY_INCREASED,
            NOTICE_UNITS.SELL_ORDER_DONE,
        ].includes(unit))
            return <RepeatOutlinedIcon className={
                [NOTICE_TYPES.NOTICE_TYPE_DANGER].includes(type) ? "text-danger" : [NOTICE_TYPES.NOTICE_TYPE_NORMAL].includes(type)
                    ? "text-primary" : [NOTICE_TYPES.NOTICE_TYPE_WARNING].includes(type) ? "text-warning" : ""
            }/>
    }

    return (
        <li onClick={switchDialog} className={seen === 0 ? "media" : "seen-media media"}>
            <div className="media-body">
                <div className="notification-badge-title">
                    {seen === 0 ?

                        <Badge className="notice-badge-wrapper" variant="dot">
                            {createUnitIcons(notice_type)}
                        </Badge>
                        :

                        <Badge className="notice-badge-wrapper" invisible={true}>
                            {createUnitIcons(notice_type)}
                        </Badge>

                    }
                    <div  className='message-title'>{title}</div>
                </div>

                <div className="notification-message">

                    {content.substring(0, 45)}...
                </div>
                <div className="notification-footer">
                    <div className="notification-time">
                        {toPersianDate(created_at)}
                    </div>
                </div>
            </div>


        </li>
    );
};


export default NotificationItem

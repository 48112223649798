import {FETCH_ERROR, FETCH_START, FETCH_SUCCESS, HIDE_MESSAGE, SHOW_ALERT, SHOW_MESSAGE} from '../constants/ActionTypes'

const INIT_STATE = {
  messageUnit: '',
  errors: {},
  hasErrors: false,
  loading: false,
  messages: {},
  alarm: {},
  errorRound: 1,
  messageRound: 1,
  alarmRound: 1,
};

export default (state = INIT_STATE, action) => {
  if(!action.payload) action.payload = {};
  switch (action.type) {
    case FETCH_START: {
      return {...state, errors: {}, hasErrors: false, messages: {}, loading: true};
    }
    case FETCH_SUCCESS: {
      return {...state, errors: {}, hasErrors: false, messages: action.payload.messages ?? {}, messageUnit: action.payload.unit ?? '', messageRound: ++state.messageRound, loading: false};
    }
    case SHOW_MESSAGE: {
      return {...state, errors: {}, hasErrors: false, messages: action.payload.messages ?? {}, messageUnit: action.payload.unit ?? '', messageRound: ++state.messageRound, loading: false};
    }
    case SHOW_ALERT: {
      return {...state, errors: {}, hasErrors: false, alarm: action.payload, loading: false};
    }
    case FETCH_ERROR: {
      return {...state, loading: false, errors: action.payload.errors ?? {}, messageUnit: action.payload.unit ?? '', errorRound: ++state.errorRound, hasErrors: true, messages: {}};
    }
    case HIDE_MESSAGE: {
      return {...state, loading: false, errors: {}, hasErrors: false, messages: {}};
    }
    default:
      return state;
  }
}

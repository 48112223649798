import React from "react";
import {Radio, RadioGroup, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import {idSelected, isInt} from "../../../../util/Validator";
import IntlMessages from "../../../../util/IntlMessages";
import {showError} from "../../../components/Notifier";
import {WALLET_ONLINE_DEPOSIT} from "../../../../constants/Units";
import {onlineDeposit, switchDepositDialog, useGateway} from "../../../../actions/Wallet";
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Link} from "react-router-dom";
import {orderBuy, switchBuyDialog} from "../../../../actions/CurrencyResource";
import {DIGITAL_CURRENCIES, GATEWAYS, STATUS, TRANSFER_TYPES} from "../../data/constants";
import {fa2enNumbers} from "../../../../util/Utilities";
import {findBankByPAN} from "@alibaba-aero/griphook";
import {bankIcons} from "../../../../constants/Banks";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import IDpay from "../../../../assets/images/gateways/IDpay.svg"
import Payir from "../../../../assets/images/gateways/Pay.ir.svg"
import PayStar from "../../../../assets/images/gateways/PayStar.svg"
import Vandar from "../../../../assets/images/gateways/Vandar.svg"
import Zibal from "../../../../assets/images/gateways/zibal.svg"
import Ayandeh from "../../../../assets/images/gateways/ayandeh.svg"
import Jibimo from "../../../../assets/images/gateways/jibimo.svg"
import Payping from "../../../../assets/images/gateways/payping.svg"
import md5 from "md5";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import {DOMAIN} from "../../../../constants/Path";


const gateWaysIcon = {
    1: Zibal,
    2: Payir,
    3: IDpay,
    4: PayStar,
    5: PayStar,
    9: Vandar,
    10: Ayandeh,
    13: Payir,
    16: Jibimo,
    17: Payping,
};


class OnlineForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hashedToken: md5(props.token),
            openDepositDescription: false,
            cards: this.props.cards,
            postFormRef: '',
            minimumDeposit: "",
            postForm: {
                link: '',
                params: {}
            },
            inputs: {
                amount: props.amount ?? '',
                selectCard: (this.props.cards.length > 0 ? this.props.cards[0]['id'] : 0),
                // gateway: 1,
                gate_id: this.getFirstGatewayID(this.props.gateways),
                gatewayProvider: 0,
                currencyType: DIGITAL_CURRENCIES.IRR,
                rulesChecked: true,
            },
            formErrors: {
                gate_id: "",
                amount: "",
                selectCard: "",
                rulesChecked: '',
            },
        }
        let selected_gateway = this.props.gateways.find((selected) => {
            return selected.id == this.state.inputs.gate_id;
        });
        if (selected_gateway)
            this.state.inputs.gatewayProvider = selected_gateway.gateway;
    };

    getFirstGatewayID = (gateways) => {
        if (gateways) {
            let filteredGateways = gateways.filter((gateway) => gateway.gate_type === GATEWAYS.ONLINE && gateway.depositable > 0);
            if (filteredGateways.length)
                return filteredGateways[0]['id'].toString();
            // this.gateMinDeposit();
        }
        return '0';
    };

    formValidated = () => {
        const {inputs} = this.state;
        const context = this;
        let isValid = true;
        Object.keys(inputs).map(function (key, index) {
            isValid &= context.validateFormValue(key, inputs[key]);
        });
        const {formErrors} = this.state;
        Object.keys(formErrors).map(function (key, index) {
            isValid &= !formErrors[key];
        });
        return isValid;
    };

    validateFormValue = (name, value) => {

        const {gateways} = this.props;
        let {formErrors, inputs, minimumDeposit} = this.state;

        let selected_gateway = gateways.find(function cond(selected) {
            return selected.id == inputs.gate_id;

        });
        if (selected_gateway) {
            this.setState({minimumDeposit: selected_gateway.min_deposit});
        }

        switch (name) {

            case 'amount':
                // value = clearCommas(value);
                var pRegex = /^[\u0600-\u06FF\s]+$/;
                var EnRegex = /^[a-zA-Z]+$/;
                value = fa2enNumbers(value);
                value = value.replace(pRegex, '');
                value = value.replace(EnRegex, '');
                if (!isInt({input: value}))
                    formErrors.amount = <IntlMessages id="error.content.amount.numeric"/>;
                else if (value < minimumDeposit)
                    formErrors.amount = <><IntlMessages id="error.content.amount.min.value"/>
                        {minimumDeposit}
                        <IntlMessages id="error.content.amount-min-value-so-on"/>
                    </>;
                else if (value > 24900000)
                    formErrors.amount = <><IntlMessages id={"error.content.amount.max.value"}/>
                        {(24900000).toLocaleString()}<IntlMessages id="error.content.amount-max-value-so-on"/></>
                else {
                    formErrors.amount = "";
                }
                value = value.toString().length ? parseInt(value) : '';
                break;

            case 'selectCard':
                if (!idSelected({input: value}))
                    formErrors.selectCard = <IntlMessages id="error.content.select_card.null"/>;
                else
                    formErrors.selectCard = "";
                break;

            case 'gate_id':
                inputs.gatewayProvider = 0;
                if (!idSelected({input: value}))
                    formErrors.gate_id = <IntlMessages id="error.content.select_gateway.null"/>;
                else
                    formErrors.gate_id = "";
                let selected_gateway = gateways.find(function cond(selected) {
                    return selected.id == value;
                });
                if (selected_gateway) {
                    this.setState({minimumDeposit: selected_gateway.min_deposit}, function () {
                        this.validateFormValue('amount', inputs.amount);
                    });
                    inputs.gatewayProvider = selected_gateway.gateway;
                }

                break;
        }
        inputs[name] = value;
        this.setState({formErrors, inputs});

        return !formErrors[name];
    };

    handleChange = e => {
        let {name, value, checked} = e.target;
        this.validateFormValue(name, value, checked);
    };

    postForm(link, params = {}) {
        return (
            <form style={{display: 'none'}} action={link} method="POST" target='_blank'
                  ref={(ref) => this.state.postFormRef = ref}>
                {
                    Object.keys(params).map((key) => (
                        <input name={key} value={params[key]}/>
                    ))
                }
            </form>
        );
    };

    componentWillReceiveProps(nextProps, nextContext) {
        const {inputs} = this.state;
        const stateUpdate = {
            cards: nextProps.cards,
        };

        if (!+inputs.gate_id && nextProps.gateways.length) {
            inputs.gate_id = this.getFirstGatewayID(nextProps.gateways);
            let selected_gateway = nextProps.gateways.find(function cond(selected) {
                return selected.id == inputs.gate_id;
            });
            if (selected_gateway)
                inputs.gatewayProvider = selected_gateway.gateway;
        }

        if (nextProps.amount && nextProps.amount !== this.props.amount) {
            inputs.amount = nextProps.amount;
        }
        this.setState(stateUpdate, () => {
            if (!inputs.selectCard) {
                this.validateFormValue('selectCard', nextProps.cards[0] ? nextProps.cards[0]['id'] : 0);
            }
        });

        if (!nextProps.gatewayUsed && nextProps.gateway.link) {
            nextProps.useGateway();
            if (nextProps.gateway.method === 'GET') {
                window.open(nextProps.gateway.link, '_blank').focus();
            } else if (nextProps.gateway.method === 'POST') {
                this.setState({
                    postForm: {
                        link: nextProps.gateway.link,
                        params: nextProps.gateway.params
                    }
                }, () => {
                    this.state.postFormRef.submit();
                })
                this.postForm(nextProps.gateway.link, nextProps.gateway.params);
            }
            this.props.switchDepositDialog({state: false});
        }
        stateUpdate.inputs = inputs;
        this.setState(stateUpdate);
    }

    handleCheck = e => {
        const {name, checked} = e.target;
        const{formErrors, inputs} = this.state;
        inputs[name] = !!checked;
        formErrors.rulesChecked =
            !checked ? "پذیرش قوانین الزامی است." : "";
        this.setState({inputs, formErrors});

    };

    handleSubmit = (e) => {
        e.preventDefault();
        const {currentUser} = this.props;
        const {inputs, hashedToken} = this.state;
        const {amount, selectCard, currencyType, gate_id} = inputs;
        if (this.formValidated()) {
            if (![STATUS.ACCEPTED_BY_ADMIN, STATUS.ACCEPTED_BY_SYSTEM, STATUS.ACCEPTED_BY_EX_API].includes(currentUser.phone_number_verified))
                showError(WALLET_ONLINE_DEPOSIT, 'phone_number.not_verified');
            if (![STATUS.ACCEPTED_BY_ADMIN, STATUS.ACCEPTED_BY_SYSTEM, STATUS.ACCEPTED_BY_EX_API].includes(currentUser.national_card_verified))
                showError(WALLET_ONLINE_DEPOSIT, 'user.not_verified');
            else {
                window.open(`/core/api/deposit/redirectDeposit?token=${hashedToken}&o_amount=${amount}&card_id=${selectCard}&gate_type=1&gateway=${gate_id}&user_id=${currentUser.id}&o_currency=${currencyType}&transfer_id=${this.props.transferID ?? 0}`)
                this.props.switchDepositDialog({state: false});
            }
        } else
            showError(WALLET_ONLINE_DEPOSIT, 'form.invalid');

    };

    handleRequestClose = () => {
        this.props.switchDepositDialog({state: false});
        this.props.switchBuyDialog({state: false});
    };

    handleClick = () => {
        const {openDepositDescription} = this.state;
        this.setState({openDepositDescription: !openDepositDescription});

    };

    handleKeyPress = e => {
        if (e.key === 'Enter') {
            this.handleSubmit(e)
        }
    }

    render() {
        const {inputs, formErrors, cards, postForm, openDepositDescription} = this.state;
        const {requestingOnline, loadingCards, loadingGateways, gateways, selectedOrder} = this.props;
        let onlineGateAllowed = true;
        if (this.props.transferID && selectedOrder) {
            if (selectedOrder.factor && selectedOrder.factor.type === TRANSFER_TYPES.BUY && selectedOrder.factor.d_currency == DIGITAL_CURRENCIES.WM)
                onlineGateAllowed = false;
        }
        return (
            onlineGateAllowed ? (
                <form onSubmit={this.handleSubmit} className="online-form-wrapper">
                    <div className="inputs-wrapper">
                        <div className="card-amount-wrapper">
                            <div className="select-card-input">
                                <div className="desc">
                                    کارت بانکی تایید شده ی خود را انتخاب کنید:
                                </div>
                                <TextField
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label="انتخاب کارت بانکی"
                                    select
                                    onChange={this.handleChange}
                                    name="selectCard"
                                    variant="outlined"
                                    fullWidth
                                    value={inputs.selectCard}
                                    disabled={(!loadingCards && !cards.length)}
                                    error={!!formErrors.selectCard}
                                    className={formErrors.selectCard ? "error" : null}
                                    size="small"
                                    style={cards.length ? {textAlign: 'left ', direction: 'ltr'} : {}}
                                >
                                    {
                                        loadingCards ?
                                            <MenuItem key={0} value={0}>
                                                {<IntlMessages id="button.loading_card"/>}
                                                <CircularProgress size={24}/>
                                            </MenuItem>
                                            :
                                            (
                                                cards.length === 0 ?
                                                    <MenuItem key={0} value={0}
                                                              style={{textAlign: 'right ', direction: 'rtl'}}>
                                                        <Link to="/panel/creditcard" onClick={this.handleRequestClose}>
                                                            <IntlMessages id="title.message.add-credit-card"/>
                                                        </Link>
                                                    </MenuItem> :
                                                    cards.map((option) => (
                                                        <MenuItem key={option.id} value={option.id}
                                                                  style={{textAlign: 'left ', direction: 'ltr'}}>
                                                            <div className="credit-card-wrapper">
                                                                {findBankByPAN(option.card_number).icon ?
                                                                    <img alt="alt" className="credit-card-img"
                                                                         src={bankIcons[findBankByPAN(option.card_number).icon.replace('.svg', '')]}/> : ""}
                                                                <div className="account-num">
                                                                    {option.card_number.substring(0, 4) +
                                                                    option.card_number.substring(4, option.card_number.length - 8).replace(/\d/g, "") +
                                                                    option.card_number.substring(8, option.card_number.length - 4).replace(/\d/g, "*")
                                                                    + option.card_number.substring(option.card_number.length - 4)}</div>
                                                                <div className="credit-card-num">
                                                                    ({option.account_number})
                                                                </div>

                                                            </div>

                                                        </MenuItem>)
                                                    ))
                                    }

                                </TextField>
                            </div>
                            <div className="amount-input">
                                <div className="desc">
                                    مبلغ مورد نظر خود را وارد کنید:
                                </div>


                                <input type="hidden" name="amirs-gold-input" value="Don't remove this input while it is useless" />


                                <TextField
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    id="amount"
                                    label={<IntlMessages id="title.price"/>}
                                    onChange={this.handleChange}
                                    onKeyPress={this.handleKeyPress}
                                    fullWidth
                                    variant="outlined"
                                    type="number"
                                    name="amount"
                                    error={!!formErrors.amount}
                                    className={formErrors.amount ? "error" : null}
                                    size="small"
                                    value={inputs.amount}
                                    InputProps={{
                                        inputMode: 'numeric',
                                        endAdornment: <span className="input-end-adornment">
                                        <IntlMessages id={"title.message.toman"}/>
                                    </span>,
                                    }}
                                />
                                <div
                                    className="amount-equal">معادل: {((inputs.amount ?? 0) * 10).toLocaleString()} ریال
                                </div>
                                {formErrors.amount && (
                                    <div className="error-box">
                                        <img alt="alt" src={require("../../../../assets/images/V2/error.svg")}/>
                                        {formErrors.amount}
                                    </div>
                                )}

                            </div>
                        </div>


                            <div className="gate-way-wrapper">
                                <div className="desc">
                                    درگاه مورد نظر خود را انتخاب کنید:
                                </div>
                                <div className="gate-way-radios-wrapper">
                                    <div className="gate-ways-inner-wrapper">
                                        <FormControl  disabled={(!loadingGateways && !gateways.length)} error={!!formErrors.gate_id} component="fieldset">
                                            <RadioGroup aria-label="gate_id" name="gate_id" value={inputs.gate_id} onChange={this.handleChange}>
                                                {loadingGateways ?
                                                    <MenuItem key={0} value={0}>
                                                        {<IntlMessages id="button.loading_gateways"/>}
                                                        <CircularProgress size={24}/>
                                                    </MenuItem>
                                                    :
                                                    (
                                                        gateways.filter((gateway) => gateway.gate_type === GATEWAYS.ONLINE && gateway.depositable > 0).length === 0 ?
                                                            (
                                                                <FormControlLabel  labelPlacement="top" value={0} control={<Radio/>} label={
                                                                    <div className="gate-way-label" onClick={this.props.goToOffline}>
                                                                        درگاه های آنلاین موقتا غیر فعال می باشد. لطفا از طریق منوی شارژ حساب و از طریق <div className="buttons-wrapper">
                                                                        <div className="confirm-btn-wrapper">
                                                                            <Link
                                                                                variant="contained"
                                                                                className="confirm-btn MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-fullWidth"
                                                                                disabled={requestingOnline}
                                                                                // target='_blank'

                                                                                // to={`/core/api/deposit/redirectDeposit?token=${hashedToken}&o_amount=${amount}&card_id=${selectCard}&gateway=${gate_id}&o_currency=${currencyType}&transfer_id=${this.props.transferID ?? 0}` }
                                                                                // onClick={this.handleSubmit}
                                                                                type="submit"
                                                                            >
                                                                                واریز آفلاین
                                                                            </Link>
                                                                        </div>
                                                                    </div>اقدام به شارژ کیف پول خود نمائید.
                                                                    </div>

                                                                }/>


                                                            ) :
                                                            // <MenuItem key={0} value={0}>
                                                            //     {/*{<IntlMessages id="error.field.no_gateways_exist"/>}*/}
                                                            // </MenuItem> :
                                                            gateways.filter((gateway) => gateway.gate_type === GATEWAYS.ONLINE && gateway.depositable > 0).map((option) => (

                                                                    <FormControlLabel  labelPlacement="top" value={option.id.toString()} control={<Radio/>} label={
                                                                        <div className="gate-way-label">
                                                                            <img alt="alt" className="gate-way-logos"
                                                                                 src={gateWaysIcon[option.gateway]}/>
                                                                            <div className="gate-way-title">
                                                                                {option.title}
                                                                            </div>
                                                                        </div>} />

                                                                )
                                                            ))
                                                }

                                                {/*<FormControlLabel value="female" control={<Radio />} label="Female" />*/}
                                                {/*<FormControlLabel value="male" control={<Radio />} label="Male" />*/}
                                                {/*<FormControlLabel value="other" control={<Radio />} label="Other" />*/}
                                                {/*<FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" />*/}
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>

                            </div>
                            {/*<div className="gate-way-input">*/}
                            {/*    <div className="desc">*/}
                            {/*        درگاه بانکی را انتخاب کنید.*/}
                            {/*    </div>*/}
                            {/*    <TextField*/}
                            {/*        label={<IntlMessages id="title.bank-gate-way"/>}*/}
                            {/*        InputLabelProps={{*/}
                            {/*            shrink: true,*/}
                            {/*        }}*/}
                            {/*        select*/}
                            {/*        onChange={this.handleChange}*/}
                            {/*        name="gate_id"*/}
                            {/*        variant="outlined"*/}
                            {/*        fullWidth*/}
                            {/*        value={inputs.gate_id}*/}
                            {/*        disabled={(!loadingGateways && !gateways.length)}*/}
                            {/*        error={!!formErrors.gate_id}*/}
                            {/*        className={formErrors.gate_id ? "error" : null}*/}
                            {/*        size="small">*/}
                            {/*        {*/}
                            {/*            loadingGateways ?*/}
                            {/*                <MenuItem key={0} value={0}>*/}
                            {/*                    {<IntlMessages id="button.loading_gateways"/>}*/}
                            {/*                    <CircularProgress size={24}/>*/}
                            {/*                </MenuItem>*/}
                            {/*                :*/}
                            {/*                (*/}
                            {/*                    gateways.length === 0 ?*/}
                            {/*                        <MenuItem key={0} value={0}>*/}
                            {/*                            {<IntlMessages id="error.field.no_gateways_exist"/>}*/}
                            {/*                        </MenuItem> :*/}
                            {/*                        gateways.filter((gateway) => gateway.gate_type === GATEWAYS.ONLINE).map((option) => (*/}

                            {/*                                <MenuItem key={option.id} value={option.id}>*/}
                            {/*                                    <img alt="alt" className="gate-way-logos mx-1"*/}
                            {/*                                         src={gateWaysIcon[option.gateway]}/>*/}
                            {/*                                    {option.title}*/}
                            {/*                                </MenuItem>*/}

                            {/*                            )*/}
                            {/*                        ))*/}
                            {/*        }*/}

                            {/*    </TextField>*/}
                            {/*</div>*/}


                    </div>
                    {/*<div className="rules-check-wrapper">*/}
                    {/*   /!* <FormControlLabel*!/*/}
                    {/*   /!*     control={*!/*/}
                    {/*   /!*         <Checkbox className="rules-check" tabIndex={5}  checked={inputs.rulesChecked}  onChange={this.handleCheck} name="rulesChecked" />*!/*/}
                    {/*   /!*     }*!/*/}
                    {/*   /!*     label={ <> <a onClick={() => this.handleClick()}  className="rules-desc" >*!/*/}
                    {/*   /!* <span>*!/*/}
                    {/*   /!*     قوانین واریز وجه*!/*/}

                    {/*   /!* </span>*!/*/}

                    {/*   /!*     </a>*!/*/}
                    {/*   /!*         {" "}*!/*/}
                    {/*   /!*         <span>*!/*/}
                    {/*   /!*    را می پذریم*!/*/}
                    {/*   /!*</span>*!/*/}
                    {/*   /!*     </>}*!/*/}
                    {/*   /!* />*!/*/}
                    {/*   */}


                    {/*</div>*/}
                    {this.state.formErrors.rulesChecked.length > 0 && (
                        <div className="error-box">
                            <img alt="alt" src={require("../../../../assets/images/V2/error.svg")}/>
                            {this.state.formErrors.rulesChecked}
                        </div>

                    )}
                    <div className="bottom-wrapper">
                        {inputs.gatewayProvider === 13 ? (
                            <div className="warning-box">
                                توجه*: الزاما با کارت انتخاب شده پرداخت نمائید. در غیراینصورت ممکن است اصلاحیه تراکنش تا
                                24 ساعت به طول انجامد.
                            </div>
                        ) : ''}
                        <div className="buttons-wrapper">
                            <div className="confirm-btn-wrapper">
                                <Link
                                    variant="contained"
                                    className="confirm-btn MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-fullWidth"
                                    disabled={requestingOnline}
                                    target='_blank'

                                    // to={`/core/api/deposit/redirectDeposit?token=${hashedToken}&o_amount=${amount}&card_id=${selectCard}&gateway=${gate_id}&o_currency=${currencyType}&transfer_id=${this.props.transferID ?? 0}` }
                                    onClick={this.handleSubmit}
                                    type="submit"
                                >
                                    {requestingOnline ? <><IntlMessages id="button.requesting_payment"/>
                                        <CircularProgress
                                            size={24} color="inherit"/></> : <IntlMessages id="button.deposit_online"/>}
                                </Link>
                            </div>
                            <div className="cancel-btn-wrapper">
                                <Button
                                    variant="contained"
                                    onClick={this.handleRequestClose}
                                    fullWidth
                                    className="cancel-btn"
                                >
                                    <IntlMessages id="button.cancel"/>

                                </Button>
                            </div>

                            {/*</div>*/}
                        </div>
                        <div className="rules-wrapper">
                            <List
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                className="rules-list"
                            >
                                <ListItem button onClick={() => {
                                    this.handleClick()
                                }} className="rules-list-item">
                                    <ListItemText>
                                        <FormControlLabel
                                            control={
                                                <Checkbox className="rules-check" tabIndex={5}
                                                          checked={inputs.rulesChecked} onChange={this.handleCheck}
                                                          name="rulesChecked"/>
                                            }
                                            label={<> <a  className="rules-desc">
                            <span>
                                قوانین واریز وجه

                            </span>

                                            </a>
                                                {" "}
                                                <span>
                               را می پذیرم
                           </span>
                                            </>}
                                        />
                                    </ListItemText>
                                    {openDepositDescription ? <ExpandLess/> : <ExpandMore/>}
                                </ListItem>
                                <Collapse in={openDepositDescription} timeout="auto" unmountOnExit>
                                    <div className="rules-card">
                                        <div className="rule-row">
                                            <IntlMessages id={"contents.deposit.rules1"}/>
                                        </div>
                                        <div className="rule-row">
                                            <IntlMessages id={"contents.deposit.rules2"}/>
                                        </div>
                                        <div className="rule-row">
                                            <IntlMessages id={"contents.deposit.rules3"}/>
                                        </div>
                                    </div>
                                </Collapse>
                            </List>
                        </div>
                    </div>
                    {this.postForm(postForm.link, postForm.params)}
                </form>
            ) : (
                <div className="warning-box">
                    خرید و فروش وبمانی با درگاه بانکی غیرفعال است.
                </div>
            )

        )
    }
}

const mapStateToProps = ({auth, commonData, wallet, currency, settings}) => {
    const {width} = settings;
    const {hasErrors} = commonData;
    const {currentUser, token} = auth;
    const {selectedOrder} = currency;
    const {requestingOnline, paymentURL, gatewayUsed, gateway} = wallet;
    return {requestingOnline, paymentURL, gatewayUsed, gateway, hasErrors, currentUser, token, selectedOrder, width};
};
export default connect(mapStateToProps, {
    onlineDeposit,
    useGateway,
    orderBuy,
    switchDepositDialog,
    switchBuyDialog
})(OnlineForm);


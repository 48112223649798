import React from "react"
import {connect} from "react-redux";
import {
    getConversationInformation,
    submitTicket,
    submitTicketMessage,
    switchChatBox,
    switchLiveChatContentStepper
} from "../../../../actions/SupportResource";
import {withStyles} from "@material-ui/core";
import {TICKET_SHOW_TYPES} from "../../../routes/data/constants";
import Conversation from "./Conversation/conversation";
import md5 from "md5";
import IconButton from "@material-ui/core/IconButton";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {fa2enNumbers} from "../../../../util/Utilities";
import {ticketTyping} from "../../../../util/Socket";
import LinearProgressWithLabel from "../../../routes/components/linearProgressWithLabel";
import IntlMessages from "../../../../util/IntlMessages";
import {SUPPORT} from "../../../../constants/Units";
import createNotification from "../../../../util/Notifier";
import CustomScrollbars from "../../../../util/CustomScrollbars";


const acceptableExtensions = ['jpeg', 'png', 'jpg', 'pdf', 'rar', 'zip'];
const maxFileSize = 15728640;
const MAX_MESSAGE_LENGTH = 2048;
const styles = (theme) => ({

    cardRoot: {
        height: "inherit",
        '& .MuiCardContent-root': {
            padding: 0
        }

    },
    cardHeader: {
       // '& .MuiCardHeader-root' : {
        padding: "0px !important",
        paddingLeft: "5px !important",
        boxShadow: '0 3px 8px -5px #456777'
       // }
    },
    cardContent: {
        padding: "0 !important",
        flex: "1",
        overflowY: "scroll"
    },
    cardAction: {
        padding: "0 !important"
    },
    chatHeader: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "4px"


    },
    closeTicketButton: {
        background: "linear-gradient(45deg, #603a3a, #ff4141)"
    }
});

const management_units = [
    {
        value: "3",
        label: "فنی"
    },
    {
        value: "4",
        label: "مالی"
    },
    {
        value: "5",
        label: "انتقادات و پیشنهادات"
    }
];

class ChatWrapper extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            file: props.ticketSubmitted || props.ticketSubmitFailed ? false : "",
            content: '',
        }


    }


    componentWillReceiveProps(nextProps, nextContext) {
        if ((this.props.submittingMessage && !nextProps.submittingMessage) || nextProps.ticketSubmitted)
            this.setState({content: '', file: null});
    }



    handleSubmit = (e) => {
        const {stepIndex} = this.props;
        this.props.switchLiveChatContentStepper({stepIndex : stepIndex + 1})
    }

    onChangeFile(event) {
        event.stopPropagation();
        event.preventDefault();
        let succeed = true;
        var file = event.target.files[0];
        if (!file)
            return;
        if (!['image/jpeg', 'image/jpg', 'image/png', 'application/x-compressed', 'application/pdf'].includes(file.type)) {
            createNotification('error', <IntlMessages
                id={'message.title.' + SUPPORT}/>, 'فرمت های مجاز برای ارسال: ' + acceptableExtensions.join(', '))
            succeed = false;
        }
        if (file.size > maxFileSize) {
            createNotification('error', <IntlMessages
                id={'message.title.' + SUPPORT}/>, 'حداکثر حجم مجاز مجاز برای ارسال: ' + (maxFileSize / 1024 / 1024))
            succeed = false;
        }
        if (succeed)
            this.setState({file: file});

        // this.setState({file}); /// if you want to upload latter
    }

    sendMessage = () => {
        const formData = new FormData();
        const {chatBoxSelectedTicket, chatBoxMessages} = this.props;
        if(!chatBoxMessages.length) {
            // if (this.formValidated()) {
            formData.append('title', chatBoxSelectedTicket.title);
            formData.append('message', this.state.content);
            formData.append('management_unit', chatBoxSelectedTicket.management_unit);
            formData.append('show_type', TICKET_SHOW_TYPES.CHATBOX.toString());

            if(this.state.file){
                formData.append('file', this.state.file);
            }

            this.props.submitTicket(formData);

            // } else
            //     showError(SUPPORT, 'form.invalid');
        } else {
            formData.append('ticket_id', chatBoxSelectedTicket.id);
            formData.append('management_unit', chatBoxSelectedTicket.management_unit);
            formData.append('content', this.state.content);
            if (this.state.file) {
                formData.append('file', this.state.file);
            }
            this.props.submitTicketMessage(formData);
        }


    }

    updateMessageValue(evt) {
        const {chatBoxSelectedTicket} = this.props;
        ticketTyping(chatBoxSelectedTicket.id , evt.target.value)
        this.setState({
            content: fa2enNumbers(evt.target.value)
        });
    }


    render() {
        const{content} = this.state
        const{chatBoxSelectedTicket, chatBoxMessages, currentUser, token, submittingMessage, submittingTicket, progress} = this.props;
        const hashedToken = md5(token ?? '');
        return (
            <div className="live-chat-section-wrapper">
                <CustomScrollbars className="chat-list-scroll scrollbar">
                    <Conversation selectedTicket={chatBoxSelectedTicket} messages={chatBoxMessages} hashedToken={hashedToken} currentUser={currentUser}/>
                </CustomScrollbars>

                    <div className={"chat-main-footer"}
                         style={{position: "fixes", bottom: "0%"}}>
                        {submittingMessage ? <LinearProgressWithLabel progress={progress}/> : ""}
                        <div className="ticket-compose-wrapper">
                            <div className="buttons-wrapper">

                                <IconButton disabled={submittingMessage || submittingTicket} className="submit-btn"
                                            onClick={this.sendMessage.bind(this)}
                                            aria-label="Send message">
                                    <i className="zmdi  zmdi-mail-send"/>
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        this.upload.click()
                                    }}
                                    aria-label="Send attachment" className={"file-btn"}>
                                    <i className="zmdi zmdi-attachment"/>
                                </IconButton>
                                <input type="file" accept='.jpeg, .png, .jpg, .pdf, .rar, .zip'
                                       ref={(ref) => this.upload = ref} style={{display: 'none'}}
                                       onChange={this.onChangeFile.bind(this)}>
                                </input>

                            </div>
                            <div className="textarea-wrapper">
                                <TextareaAutosize
                                    id="required"

                                    className="chat-text-area"
                                    // style={(width < MOBILE_MAX_WIDTH ? {height: '145px'} : {})}
                                    // onKeyUp={this._handleKeyPress.bind(this)}
                                    onChange={this.updateMessageValue.bind(this)}
                                    value={content}
                                    placeholder="توجه داشته باشید شما تنها 3 پیام میتوانید قبل از پاسخ توسط همکاران ما ارسال نمایید درخواست خود را به صورت کامل ارسال کنید...."
                                    variant="contained"
                                />
                            </div>

                        </div>
                        <div className="chat-bottom-wrapper">
                            <div className={"chat-limit"}>{MAX_MESSAGE_LENGTH - content.length} / {MAX_MESSAGE_LENGTH}</div>
                            {this.state.file ?
                                <div className="file-badge" >
                                    {this.state.file.name}
                                    <IconButton
                                        onClick={() => {
                                            this.setState({file: null})
                                        }}
                                        aria-label="Send attachment" className="close-badge">
                                        <i className="zmdi zmdi-close-circle"/>
                                    </IconButton></div> : ""

                            }
                        </div>

                        {/*<div className={"d-flex flex-row align-items-center my-1 " + (width < 480 ? 'py-1' : '')}>*/}

                        {/*    <div className="chat-sent pl-2">*/}
                        {/*        <IconButton disabled={submittingMessage} className="text-white p-2"*/}
                        {/*                    onClick={this.submitComment.bind(this)}*/}
                        {/*                    aria-label="Send message">*/}
                        {/*            <i className="zmdi  zmdi-mail-send"/>*/}
                        {/*        </IconButton>*/}
                        {/*        <IconButton*/}
                        {/*            onClick={() => {*/}
                        {/*                this.upload.click()*/}
                        {/*            }}*/}
                        {/*            aria-label="Send attachment" className={"text-white p-2" + (width < 480 ? 'mt-2' : '')}>*/}
                        {/*            <i className="zmdi zmdi-attachment"/>*/}
                        {/*        </IconButton>*/}

                        {/*        <p className={'ltr'}>{MAX_MESSAGE_LENGTH - content.length} / {MAX_MESSAGE_LENGTH}</p>*/}
                        {/*        <input type="file" accept='.jpeg, .png, .jpg, .pdf, .rar, .zip'*/}
                        {/*               ref={(ref) => this.upload = ref} style={{display: 'none'}}*/}
                        {/*               onChange={this.onChangeFile.bind(this)}>*/}
                        {/*        </input>*/}

                        {/*    </div>*/}
                        {/*    <div className={"col px-2 "}>*/}
                        {/*        /!*{ticketSubmitted ? "" :*!/*/}
                        {/*        {this.state.file ?*/}
                        {/*            <div className="badge" style={{*/}
                        {/*                backgroundColor: "rgb(10, 10, 10 ,0.33)",*/}
                        {/*                borderRadius: "30px"*/}
                        {/*            }}>*/}
                        {/*                {this.state.file.name}*/}
                        {/*                <IconButton*/}
                        {/*                    onClick={() => {*/}
                        {/*                        this.setState({file: null})*/}
                        {/*                    }}*/}
                        {/*                    aria-label="Send attachment" className="text-secondary p-1">*/}
                        {/*                    <i className="zmdi zmdi-close-circle"/>*/}
                        {/*                </IconButton></div> : ""*/}

                        {/*        }*/}

                        {/*        <div className="form-group">*/}

                        {/*        </div>*/}
                        {/*    </div>*/}

                        {/*</div>*/}
                    </div>
                    {/*<div className={"chat-main-footer bg-primary " + (width < 480 ? 'p-0' : '')}*/}
                    {/*     style={{bottom: "0", backgroundImage: "linear-gradient(90deg, #320097, #8e2de2)", padding: "0"}}>*/}
                    {/*    {submittingMessage ? <LinearProgressWithLabel progress={progress}/> : ""}*/}
                    {/*    <div className={"d-flex flex-row align-items-center my-1 " + (width < 480 ? 'py-1' : '')}>*/}

                    {/*        <div className="chat-sent pl-2" style={{display: "flex", flexDirection: "column"}}>*/}
                    {/*            <IconButton disabled={submittingMessage} className="text-white p-2"*/}
                    {/*                        onClick={this.sendMessage.bind(this)}*/}
                    {/*                        aria-label="Send message">*/}
                    {/*                <i className="zmdi  zmdi-mail-send"/>*/}
                    {/*            </IconButton>*/}
                    {/*            <IconButton*/}
                    {/*                onClick={() => {*/}
                    {/*                    this.upload.click()*/}
                    {/*                }}*/}
                    {/*                aria-label="Send attachment" className={"text-white p-2" + (width < 480 ? 'mt-2' : '')}>*/}
                    {/*                <i className="zmdi zmdi-attachment"/>*/}
                    {/*            </IconButton>*/}
                    {/*            <input type="file" accept='.jpeg, .png, .jpg, .pdf, .rar, .zip'*/}
                    {/*                   ref={(ref) => this.upload = ref} style={{display: 'none'}}*/}
                    {/*                   onChange={this.onChangeFile.bind(this)}>*/}
                    {/*            </input>*/}

                    {/*        </div>*/}
                    {/*        <div className={"col p-0 "}>*/}
                    {/*            /!*{ticketSubmitted ? "" :*!/*/}
                    {/*            {this.state.file ?*/}
                    {/*                <div className="badge my-1" style={{*/}
                    {/*                    backgroundColor: "rgb(10, 10, 10 ,0.33)",*/}
                    {/*                    borderRadius: "30px"*/}
                    {/*                }}>*/}
                    {/*                    {this.state.file.name}*/}
                    {/*                    <IconButton*/}
                    {/*                        onClick={() => {*/}
                    {/*                            this.setState({file: null})*/}
                    {/*                        }}*/}
                    {/*                        aria-label="Send attachment" className="text-secondary p-1">*/}
                    {/*                        <i className="zmdi zmdi-close-circle"/>*/}
                    {/*                    </IconButton></div> : ""*/}

                    {/*            }*/}

                    {/*            <div className="col px-1 ">*/}
                    {/*                <div className="form-group">*/}
                    {/*                    <TextareaAutosize*/}
                    {/*                        id="required"*/}
                    {/*                        className="border-0 form-control chat-textarea rtl jr-fs-lg "*/}
                    {/*                        // style={{height: '80px'} }*/}
                    {/*                        // onKeyUp={this._handleKeyPress.bind(this)}*/}
                    {/*                        onChange={this.updateMessageValue.bind(this)}*/}
                    {/*                        value={content}*/}
                    {/*                        placeholder="برای نوشتن کلیک کنید"*/}
                    {/*                        variant="contained"*/}
                    {/*                    />*/}
                    {/*                </div>*/}

                    {/*            </div>*/}

                    {/*        </div>*/}

                    {/*    </div>*/}
                    {/*</div>*/}

            </div>
        )
    }
}
const mapStateToProps = ({support, auth, settings}) => {
    const {width} = settings;
    const {currentUser, token} = auth;
    const {chatBoxMessages, chatBoxSelectedTicket, stepIndex, submittingMessage, progress, ticketSubmitted, submittingTicket} = support;
    return {chatBoxMessages, chatBoxSelectedTicket, currentUser, token, width, stepIndex, submittingMessage, progress, ticketSubmitted, submittingTicket}
}
export default connect(mapStateToProps, {switchChatBox, switchLiveChatContentStepper, getConversationInformation,
    submitTicket, submitTicketMessage}) (withStyles(styles)(ChatWrapper));

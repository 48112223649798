import SweetAlert from "sweetalert-react";
import React from "react";
import {browserName} from 'react-device-detect';


class BrowserAlert extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            alarm: {
                show: false,
                title: 'IR Exchanger',
                message: 'ممکن است برخی از امکانات پلتفرم در مرورگر شما به درستی عمل نکنند. پیشنهاد میکنیم از مرورگر گوگل کروم (Chrome) استفاده نمائید.',

            }
        }
    }

    closeAlarm() {
        const {alarm} = this.state;
        alarm.show = false;
        this.setState({alarm: alarm});
    }

    componentWillMount() {
        // const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        if (browserName.toUpperCase() !== 'CHROME') {
            const {alarm} = this.state;
            let lastChecked = parseInt(localStorage.getItem('browserAlertCheckTime') ?? 0);
            let now = Date.now() / 1000;
            if(lastChecked + 604800 < now) {
                localStorage.setItem('browserAlertCheckTime', now.toString());
                alarm.show = true;
                this.setState({alarm: alarm});
            }
        }
    }

    render() {
        const {alarm} = this.state;
        return (
            <SweetAlert
                show= {alarm.show}
                title={alarm.title}
                text={alarm.message}
                confirmButtonText={'متوجه شدم'}
                onConfirm={() => this.closeAlarm()}
            />
        )
    }
}

export default BrowserAlert;
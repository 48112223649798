import {
    C2C_DEPOSIT_FAILED,
    C2C_DEPOSIT_REQUESTED,
    CANCEL_REQUEST_FAILED,
    CANCEL_REQUEST_FETCHED,
    DEPOSIT_IDENTITY_GENERATED,
    DEPOSITS_LIST_FETCHED,
    FETCHING_DEPOSITS_LIST,
    FETCHING_GATEWAYS,
    FETCHING_GATEWAYS_FAILED,
    FETCHING_TRANS_INFO,
    FETCHING_TRANSACTIONS_LIST,
    FETCHING_WITHDRAWS_LIST,
    GATEWAYS_FETCHED,
    GENERATE_IDENTITY_FAILED,
    INSTANT_BANKS_FETCHED,
    OFFLINE_DEPOSIT_FAILED,
    OFFLINE_DEPOSIT_REQUESTED,
    ONLINE_DEPOSIT_FAILED,
    ONLINE_DEPOSIT_REQUESTED,
    PM_DEPOSIT_REQUESTED,
    REDRAW_TRANSACTIONS_LIST,
    REQUESTING_C2C_DEPOSIT,
    REQUESTING_GENERATE_IDENTITY,
    REQUESTING_OFFLINE_DEPOSIT,
    REQUESTING_ONLINE_DEPOSIT,
    REQUESTING_ORDER_CANCEL,
    REQUESTING_WITHDRAW,
    SWITCH_CANCEL_DIALOG,
    SWITCH_DEPOSIT_CRYPTO_DIALOG,
    SWITCH_DEPOSIT_DIALOG,
    SWITCH_TRANS_INFO_DIALOG,
    SWITCH_WITHDRAW_CRYPTO_DIALOG,
    SWITCH_WITHDRAW_DIALOG,
    TRANS_INFO_FAILED,
    TRANS_INFO_FETCHED,
    TRANSACTIONS_LIST_FETCHED,
    USE_GATEWAY,
    WITHDRAW_FAILED,
    WITHDRAW_REQUESTED,
    WITHDRAWS_LIST_FETCHED
} from "../constants/ActionTypes";

import {global} from "../store/global";

const INIT_STATE = {
    transactions: [],
    deposits: [],
    withdraws: [],
    totalTransactions: 0,
    totalDeposits: 0,
    totalWithdraws: 0,
    loadingTransactions: false,
    loadingDeposits: false,
    loadingWithdraws: false,
    requestingOnline: false,
    requestingC2C: false,
    requestingOffline: false,
    requestingWithdraw: false,
    transInfoDialogOpened: false,
    selectedTransaction: {},
    fetchingTrans: true,
    selectedTrans: {},
    redraw: false,
    gateway: {},
    gatewayUsed: false,
    fetchingGateways: false,
    gateways: [],
    stats: {deposits: {}, withdraws: {}},
    depositsStats: {},
    withdrawsStats: {},
    transactionsStats: {},
    cancelOrder: false,
    cancelableID: "",
    cancelDialogOpened: false,
    requestingOrderCancel: false,
    depositDialogOpened: false,
    withdrawDialogOpened: false,
    instantBanks: {},
    depositIdentities: [],
    depositCryptoDialogOpened: false,
    selectedDepositCurrency: '',
    selectedDepositBase: '',
    initialDepositAmount: 0,
    withdrawCryptoDialogOpened: false,
    selectedWithdrawCurrency: '',
    selectedWithdrawBase: '',
    initialWithdrawAmount: 0

};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FETCHING_TRANSACTIONS_LIST: {
            return {...state, loadingTransactions: true, redraw: false};
        }
        case FETCHING_DEPOSITS_LIST: {
            return {...state, loadingDeposits: true, redraw: false};
        }
        case FETCHING_WITHDRAWS_LIST: {
            return {...state, loadingWithdraws: true, redraw: false};
        }
        case REQUESTING_ONLINE_DEPOSIT: {
            return {...state, requestingOnline: true};
        }
        case REQUESTING_C2C_DEPOSIT: {
            return {...state, requestingC2C: true};
        }
        case REQUESTING_OFFLINE_DEPOSIT: {
            return {...state, requestingOffline: true};
        }
        case REQUESTING_WITHDRAW: {
            return {...state, requestingWithdraw: true};
        }
        case ONLINE_DEPOSIT_REQUESTED: {
            return {...state, requestingOnline: false, redraw: true, gateway: action.payload, gatewayUsed: false};
        }
        case C2C_DEPOSIT_REQUESTED: {
            return {...state, requestingC2C: false, redraw: true, gateway: action.payload, gatewayUsed: false};
        }
        case OFFLINE_DEPOSIT_REQUESTED: {
            return {...state, requestingOffline: false, redraw: true, gateway: action.payload, gatewayUsed: false};
        }
        case PM_DEPOSIT_REQUESTED: {
            global.selectedTransID = action.payload.transaction.factor.id;
            global.selectedOrder = action.payload.transaction.factor;
            return {...state, fetchingTrans: false, selectedTrans: action.payload.transaction, gateway: action.payload.gateway, gatewayUsed: false,};
        }
        case REQUESTING_GENERATE_IDENTITY: {
            return {...state, generatingID: true};
        }
        case DEPOSIT_IDENTITY_GENERATED: {
            return {...state, generatingID: false, depositIdentities: action.payload.records};
        }
        case GENERATE_IDENTITY_FAILED: {
            return {...state, generatingID: false};
        }
        case WITHDRAW_REQUESTED: {
            global.selectedTransID = action.payload.transaction ? action.payload.transaction.factor.id : 0;
            global.selectedOrder = action.payload.transaction ? action.payload.transaction.factor : null;
            return {...state, requestingWithdraw: false, redraw: true, gateway: action.payload.gateway, gatewayUsed: false,
                selectedTrans: action.payload.transaction ?? {}, withdrawDialogOpened: action.payload.stayDialogOpen};
        }
        case ONLINE_DEPOSIT_FAILED: {
            return {...state, requestingOnline: false, gateway: action.payload, gatewayUsed: false};
        }
        case C2C_DEPOSIT_FAILED: {
            return {...state, requestingC2C: false, gatewayUsed: false};
        }
        case OFFLINE_DEPOSIT_FAILED: {
            return {...state, requestingOffline: false, gatewayUsed: false};
        }
        case WITHDRAW_FAILED: {
            return {...state, requestingWithdraw: false, gatewayUsed: false};
        }
        case USE_GATEWAY: {
            return {...state, gatewayUsed: true};
        }
        case DEPOSITS_LIST_FETCHED: {
            return {...state, loadingDeposits: false, redraw: false, deposits: action.payload.deposits ?? [], totalDeposits: action.payload.totalDeposits, depositsStats: action.payload.depositsStats ?? {}};
        }
        case WITHDRAWS_LIST_FETCHED: {
            return {...state, loadingWithdraws: false, redraw: false, withdraws: action.payload.withdraws ?? [], totalWithdraws: action.payload.totalWithdraws, withdrawsStats: action.payload.withdrawsStats ?? {}};
        }
        case TRANSACTIONS_LIST_FETCHED: {
            return {...state, loadingTransactions: false, redraw: false, transactions: action.payload.transactions ?? [], totalTransactions: action.payload.totalTransactions, transactionsStats: action.payload.transactionsStats ?? {}};
        }
        case REDRAW_TRANSACTIONS_LIST: {
            return {...state, redraw: true};
        }
        case CANCEL_REQUEST_FETCHED: {
            return {...state, cancelOrder: true, redraw: true}
        }
        case CANCEL_REQUEST_FAILED: {
            return {...state, cancelOrder: false, redraw: true}
        }
        case REQUESTING_ORDER_CANCEL: {
            return {...state, requestingOrderCancel: true};
        }
        case SWITCH_TRANS_INFO_DIALOG: {
            return {...state, transInfoDialogOpened: action.payload.state};
        }
        case SWITCH_CANCEL_DIALOG: {
            return {...state, cancelDialogOpened: action.payload.state, selectedOrder: {}, cancelableID: action.payload.cancelableID};
        }
        case SWITCH_DEPOSIT_DIALOG: {
            return {...state, depositDialogOpened: action.payload.state};
        }
        case SWITCH_WITHDRAW_DIALOG: {
            global.withdrawDialogOpened = action.payload.state;
            return {...state, withdrawDialogOpened: action.payload.state};
        }
        case SWITCH_DEPOSIT_CRYPTO_DIALOG: {
            global.selectedTransID = null;
            global.selectedOrder = null;
            return {...state, depositCryptoDialogOpened: action.payload.state, selectedTrans: {},
                selectedDepositCurrency: action.payload.selectedDepositCurrency, selectedDepositBase: action.payload.selectedDepositBase,
                initialDepositAmount: action.payload.initialDepositAmount ?? '', gateway: {}, };
        }
        case SWITCH_WITHDRAW_CRYPTO_DIALOG: {
            global.selectedTransID = null;
            global.selectedOrder = null;
            return {...state, withdrawCryptoDialogOpened: action.payload.state, selectedTrans: {},
                selectedWithdrawCurrency: action.payload.selectedWithdrawCurrency, selectedWithdrawBase: action.payload.selectedWithdrawBase,
                initialWithdrawAmount: action.payload.initialWithdrawAmount ?? ''};
        }
        case FETCHING_TRANS_INFO: {
            return {...state, fetchingTrans: true};
        }
        case TRANS_INFO_FETCHED: {
            global.selectedTransID = action.payload.transaction.factor.id;
            global.selectedOrder = action.payload.transaction.factor;
            return {...state, fetchingTrans: false, selectedTrans: action.payload.transaction, gateway: action.payload.gateway, gatewayUsed: false,};
        }
        case TRANS_INFO_FAILED: {
            return {...state, fetchingTrans: false};
        }
        case FETCHING_GATEWAYS: {
            return {...state, fetchingGateways: true};
        }
        case GATEWAYS_FETCHED: {
            return {...state, fetchingGateways: false, gateways: action.payload.gateways, depositIdentities: action.payload.depositIdentities};
        }
        case FETCHING_GATEWAYS_FAILED: {
            return {...state, fetchingGateways: false, gateways: []};
        }
        case INSTANT_BANKS_FETCHED: {
            return {...state, instantBanks: action.payload.instant_banks ?? {}};
        }
        default:
            return state;
    }
}


import appLocaleData from 'react-intl/locale-data/en';
import faMessages from '../locales/fa_IR.json';

const FaLang = {
    messages: {
        ...faMessages
    },
    locale: 'fa-US',
    data: appLocaleData
};
export default FaLang;

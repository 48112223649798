import React from "react"
import {TextField, withStyles} from "@material-ui/core";
import IntlMessages from "../../../../util/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";
import {connect} from "react-redux";
import {startChatBoxTicket, unselectTicket} from "../../../../actions/SupportResource";
import {lengthBetween} from "../../../../util/Validator";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {showError} from "../../Notifier";
import {SUPPORT} from "../../../../constants/Units";


const styles = (theme) => ({

    card: {
        height: "100%",
        // '& .MuiCardContent-root': {
        //     padding: 0
        // }

    },
    cardContentRoot: {

        display: "flex",
        flexDirection: 'column',
        gap: '10px',
        justifyContent: 'center',
        paddingBottom: "0px"
    },
    cardHeader: {
        padding: "8px"
    },
    cardActionsRoot: {
        width: "100%",
        position: "absolute",
        bottom: "0"
    },
    headerContentWrapper: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: 'column',
        height: "calc(100% - 56px)",
        overflowY: "auto"
    }
});


const management_units = [
    {
        value: "3",
        label: "فنی"
    },
    {
        value: "4",
        label: "مالی"
    },
    {
        value: "5",
        label: "انتقادات و پیشنهادات"
    }
];

class SelectManagementUnit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputs: {
                management_unit: 3,
                subject: "",

            },
            formErrors: {
                management_unit: "",
                subject: "",

            },
        }
    };

    formValidated = () => {
        const {inputs} = this.state;
        const context = this;
        let isValid = true;
        Object.keys(inputs).map(function (key, index) {
            isValid &= context.validateFormValue(key, inputs[key]);
        });
        const {formErrors} = this.state;
        Object.keys(formErrors).map(function (key, index) {
            isValid &= !formErrors[key];
        });
        return isValid;
    };

    validateFormValue = (name, value) => {
        let {formErrors, inputs} = this.state;
        switch (name) {
            case 'subject':
                if (!lengthBetween({input: value, min: 2, max: 128}))
                    formErrors.subject = <IntlMessages id="error.content.subject.length"/>;
                else
                    formErrors.subject = "";
                break;

        }
        inputs[name] = value;
        this.setState({formErrors, inputs});
        return !formErrors[name];
    };

    handleChange = e => {
        const {name, value} = e.target;
        // this.setState({[e.target.name]: e.target.value});
        this.validateFormValue(name, value);

    };
    handleSubmit = (e) => {
        const {management_unit, subject} = this.state.inputs;
        if (this.formValidated()) {
            this.props.startChatBoxTicket(subject, management_unit)
        } else
            showError(SUPPORT, 'form.invalid');

    }

    render() {
        const {inputs, formErrors} = this.state;
        return (
            <div className="select-management-unit-wrapper">
                <div className="support-chats-wrapper">
                    <div className="d-flex flex-nowrap chat-item flex-row-reverse">
                        <img alt="alt" className="chat-profile"
                             src={require("../../../../assets/images/header/userHeader.svg")}
                        />
                        <div className="chat-bubble admin-chat">
                            <div className="chat-message" style={{overflowWrap: 'break-word', whiteSpace: "pre-wrap"}}>
                                کاربر گرامی سلام؛
                                <br/>
                                از اینکه ما را انتخاب نموده اید متشکریم لطفا جهت ادامه فرآیند پشتیبانی واحد و موضوع
                                گفت و گوی خود را انتخاب نمایید.</div>
                        </div>
                    </div>
                    <div className="d-flex flex-nowrap chat-item flex-row-reverse">
                        <img alt="alt" className="chat-profile"
                             src={require("../../../../assets/images/header/userHeader.svg")}
                        />
                        <div className="chat-bubble admin-chat">
                            <div className="chat-message" style={{overflowWrap: 'break-word', whiteSpace: "pre-wrap"}}>
                                کاربر محترم قبل از شروع گفتگو بخش <Link to={"./support"} onClick={() => this.props.unselectTicket()}>
                                سوالات متداول
                            </Link> را بررسی نمایید.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-wrapper">
                    <div className="input-wrapper">

                        <TextField
                            label={<IntlMessages id="form.description.select_ticket_department"/>}
                            select
                            onChange={this.handleChange}
                            name="management_unit"
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={inputs.management_unit}
                        >

                            {management_units.map((option) => {
                                return (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                )
                            })}

                        </TextField>
                    </div>
                    <div className="input-wrapper">
                        <TextField
                            placeholder={"موضوع گفتگو را بنویسید."}
                            // label={<IntlMessages id="title.subject" />}
                            onChange={this.handleChange}
                            fullWidth
                            variant="outlined"
                            name="subject"
                            size="small"
                            error={!!formErrors.subject}
                            className={formErrors.subject ? "error" : null}
                            value={inputs.subject}
                            minRows={"1"}
                            multiline
                        />
                        {formErrors.subject && (
                            <div className="error-box">
                                <img alt="alt" src={require("../../../../assets/images/V2/error.svg")}/>
                                {formErrors.subject}
                            </div>
                        )}
                    </div>
                    <div className="button-wrapper">
                        <Button
                                variant="contained"

                            // disabled={submittingTicket}
                                onClick={this.handleSubmit}
                                fullWidth
                                className="confirm-btn"
                        >
                            <IntlMessages id="button.start_conversation"/>
                            {/*{submittingTicket ? <><IntlMessages id="button.sending"/> <CircularProgress size={24} /></> : <IntlMessages id="button.submit_ticket"/>}*/}
                        </Button>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = ({support}) => {
    const {liveChatWrapperOpened, stepIndex} = support;
    return {liveChatWrapperOpened, stepIndex}
}
export default connect(mapStateToProps, {startChatBoxTicket, unselectTicket})(withStyles(styles)(SelectManagementUnit));

import React, {createRef} from 'react';
import {Link} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import '../assets/css/formStyle.css';
import Login from "../actions/Login";
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import {withStyles} from "@material-ui/core/styles";
import {isMobile, isNumeric, lengthBetween} from "../util/Validator";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import RefreshIcon from '@material-ui/icons/Refresh';
import ParticlesBg from 'particles-bg'
import {compose} from "redux";
import {connect} from "react-redux";
import ReactCodeInput from "react-code-input";
import {
    authModeSelector,
    resendSignIn,
    setSigninStep,
    updateCaptchaVersion,
    userSignIn,
    verifyUserSignIn
} from "../actions";
import {showError} from "../panel/components/Notifier";
import {LOGIN} from "../constants/Units";
import {fa2enNumbers} from "../util/Utilities";
import BrowserAlert from "./components/BrowserAlert";
import {BRANCH_ESSENTIALS} from "../panel/routes/data/constants";



const login = new Login();
const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        color: grey[50],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});


const emailRegex = RegExp(
    /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
);
const phoneRegex = RegExp(/^[+][0-9 ]{9,20}$/);

// let history = useHistory();

class SignIn extends React.Component {

    constructor(props) {
        super(props);
        this.timer = 0;
        this.updateRecaptcha();
    }

    state = {
        userInfo: {
            email: '',
            phone_number: '',
            password: '',

        },
        formErrors: {
            captcha: "",
            email: "",
            password: ""
        },
        refs: {
            email: createRef(),
            code: createRef(),
            captcha: createRef()
        },
        captcha: '',
        verifyCode: '',
        error: {},
        loading: false,
        success: false,
        activeStep: 0,
        formValid: {},
        formToken: '',
        userInputName: 'emailOrPhone',
        showPassword: false,
        time: {},
        seconds: "",
        resendLeftSecs: 120,

    };

    handleClickShowPassword = () => {
        this.setState({showPassword: !this.state.showPassword});
    };

    handleMouseDownPassword = event => {
        event.preventDefault();
    };

    handleNext = (e) => {
        e.preventDefault();
        const {signinStep} = this.props;

        if (signinStep === 1) {
            const {userInfo, userInputName, captcha, formErrors} = this.state;
            if (!!formErrors.password || !!formErrors[userInputName] || captcha.length !== 6) {
                showError(LOGIN, 'form.invalid');
                return;
            }

            let fields = {password: userInfo.password, captcha: captcha,
                terminal_id : BRANCH_ESSENTIALS.TERMINAL_ID,
                terminal_token : BRANCH_ESSENTIALS.TERMINAL_TOKEN
            };
            fields[userInputName] = userInfo[userInputName];
            this.props.userSignIn(fields);
        } else if (signinStep === 2) {
            if (this.state.verifyCode.length < 5) {
                showError(LOGIN, 'form.invalid');
                return;
            }
            this.props.verifyUserSignIn({token: this.props.signinVerifyDetails.token, code: this.state.verifyCode});

        }
    };

    componentWillUnmount() {
        this.stopTimer();
    }

    handleBack = () => {
        const {signinStep} = this.props;
        this.props.setSigninStep(signinStep - 1);
        this.setState({
            email: "",
            phone_number: "",
            password: "",
        });
    };

    handleSubmit = () => {
        this.setState({submitted: true}, () => {
            setTimeout(() => this.setState({submitted: false}), 2000);
        });
    };

    changeVerifyCode = value => {
        let formErrors = {...this.state.formErrors};
        if (!isNumeric({input: value}))
            formErrors.verifyCode = <IntlMessages id="error.content.verify_code.numeric"/>;
        else if (!lengthBetween({input: value, min: 6, max: 6}))
            formErrors.verifyCode = <IntlMessages id="error.content.verify_code.length"/>;
        else
            formErrors.verifyCode = "";
        this.setState({formErrors, 'verifyCode': value},);
    }

    handleChange = e => {
        // localStorage.clear();
        e.preventDefault();
        let {name, value} = e.target;
        let formErrors = {...this.state.formErrors};
        value = fa2enNumbers(value);
        switch (name) {
            case this.state.userInputName:
                formErrors.email = '';
                if (emailRegex.test(value)) {
                    this.state.userInputName = 'email';
                    this.state.userInfo.email = value;
                }
                // else if (isMobile({input: value})) {
                //     this.state.userInputName = 'phone_number';
                //     this.state.userInfo.phone_number = value;
                // }
                else {
                    formErrors.email = <IntlMessages id="error.content.email_mobile.invalid"/>;
                    this.state.userInputName = 'emailOrPhone';
                    this.state.userInfo.phone_number = '';
                    this.state.userInfo.email = '';
                }
                break;
            case "password":
                formErrors.password =
                    value.length < 8 ? <IntlMessages id="error.content.password.length"/> : "";
                this.state.userInfo.password = value;
                break;
            case "captcha":
                if(value.toString().length !== 6)
                    formErrors.captcha = <IntlMessages id="error.content.captcha.length"/>;
                else
                    formErrors.captcha = '';
                break;
            case "verifyCode":
                formErrors.verifyCode =
                    value.toString().length < 5 ? <IntlMessages id="error.content.auth_code.invalid"/> : "";
                break;
            default:
                break;
        }
        this.setState({formErrors, [name]: value},);
    };

    handleResendCode = (e) => {
        e.preventDefault();
        this.props.resendSignIn({token: this.props.signinVerifyDetails.token,
            // terminal_token: BRANCH_ESSENTIALS.TERMINAL_TOKEN,
            // terminal_id: BRANCH_ESSENTIALS.TERMINAL_ID
        });
    };

    startTimer() {
        const {signinVerifyDetails} = this.props;
        const now = Date.now() / 1000;
        if (signinVerifyDetails && signinVerifyDetails.next_request > now && !this.timerStarted) {
            this.timer = setInterval(() => {
                const resendLeftSecs = this.props.signinVerifyDetails.next_request - parseInt(Date.now() / 1000);
                this.setState({resendLeftSecs: resendLeftSecs});
                if (resendLeftSecs === 0)
                    this.stopTimer();
            }, 1000);
            this.timerStarted = true;
        }

    }

    stopTimer() {
        if (this.timerStarted && this.timer) {
            clearInterval(this.timer);
            this.timerStarted = false;
        }
    }

    getVerifyCode() {
        const {signinVerifyDetails} = this.props;
        return <>
            <div className="enter-code-text">
                {signinVerifyDetails.field == "mobile" ?
                    <>لطفا کد 6 رقمی ارسال شده به شماره <div className="mobile-num">{signinVerifyDetails.mobile}</div> را وارد نمایید</>
                    : signinVerifyDetails.field == "email" ?
                        <>لطفا کد 6 رقمی ارسال شده به ایمیل <div className="mobile-num">{signinVerifyDetails.email}</div> را وارد نمایید</>
                        : ""
                }
                {/*{<IntlMessages id="form.description.enter_verification_code"/>}*/}
            </div>
            {/*<div className="d-inline-flex">*/}
            {/*    <div className="jr-fs-xl text-danger">*/}
            {/*        اعتبار تا :*/}
            {/*    </div>*/}
            {/*    <span className="text-primary jr-fs-xl">*/}
            {/*    {" " + signinVerifyDetails.code_validity / 60 + " " + "دقیقه" + " "}*/}
            {/*</span>*/}
            {/*</div>*/}
            <div className="confirm-code-input">
                <ReactCodeInput
                    value={this.state.verifyCode}
                    inputRef={this.state.refs.code}
                    id="verifyCode"
                    name="verifyCode"
                    // label={<IntlMessages id="title.verify_code"/>}
                    // fullWidth
                    // value={inputs.verifyCode}
                    margin="normal"
                    onChange={this.changeVerifyCode}
                    // onChange={this.changeVerifyCode}
                    error={this.state.error.verifyCode}
                    // error={!!formErrors.verifyCode}
                    // className={formErrors.verifyCode ? "error" : null}
                    type='number' fields={6}
                />
            </div>
            {/*<TextField*/}
            {/*    type="number"*/}
            {/*    id="verifyCode"*/}
            {/*    name="verifyCode"*/}
            {/*    autoComplete='off'*/}
            {/*    label={<IntlMessages id="appModule.verifycode"/>}*/}
            {/*    fullWidth*/}
            {/*    inputRef={this.state.refs.code}*/}
            {/*    autoFocus={true}*/}
            {/*    // defaultValue={this.state.password}*/}
            {/*    // onChange={(event) => this.setState({password: event.target.value})}*/}
            {/*    margin="normal"*/}
            {/*    className="mt-1"*/}
            {/*    onChange={this.handleChange}*/}
            {/*    error={this.state.error.verifyCode}*/}
            {/*    variant="outlined"*/}
            {/*    size="small"*/}
            {/*/>*/}

            {this.state.resendLeftSecs === 0 ?
                <div className="resend-text-link" onClick={this.handleResendCode}>
                    ارسال مجدد کد
                </div>

                :
                <div className="seconds-text">
                    {this.state.resendLeftSecs === 0 ? "ارسال مجدد کد" : "ارسال مجدد تا" + " " + this.state.resendLeftSecs + " " + "ثانیه"}
                </div>
            }
            {signinVerifyDetails.field == "email" ?
                <div className="check-email">
                    لطفا پوشه اسپم ایمیل خود را نیز بررسی نمائید.
                </div>
                : ""
            }


            <TextField
                className="d-none"
                type="hidden"
                id="verifyCode"
                name="verifyCode"
                value={this.state.formToken}

            />

        </>
    }

    updateRecaptcha = () => {
        this.props.updateCaptchaVersion();
        if(this.state.refs.captcha.current ) {
            this.state.refs.captcha.current.focus();
        }
    }

    getUserInfo() {
        const {darkTheme} = this.props;
        return (
            <>
                <BrowserAlert/>
                <div className="email-phone-input">
                    <TextField
                        // InputLabelProps={{
                        //     shrink: true,
                        // }}
                        id="emailOrPhoneNo"
                        // label={<IntlMessages id="appModule.email-or-mobile"/>}
                        // value={this.state.userInfo.email}
                        fullWidth
                        inputProps={{ tabIndex: "1" }}
                        autoFocus={true}
                        inputRef={this.state.refs.email}
                        margin="normal"
                        className={this.state.formErrors.email ? "error" : null}
                        placeholder="ایمیل"
                        type="text"
                        name={this.state.userInputName}
                        noValidate
                        onChange={this.handleChange}
                        error={!!this.state.formErrors.email}
                        variant="outlined"
                        size="small"
                    />
                    {this.state.formErrors.email && (

                        <div className="small text-danger" style={{margin: '0 8px -8px 0'}}>{this.state.formErrors.email}</div>

                    )}
                </div>
                <div className="password-input">
                    <TextField
                        // InputLabelProps={{
                        //     shrink: true,
                        // }}
                        id="password"
                        // value={this.state.userInfo.password}
                        // label={<IntlMessages id="appModule.password"/>}
                        fullWidth
                        inputProps={{ tabIndex: "2" }}
                        margin="normal"
                        className={this.state.formErrors.password ? "error" : null}
                        placeholder="رمز عبور"
                        type={this.state.showPassword ? 'text' : 'password'}
                        name="password"
                        noValidate
                        onChange={this.handleChange}
                        error={!!this.state.formErrors.password}
                        // value={this.state.password}
                        variant="outlined"
                        size="small"
                        InputProps={{

                            endAdornment:
                                <IconButton
                                    tabIndex={-1}
                                    onClick={this.handleClickShowPassword}
                                    onMouseDown={this.handleMouseDownPassword}
                                >
                                    {this.state.showPassword ? <img src={require("../assets/images/mars/hide.svg")}/> :
                                        <img src={require("../assets/images/mars/show.svg")}/>}

                                </IconButton>


                        }}

                    />
                    {this.state.formErrors.password && (

                        <div className="small text-danger" style={{margin: '0 8px -8px 0'}}>{this.state.formErrors.password}</div>


                    )}
                </div>
                <div className="captcha-row">
                    <div className="captcha-input">
                        <TextField
                            id="captcha"

                            // label={<IntlMessages id="appModule.captcha_input"/>}
                            // value={this.state.userInfo.email}
                            fullWidth
                            inputProps={{ tabIndex: "3" }}
                            inputRef={this.state.refs.captcha}
                            autoComplete='off'
                            margin="normal"
                            className={this.state.formErrors.captcha.length > 0 ? "error" : null}
                            placeholder="کد امنیتی"
                            type="number"
                            name='captcha'
                            noValidate
                            onChange={this.handleChange}
                            error={this.state.error.captcha}
                            variant="outlined"
                            size="small"
                        />
                        {this.state.formErrors.captcha  && (
                            <div className="small text-danger" style={{margin: '0 8px -8px 0'}}>{this.state.formErrors.captcha}</div>
                        )}
                    </div>
                    <div className='captcha-img-wrapper'>
                        <div className="captcha-refresh-btn">
                            <IconButton
                                tabIndex={-1}
                                onClick={this.updateRecaptcha}
                            >
                                <RefreshIcon/>
                            </IconButton>
                        </div>
                        <div className="captcha-img">
                            <img src={'/core/api/captcha/login?v=' + this.props.captchaVersion} alt='Login Captcha'/>
                        </div>

                    </div>

                </div>
                <div className="forget-password-text">
                    در صورت فراموشی رمز ورود به حساب
                    <div className="forget-link" onClick={() => this.props.authModeSelector("forgetPassword")}>اینجا</div>
                    کلیک کنید.
                    {/*<Link to="/reset-password">*/}
                    {/*    رمز خود را فراموش کرده اید؟*/}
                    {/*</Link>*/}
                </div>


                {/*<NotificationContainer/>*/}

            </>
        )


    }


    getStepContent(stepIndex) {
        switch (stepIndex) {
            case 1:
                this.stopTimer();
                return this.getUserInfo();
            case 2:
                this.startTimer();
                return this.getVerifyCode();
            default:
                return <IntlMessages id="appModule.loginSuccessfully"/>;
        }
    }

    render() {
        const {classes, signingIn, signinStep, signinVerifyDetails} = this.props;

        return (


            <div className="login-wrapper">
                <div className="login-header-wrapper">
                    {/*<IntlMessages id="home.header.signin"/>*/}
                    <div>
                        ورود | خوش آمدید!
                    </div>

                    برای ادامه ایمیل و رمز عبور خود را وارد نمایید:
                </div>
                <div className="login-form">
                    <form>
                        <fieldset className={signinStep === 2 ? "second-step" : " login-fieldset"}>
                            {this.getStepContent(signinStep)}
                            {signinStep === 1 ?
                                <div className="button-wrapper">
                                    <Button
                                        variant="contained"
                                        className=" confirm-btn"
                                        disabled={signingIn}
                                        onClick={this.handleNext}
                                        // style={{backgroundColor: '#5e37ff', color: '#ffffff'}}
                                        fullWidth
                                        type="submit"
                                    >
                                        ادامه
                                    </Button>
                                    {signingIn &&
                                        <CircularProgress style={{zIndex: '9'}} size={24}
                                                          className={classes.buttonProgress}/>}
                                </div>
                                :
                                <div className="buttons-wrapper">
                                    <div className="confirm-btn-wrapper">
                                        <Button
                                            variant="contained"
                                            className=" confirm-btn"
                                            disabled={signingIn}
                                            onClick={this.handleNext}
                                            fullWidth
                                            type="submit"
                                        >
                                            {signinStep === 1 ?
                                                <IntlMessages id="appModule.signup"/> : "تایید"}
                                        </Button>
                                        {signingIn &&
                                            <CircularProgress style={{zIndex: '9'}} size={24}
                                                              className={classes.buttonProgress}/>}
                                    </div>
                                    <div className="cancel-btn-wrapper">
                                        <Button
                                            onClick={this.handleBack}
                                            className="cancel-btn btn-disable"
                                            fullWidth
                                        >
                                            <IntlMessages id="appModule.back"/>
                                        </Button>
                                    </div>
                                </div>
                            }

                        </fieldset>
                    </form>
                </div>
            </div>

        );
    }
}

const mapStateToProps = ({auth, settings}) => {
    const {darkTheme} = settings;
    const {signingIn, tokenReceiveTime, signinStep, signinVerifyDetails, captchaVersion} = auth;
    return {signingIn, tokenReceiveTime, signinStep, signinVerifyDetails, captchaVersion, darkTheme};
};

export default compose(
    connect(
        mapStateToProps,
        {userSignIn, verifyUserSignIn, setSigninStep, updateCaptchaVersion, resendSignIn, authModeSelector},
    ),
    withStyles(styles)
)(SignIn)


import {global} from "../store/global";
import socketIOClient from "socket.io-client";
import {
    getTransfer,
    redrawTransfers,
    setBaseCurrencies,
    setCurrencies,
    setSupplies,
    setTranslates, whatsup
} from "../actions/CurrencyResource";
import {
    FETCHING_NETWORKS,
    INSTANT_BANKS_FETCHED,
    LAST_AUTH_FETCHED,
    NEW_NETWORK,
    NEW_PANEL_USER_NOTICE,
    NEW_SYSTEM_NOTICE,
    NEW_TICKET,
    NEW_TICKET_MESSAGE,
    PANEL_USER_NOTICES_FETCHED,
    PRICE_UPDATE_SETTINGS_FETCHED,
    REDRAW_TRANSACTIONS_LIST,
    SEEN_USER_NOTICE,
    SYSTEM_NOTICES_FETCHED,
    TICKET_CLOSED,
    TICKET_SEEN,
    TICKET_TYPING,
    UPDATE_NETWORK,
} from "../constants/ActionTypes";

import {getTransaction, redrawTransactions} from "../actions/Wallet";
import {DOMAIN, PATH} from "../constants/Path";
import {getUser, getUserScores, setTerminalInfo} from "../actions";
import {AES_KEY, BRANCH_ESSENTIALS, TRANSFER_TYPES} from "../panel/routes/data/constants";
import {getCardsList} from "../actions/CreditCardResource";
import {getTicketMessages, getTicketsList} from "../actions/SupportResource";

const AesEncryption = require('aes-encryption')
var ss = require('socket.io-stream');
const aes = new AesEncryption()
aes.setSecretKey(AES_KEY)

const PRICE_HISTORY_PATHS = [
    PATH.DASHBOARD, PATH.NEW_EXCHANGE, PATH.HOMEPAGE
]
const socketConfig = {
    grabHistory : PRICE_HISTORY_PATHS.includes(window.location.pathname),

}
const socketEndpoint = '/' + BRANCH_ESSENTIALS.TERMINAL_ID;
let socket;
let version;

export const initializeSocket = (dispatcher) => {
    global.dispatcher = dispatcher;
    socket = socketIOClient(socketEndpoint, {
        query: {token: localStorage.getItem('token')}
    });
    registerListeners(dispatcher);

    socket.on('connect', () => {
        getInitData(socketConfig.grabHistory);
    })
}

export const authorizeSocket = (token) => {
    socket.emit('authorize', {token}, function(data) {
        if(data.authorized)
            getNotices();
    });
}

export const getLastAuth = (authType) => {
    console.log('Requesting last auth ', authType);
    socket.emit('lastAuthRequest', {authType}, function(data) {
        console.log('Last auth:', data);
        global.dispatcher({type: LAST_AUTH_FETCHED, payload: {data: data}});
    });
}

export const seenUserNotice = (noticeID) => {
    socket.emit('seen_user_notice', {notice_id: noticeID, seen: 1});
}
export const sendChunk = (base64, offset, id) => {
    return new Promise(function(resolve, reject) {
        let chunk = base64.slice(offset * 41000, (offset + 1) * 41000);
        if(chunk.length === 0){
            console.log('ended');
            socket.emit('endFile', {id}, function (data) {
                console.log('upload info:', data);
            });
            resolve();
            return;
        }
        socket.emit('uploadChunk', {chunk, offset, id}, function (data) {
            console.log('chunk sent:', offset, data);
            sendChunk(base64, data.waitingOffset, id);
            resolve()
        });
        // setTimeout(() => {
        //     sendChunk(base64, offset, id);
        //     reject();
        // }, 8000);
    })

}
export const sendTicketFile2 = (base64, md5, unit, extension, size) => {
    socket.emit('startUpload', {md5, unit, extension, size}, function (data) {
        console.log('Upload started:', data);
        sendChunk(base64, data.waitingOffset, data.id);
    });
    // sendChunk(base64, 0, 1);
}
export const sendTicketFile = (file) => {
    let fileReader = new FileReader();
    let stream = ss.createStream();
    ss(socket).emit('permitTicketFile', stream);
    let blobStream = ss.createBlobReadStream(file);
    blobStream.pipe(stream);
    var size = 0;

    blobStream.on('data', function(chunk) {
        size += chunk.length;
        console.log(Math.floor(size / file.size * 100) + '%');
        // -> e.g. '42%'
    });
}

setInterval(function () {// DO WHATSUP
    let now = Date.now() / 1000;
    // console.log('global.selectedTicket', global.selectedTicket);
    // console.log('global.chatBoxSelectedTicket', global.chatBoxSelectedTicket);
    // console.log('selectedOrderID:', global.selectedOrderID, ' order:', global.selectedOrder);
    // console.log('selectedTransID:', global.selectedTransID, ' order:', global.selectedOrder);
    if((global.selectedOrderID && (now - global.selectedOrder.updated_at) > 60) ||
        (global.selectedTransID && (now - global.selectedOrder.updated_at) > 60 ) ||
        ((global.selectedTicket && global.selectedTicket.id) && (now - global.selectedTicket.updated_at) > 60) ||
        ((global.chatBoxSelectedTicket && global.chatBoxSelectedTicket.id) && (now - global.chatBoxSelectedTicket.updated_at) > 60) ) {
        whatsup(global.selectedOrderID ?? global.selectedTransID,
            (global.selectedOrderID || global.selectedTransID ? global.selectedOrder.updated_at : null),
            (global.selectedOrderID || global.selectedTransID ? global.selectedOrder.status : null),
            (global.selectedTicket && global.selectedTicket.id ? global.selectedTicket.id : null),
            (global.selectedTicket && global.selectedTicket.id ? global.selectedTicket.updated_at : null),
            (global.selectedTicket && global.selectedTicket.id ? global.selectedTicket.status : null),
            (global.chatBoxSelectedTicket && global.chatBoxSelectedTicket.id ? global.chatBoxSelectedTicket.id : null),
            (global.chatBoxSelectedTicket && global.chatBoxSelectedTicket.id ? global.chatBoxSelectedTicket.updated_at : null),
            (global.chatBoxSelectedTicket && global.chatBoxSelectedTicket.id ? global.chatBoxSelectedTicket.status : null)
        );
    }
    // if(global.selectedTransID && (now - global.selectedOrder.updated_at) > 60) {
    //     global.dispatcher(getTransaction(global.selectedTransID));
    //     global.dispatcher(redrawTransactions());
    //     // console.log('should request transactions:', global.selectedOrderID);
    // }

}, 60000);

export const registerListeners = (dispatcher) => {
    if(socket.hasListeners('prices'))
        return;
    socket.on('version', data => {
        if(version && version !== data.version) {
            window.confirm('پلتفرم به نسخه ی جدیدتری آپدیت شده است.\nبرای استفاده از جدیدترین نسخه روی دکمه زیر کلیک کنید.');
            window.location.reload();
        }
        version = data.version;
    })
    socket.on("prices", data => {
        // if(!global.priceUpdatePeriod)
        //     socket.emit('priceUpdateSettings', {});
        if(data) {
            try{
                const decrypted = aes.decrypt(data);
                // console.log('decrypted price => ', JSON.parse(decrypted));
                dispatcher(setCurrencies(JSON.parse(decrypted)));
            }catch (e) {
                console.log('price error => ', e.message);
            }

        }
    });
    socket.on("base_currencies", data => {
        global.dispatcher(setBaseCurrencies(data.currencies));
    });
    socket.on("supplies", data => {
        if(data)
            dispatcher(setSupplies(data));
    });
    socket.on("new_translate", data => {
        if(data)
            dispatcher(setTranslates(data.currency));
    });
    socket.on("priceUpdateSettings", data => {
        dispatcher({type: PRICE_UPDATE_SETTINGS_FETCHED, payload: data}); // its working dont afraid
    });
    socket.on("networkUpdate", data => {
        dispatcher({type: FETCHING_NETWORKS, payload: {}}); // its working dont afraid
        dispatcher({type: UPDATE_NETWORK, payload: data}); // its working dont afraid
    });
    socket.on("new_networks", data => {
        dispatcher({type: NEW_NETWORK, payload: data});
    });
    socket.on("new_credit_card", data => {
        dispatcher(getCardsList()); // its working dont afraid
    });
    socket.on("update_credit_card", data => {
        dispatcher(getCardsList()); // its working dont afraid
    });
    socket.on("new_user_notice", data => {
        dispatcher({type: NEW_PANEL_USER_NOTICE, payload: data});
    });
    socket.on("new_system_notice", data => {
        dispatcher({type: NEW_SYSTEM_NOTICE, payload: data});
    });
    socket.on("seen_user_notice", data => {
        dispatcher({type: SEEN_USER_NOTICE, payload: data});
    });
    socket.on("update_user_notice", data => {
        getNotices();
    });
    socket.on("update_system_notice", data => {
        dispatcher({type: SYSTEM_NOTICES_FETCHED, payload: data});
    });
    socket.on("update_score", data => {
        dispatcher(getUserScores());
    });
    socket.on("update_levels", data => {
        dispatcher(getUserScores());
    });
    socket.on("new_ticket", data => {
        dispatcher({type: NEW_TICKET, payload: data});
    });
    socket.on("new_ticket_message", data => {
        dispatcher({type: NEW_TICKET_MESSAGE, payload: data});
    });
    socket.on("close_ticket", data => {
        dispatcher({type: TICKET_CLOSED, payload: data});
    });
    socket.on("seen_ticket", data => {
        global.dispatcher({type: TICKET_SEEN, payload: data});
    });
    socket.on("ticket_typing", data => {
        dispatcher({type: TICKET_TYPING, payload: data});
    });
    socket.on("update_instant_banks", data => {
        if(global.withdrawDialogOpened)
            getInstantBanks();
    });
    socket.on("update_ticket", data => {
        dispatcher(getTicketsList({})); // its working dont afraid
        if(global.selectedTicket && global.selectedTicket.id === data.ticket_id)
            dispatcher(getTicketMessages({ticket: global.selectedTicket}));


    });
    socket.on("new_transfer", data => {
        dispatcher(getUser());
        if ([PATH.DASHBOARD, PATH.TRANSACTIONS_LIST].includes(window.location.pathname) ||
            (window.location.pathname === PATH.WITHDRAWS_LIST && data.type === TRANSFER_TYPES.WITHDRAW) ||
            (window.location.pathname === PATH.DEPOSITS_LIST && data.type === TRANSFER_TYPES.DEPOSIT)) {

            dispatcher(redrawTransactions());

        } else if ([PATH.DASHBOARD, PATH.TRANSFERS_LIST].includes(window.location.pathname)) {
            dispatcher(redrawTransfers());
        }

    });
    socket.on("transfer_update", data => {
        dispatcher(getUser());
        if(global.selectedOrderID === parseInt(data.transfer_id)) {
            dispatcher(getTransfer(data.transfer_id));
            // store.dispatch({type: TRANS_INFO_FETCHED});
            dispatcher(redrawTransfers());
        }
        if(global.selectedTransID === parseInt(data.transfer_id)) {
            dispatcher(getTransaction(data.transfer_id));
            // store.dispatch({type: TRANS_INFO_FETCHED});
            dispatcher(redrawTransactions());
        }

        if ([PATH.DASHBOARD, PATH.TRANSACTIONS_LIST, PATH.DEPOSITS_LIST].includes(window.location.pathname) ||
            (window.location.pathname === PATH.WITHDRAWS_LIST && data.type === TRANSFER_TYPES.WITHDRAW)) {

            dispatcher(redrawTransactions());

        } else if ([PATH.DASHBOARD, PATH.TRANSFERS_LIST].includes(window.location.pathname)) {
            dispatcher(redrawTransfers());
        }

    });

    socket.on("payment_update", data => {
        dispatcher(getUser());
        switch (window.location.pathname) {
            case PATH.TRANSACTIONS_LIST:
            case PATH.DEPOSITS_LIST: {
                dispatcher({type: REDRAW_TRANSACTIONS_LIST});
            }
        }
    });
}

export const
    getInstantBanks = () => {
        socket.emit('instant_banks', {}, function (data) {
            global.dispatcher({type: INSTANT_BANKS_FETCHED, payload: data});
        });
    }

const getNotices = () => {
    socket.emit('notices', {}, function (data) {
        // console.log('====> Ack notices data:', data);
        global.dispatcher({type: PANEL_USER_NOTICES_FETCHED, payload: data});
    });
}

const getSystemNotices = () => {
    socket.emit('system_notices', {}, function (data) {
        global.dispatcher({type: SYSTEM_NOTICES_FETCHED, payload: data});
    });
}

export const ticketTyping = (ticketID, message) => {
    socket.emit('ticket_typing', {ticket_id: ticketID, message});
}

export const ticketSeen = (ticketID) => {
    socket.emit('seen_ticket', {ticket_id: ticketID});
}

const getInitData = (grabHistory) => {
    grabPriceHistory(grabHistory);
    socket.emit('initData', {}, function (data) {
        console.log('Init data received');
        if(version && version !== data.version) {
            window.confirm('پلتفرم به نسخه ی جدیدتری آپدیت شده است.\nبرای استفاده از جدیدترین نسخه روی دکمه زیر کلیک کنید.');
            window.location.reload();
        }
        const decrypted = aes.decrypt(data.prices);
        // console.log('decrypted price2 => ', JSON.parse(decrypted));
        version = data.version;
        global.dispatcher({type: PRICE_UPDATE_SETTINGS_FETCHED, payload: data.priceUpdateSettings});
        global.dispatcher({type: SYSTEM_NOTICES_FETCHED, payload: data});
        global.dispatcher(setTerminalInfo(data.terminal));
        console.log('prices:', JSON.parse(decrypted))
        global.dispatcher(setCurrencies(JSON.parse(decrypted)));
        global.dispatcher(setSupplies(data.supplies));
        global.dispatcher(setTranslates(data.lang.currency));
        global.dispatcher(setBaseCurrencies(data.currencies));
        if(data.authorized)
            getNotices();
    });
}

export const grabPriceHistory = (grab) => {
    if(socketConfig.grabHistory === grab)
        return;
    socketConfig.grabHistory = grab;
    socket.emit('pricesHistory', {active: grab});
}

export const registerPriceListener = (dispatcher, grabPricesHistory = false) => {
    // global.dispatcher = dispatcher;
    // socketConfig.grabHistory = grabPricesHistory;

}

export default socket;
// import openSocket from 'socket.io-client';
//
// const socketEndpoint = 'wss://novinbit.com/';
// const socket = openSocket(socketEndpoint);

import React from "react";
import HomeFooter from "./HomeFooter";


class Footer extends React.Component{
    render(){
        return (
            <div className="footer-wrapper">
                <HomeFooter/>
            </div>
        )
    }

}
export default Footer;
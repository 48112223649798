import React from "react";
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IntlMessages from "../../../util/IntlMessages";
import {connect} from "react-redux";
import {switchBuyDialog, switchChangeDialog, switchSellDialog} from "../../../actions/CurrencyResource";
import '../../../assets/css/formStyle.css'
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {currencySymbol, fa2enNumbers, roundDown, roundUp, stepPrecision, translate} from "../../../util/Utilities";
import "../../../assets/css/main.css"
import {Card, InputAdornment, Popper} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from "@material-ui/core/CircularProgress";
import {AccountCircle} from "@material-ui/icons";
import {DIGITAL_CURRENCIES, STATUS} from "../../../panel/routes/data/constants";
import {authModeSelector, switchAuthDialog} from "../../../actions";

// const PopperMy = function (props) {
//     return <Popper {...props} className='select-currency-popover' placement="bottom-start" />;
// };

const ACCEPTED = [STATUS.ACCEPTED_BY_ADMIN, STATUS.ACCEPTED_BY_SYSTEM, STATUS.ACCEPTED_BY_EX_API];

function TabContainer(props) {
    return (
        <div className="tab-container">
            {props.children}
        </div>
    );
}

const PopperMy = function (props) {
    return <Popper {...props} className='panel-select-currency-popover' placement="bottom-start" />;
};

class CurrencyConverter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            inputs: {
                conversion: 1,
                origin_currency: 12,
                destination_currency: 1195,
                origin_amount: 0,
                destination_amount: 0,
                selectedBase: DIGITAL_CURRENCIES.IRR,
                total: 0,
                selectedPair: {},
                selectedCurrencyInput: '',
                oSelectedPair: {},
                oCurrencyInput: '',
                origin_supply: ''
            },

            value: 1,
        };
        const currencyDatasLoaded = !props.loadingCurrencies && props.translates['BTC'] && props.baseCurrencies['1'];
        if(currencyDatasLoaded) {
            this.state.inputs.origin_currency = Object.values(props.currencies).filter((pair) => ACCEPTED.includes(pair.buy_active))[0]['base']
            this.state.inputs.destination_currency = Object.values(props.currencies).filter((pair) => pair.base === this.state.inputs.origin_currency && ACCEPTED.includes(pair.buy_active))[0]['currency']
            this.state.inputs.selectedPair = Object.values(props.currencies).find((pair) => pair.currency == this.state.inputs.destination_currency && pair.base == this.state.inputs.origin_currency);
            this.state.inputs.origin_supply = this.getCurrencySupply(+this.state.inputs.origin_currency);
            console.log('loaded:', this.state.inputs.origin_currency, this.state.inputs.destination_currency);
        }

    }

    getCurrencySupply(originCurrency) {
        if([DIGITAL_CURRENCIES.PM_USD, DIGITAL_CURRENCIES.PV_USD].includes(originCurrency))
            return DIGITAL_CURRENCIES.PM_USD;
        if([DIGITAL_CURRENCIES.UUSD, DIGITAL_CURRENCIES.UV_USD].includes(originCurrency))
            return DIGITAL_CURRENCIES.UUSD;
        if([DIGITAL_CURRENCIES.WM].includes(originCurrency))
            return DIGITAL_CURRENCIES.WM;
        if([DIGITAL_CURRENCIES.IRR].includes(originCurrency))
            return DIGITAL_CURRENCIES.IRR;
        return DIGITAL_CURRENCIES.USDT;

    }



    componentWillReceiveProps(nextProps, nextContext) {
        const inputs = this.state.inputs;
        if(!Object.keys(this.props.currencies).length && Object.keys(nextProps.currencies).length > 1) {

            inputs.selectedPair = Object.values(nextProps.currencies).find((pair) => ACCEPTED.includes(pair.buy_active));
            if(inputs.selectedPair){
                inputs.origin_currency = inputs.selectedPair.base
                inputs.destination_currency = inputs.selectedPair.currency;
            }
            inputs.origin_supply = this.getCurrencySupply(+this.state.inputs.origin_currency);
            this.setState({inputs: inputs});
            console.log('inputs.origin_currency:', inputs.origin_currency, ' destination_currency', inputs.destination_currency)
        }
        // if(!currencyDatasLoaded) {
        // }
        // if(nextProps.currency) {
        //     let pairs = Object.values(nextProps.currencies);
        //     const {inputs} = this.state;
        //     if(this.state.conversion === 1) {
        //         if(!ACCEPTED.includes(pairs.filter((pair) => this.state.inputs.origin_currency === pair.currency)[0]['buy_active'])){
        //             inputs.origin_currency = pairs.filter((pair) => ACCEPTED.includes(pair.buy_active))[0]['currency']
        //         }
        //            this.setState({inputs: inputs})
        //     }else {
        //         if(!ACCEPTED.includes(
        //             pairs.filter((pair) => this.state.inputs.origin_currency === pair.currency)[0]['sell_active'])){
        //             inputs.origin_currency = pairs.filter((pair) => ACCEPTED.includes(pair.sell_active))[0]['currency']
        //         }
        //         this.setState({inputs: inputs})
        //     }
        // }

    }

    validateFormValue = (name, value) => {
        value = fa2enNumbers(value);
        let {inputs, widget_back} = this.state;
        inputs[name] = value;
        // if(['origin_amount', 'destination_amount'].includes(name))
        //     inputs[name] = +value;
        switch (name) {
            case 'origin_currency':
            case 'destination_currency':
                if(this.props.baseCurrencies) {
                    inputs.selectedPair = this.props.currencies[currencySymbol(this.props.baseCurrencies, inputs.destination_currency) + currencySymbol(this.props.baseCurrencies, inputs.origin_currency)];
                    if(!inputs.selectedPair) {
                        inputs.destination_currency = Object.values(this.props.currencies).filter((pair) => pair.base == inputs.origin_currency && ACCEPTED.includes(pair.buy_active))[0]['currency']
                        inputs.selectedPair = this.props.currencies[currencySymbol(this.props.baseCurrencies, inputs.destination_currency) + currencySymbol(this.props.baseCurrencies, inputs.origin_currency)];
                    }
                    inputs.origin_supply = this.getCurrencySupply(+inputs.origin_currency);
                    console.log('origin_supply', inputs.origin_supply)
                }
                break;
            case 'origin_amount':
                inputs.destination_amount = inputs.selectedPair ? roundDown(inputs.origin_amount * inputs.selectedPair.buy, stepPrecision(this.props.baseCurrencies, inputs.destination_currency)) : 0;
                break;
            case 'destination_amount':
                inputs.origin_amount = inputs.selectedPair ? roundUp(inputs.destination_amount / inputs.selectedPair.buy, stepPrecision(this.props.baseCurrencies, inputs.origin_currency)) : 0;
                break;


        }
        console.log('selected pair:', inputs.selectedPair, 'origin', inputs.origin_currency, 'des', inputs.destination_currency);
        // let currencyPrice = 0;
        // if (inputs.conversion === 1) { // BUY
        //     currencyPrice = this.props.currencies ? this.props.currencies[currencySymbol(this.props.baseCurrencies, inputs.destination_currency) + currencySymbol(this.props.baseCurrencies, inputs.origin_currency)]['buy'] : "";
        // } else { //sell
        //     currencyPrice = this.props.currencies ? this.props.currencies[currencySymbol(this.props.baseCurrencies, inputs.destination_currency) + currencySymbol(this.props.baseCurrencies, inputs.origin_currency)]['sell'] : "";
        // }
        // inputs.total = currencyPrice * inputs.origin_amount;
        this.setState({inputs, widget_back});
        return true;
    };

    handleClick = (name, value) => {
        this.validateFormValue(name, value);

    };

    handleChange = (e) => {
        const {name, value} = e.target;
        this.validateFormValue(name, value);

    };

    onChange = (event, value) => {
        this.setState({value});
    };


    render() {
        const {inputs} = this.state;
        const {loadingCurrencies, currencies, currentUser, supplies, token} = this.props;
        const currencyDatasLoaded = !loadingCurrencies && this.props.translates['BTC'] && this.props.baseCurrencies['1'];
        const {value} = this.state;
        const filteredBases = [];
        const filteredCurrencies = [];
        return (
            <Card className=" jr-card panel-calculator-wrapper">
                <div className="calculator-inner-wrapper"
                    //      style={{
                    //     height: (width <= MOBILE_MAX_WIDTH ? '365px' : 'calc(100vh - 268px)'),
                    //     minHeight: (width <= MOBILE_MAX_WIDTH ? '365px' : '420px')
                    // }}
                >
                    <div className="calculator">
                        {!currencyDatasLoaded && (

                            <fieldset disabled={loadingCurrencies} className="p-0">
                                <form autoComplete="off" className="row calc-form">
                                    <div className="col-12 mb-3 px-2 calculator-loading">
                                        <CircularProgress/>
                                        <p className="w-100 text-center">در حال بارگذاری</p>
                                    </div>
                                </form>
                            </fieldset>

                        )}
                        {currencyDatasLoaded &&
                            <div className="calculator-fields-wrapper">
                                <fieldset disabled={loadingCurrencies} className="p-0 field-set-give">
                                    {/*<div className="jr-card p-0 m-0">*/}
                                    <div className="input-label">
                                        پرداخت می‌کنید:
                                    </div>
                                    <form autoComplete="off" className="form-give calc-form">

                                        <div className="fields-row-wrapper">
                                            <div className="field-row field-currency">
                                                <Autocomplete
                                                    PopperComponent={PopperMy}
                                                    id="o-currency"
                                                    // PopperComponent={PopperMy}
                                                    // style={{ width: 300 }}
                                                    value={inputs.selectedPair}
                                                    inputValue={inputs.oCurrencyInput}
                                                    // defaultValue={inputs.selectedPair}
                                                    onOpen={() => {
                                                        this.validateFormValue('oCurrencyInput', '');
                                                    }
                                                    }
                                                    onInputChange={(event, newValue, reason) => {

                                                        // if(reason !== 'reset')
                                                        this.validateFormValue('oCurrencyInput', newValue);
                                                    }}
                                                    // defaultValue={inputs.selectedPair}
                                                    options={Object.values(currencies).filter((pair) => {
                                                        return (pair.base !== DIGITAL_CURRENCIES.WM) && ACCEPTED.includes(pair.buy_active) && !filteredBases.includes(pair.base) && (filteredBases.push(pair.base) || 1)
                                                    })}
                                                    // open={true}
                                                    onChange={(event, newValue) => {
                                                        this.validateFormValue('origin_currency', newValue.base);
                                                    }}
                                                    autoHighlight
                                                    disableClearable
                                                    getOptionLabel={(pair) => {
                                                        return translate(this.props.translates, currencySymbol(this.props.baseCurrencies, pair.base)) + " (" + currencySymbol(this.props.baseCurrencies, pair.base) + ")"
                                                    }}
                                                    renderOption={(pair) => (
                                                        <React.Fragment>
                                                            <MenuItem className=" px-1 flex-shrink-1 flex-grow-1"
                                                                      key={pair.base} value={pair.base}>
                                                                <div className="d-inline">
                                                                    <div
                                                                        className="coin-logo-converter mr-1 float-right"
                                                                        style={{backgroundPositionY: ([pair.base] - 1) * -20}}/>
                                                                    <div className=" ml-1">
                                                                        {translate(this.props.translates, currencySymbol(this.props.baseCurrencies, pair.base))} ({currencySymbol(this.props.baseCurrencies, pair.base)})
                                                                    </div>
                                                                </div>
                                                            </MenuItem>
                                                        </React.Fragment>
                                                    )}
                                                    renderInput={(params) => {
                                                        return (
                                                            <div className="selected-currency">
                                                                <TextField
                                                                    className="give-text-field currency-input"
                                                                    {...params}
                                                                    label={<IntlMessages id="title.origin_currency"/>}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <div className="coin-logo-converter  float-right"
                                                                                     style={{backgroundPositionY: ([inputs.selectedPair.base] - 1) * -22}}/>

                                                                            </InputAdornment>
                                                                        ),
                                                                    }}

                                                                />
                                                            </div>

                                                        )
                                                    }}
                                                />
                                            </div>
                                            <div className="field-row">
                                                <TextField
                                                    className="give-text-field price-input"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    label={'مقدار'}

                                                    size='small'
                                                    variant="outlined"
                                                    id="search"
                                                    onChange={this.handleChange}
                                                    value={+inputs.origin_amount}
                                                    name="origin_amount"
                                                    // name="origin_currency"
                                                    type="number"
                                                    margin="normal"
                                                    defaultValue={"25,500,000"}
                                                    fullWidth
                                                    style={{
                                                        marginTop: 0,
                                                        marginBottom: 0,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {token === null ? "" :
                                            <div className="field-set-bottom">
                                                <div className="title">
                                                    &nbsp;
                                                </div>
                                                <span className="value">

                                        </span>
                                            </div>
                                        }


                                    </form>

                                    {/*</div>*/}
                                </fieldset>
                                <fieldset disabled={loadingCurrencies} className="p-0 field-set-get">
                                    {/*<div className="jr-card p-0 m-0">*/}
                                    <div className="input-label">
                                        دریافت می‌کنید:
                                    </div>
                                    <form autoComplete="off" className="form-get calc-form">

                                        <div className="fields-row-wrapper">
                                            <div className="field-row field-currency">
                                                <Autocomplete
                                                    PopperComponent={PopperMy}
                                                    id="currency-select"
                                                    // PopperComponent={PopperMy}
                                                    // style={{ width: 300 }}
                                                    value={inputs.selectedPair}
                                                    inputValue={inputs.selectedCurrencyInput}
                                                    // defaultValue={inputs.selectedPair}
                                                    onOpen={() => {
                                                        this.validateFormValue('selectedCurrencyInput', '');
                                                    }
                                                    }
                                                    onInputChange={(event, newValue, reason) => {

                                                        // if(reason !== 'reset')
                                                        this.validateFormValue('selectedCurrencyInput', newValue);
                                                    }}
                                                    // defaultValue={inputs.selectedPair}
                                                    options={Object.values(currencies).filter((pair) => {
                                                        // if(ACCEPTED.includes(pair.buy_active) && !filteredCurrencies.includes(pair.currency) && pair.base == inputs.origin_currency && ((filteredCurrencies.push(pair.currency) || 1)))
                                                        //     console.log('selected', pair)
                                                        // else
                                                        //     console.log('else:', pair, filteredCurrencies)
                                                        // console.log('currency option:', ACCEPTED.includes(pair.buy_active), !filteredCurrencies.includes(pair.currency) , pair.base == inputs.origin_currency, inputs.origin_currency, filteredCurrencies)
                                                        return ACCEPTED.includes(pair.buy_active) && !filteredCurrencies.includes(pair.currency) && pair.base == inputs.origin_currency && ((filteredCurrencies.push(pair.currency) || 1))

                                                    })}
                                                    // open={true}
                                                    onChange={(event, newValue) => {
                                                        this.validateFormValue('destination_currency', newValue.currency);
                                                    }}
                                                    autoHighlight
                                                    disableClearable
                                                    getOptionLabel={(pair) => {
                                                        return translate(this.props.translates, currencySymbol(this.props.baseCurrencies, pair.currency)) + " (" + currencySymbol(this.props.baseCurrencies, pair.currency) + ")"
                                                    }}
                                                    renderOption={(pair) => (
                                                        <React.Fragment>
                                                            <MenuItem className=" px-1 flex-shrink-1 flex-grow-1"
                                                                      key={pair.currency} value={pair.currency}>
                                                                <div className="d-inline">
                                                                    <div
                                                                        className="coin-logo-converter mr-1 float-right"
                                                                        style={{backgroundPositionY: ([pair.currency] - 1) * -20}}/>
                                                                    <div className=" ml-1">
                                                                        {translate(this.props.translates, currencySymbol(this.props.baseCurrencies, pair.currency))} ({currencySymbol(this.props.baseCurrencies, pair.currency)})
                                                                    </div>
                                                                </div>
                                                            </MenuItem>
                                                        </React.Fragment>
                                                    )}
                                                    renderInput={(params) => {
                                                        return (
                                                            <div className="selected-currency">

                                                                <TextField
                                                                    className="currency-input"


                                                                    {...params}
                                                                    label={<IntlMessages
                                                                        id="title.destination_currency"/>}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <div className="coin-logo-converter  float-right"
                                                                                     style={{backgroundPositionY: ([inputs.selectedPair.base] - 1) * -22}}/>

                                                                            </InputAdornment>
                                                                        ),

                                                                    }}
                                                                    // inputProps={{

                                                                    //     // startAdornment: (<div
                                                                    //     //     className="coin-logo-converter mr-1 float-right"
                                                                    //     //     style={{backgroundPositionY: ([inputs.selectedPair.currency] - 1) * -22}}/>),
                                                                    //     ...params.inputProps,
                                                                    //     // autoComplete: 'new-password', // disable autocomplete and autofill
                                                                    // }}
                                                                />
                                                            </div>

                                                        )
                                                    }}
                                                />
                                            </div>
                                            <div className="field-row">
                                                <TextField

                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    className=" price-input"
                                                    size='small'
                                                    label={'مقدار'}
                                                    variant="outlined"
                                                    id="search"
                                                    onChange={this.handleChange}
                                                    onKeyPress={e => {
                                                        if (e.key === 'Enter') {
                                                            this.props.switchAuthDialog({state: true});
                                                            this.props.authModeSelector("login");
                                                        }
                                                    }}
                                                    value={+inputs.destination_amount}
                                                    name="destination_amount"
                                                    type="number"
                                                    // defaultValue={}
                                                    margin="normal"
                                                    fullWidth
                                                    style={{
                                                        marginTop: 0,
                                                        marginBottom: 0,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="field-set-bottom">
                                            <div className="title">
                                                موجودی سایت (حداکثر خرید)
                                            </div>
                                            <span className="value">
                                            {!supplies[inputs.destination_currency] ? '-' : supplies[inputs.destination_currency].toString().match((new RegExp('^-?\\d+(?:\.\\d{0,' + (Math.min(stepPrecision(this.props.baseCurrencies, inputs.destination_currency), 5) || -1) + '})?')))}
                                        </span>
                                        </div>

                                    </form>

                                    {/*</div>*/}
                                </fieldset>

                                <div className="button-section-wrapper">
                                    <div className="per-coin-price">
                                        {inputs.selectedPair ? (
                                            <>
                                                <span className="title"> هر 1 {translate(this.props.translates, currencySymbol(this.props.baseCurrencies, inputs.origin_currency))}
                                                </span>
                                                <span className="equal-sign">
                                                    =
                                                </span>
                                                <span className="value">
                                                      {(+inputs.selectedPair.buy).toLocaleString()}  {translate(this.props.translates, currencySymbol(this.props.baseCurrencies, inputs.destination_currency))}
                                                </span>
                                            </>
                                        ) : ''}

                                    </div>
                                    <div className="button-wrapper">
                                        <Button
                                            variant="contained"
                                            className="buy-btn"
                                            disabled={loadingCurrencies}
                                            fullWidth
                                            onClick={() => {
                                                this.props.switchAuthDialog({state: true});
                                                this.props.authModeSelector("login");
                                            }}
                                        >
                                            ثبت سفارش
                                        </Button>
                                    </div>
                                </div>
                            </div>}
                    </div>
                </div>
            </Card>


        );
    }
};

const mapStateToProps = ({currency, auth, settings}) => {
    const {currentUser, token} = auth;
    const {width} = settings;
    const {currencies, supplies, loadingCurrencies, baseCurrencies, translates} = currency;
    const filteredCurrencies = Object.fromEntries(
        Object.entries(currencies).filter(([key, value]) => value.base !== DIGITAL_CURRENCIES.IRR) )
    return {currencies: filteredCurrencies, loadingCurrencies, baseCurrencies, translates, currentUser, token, supplies, width}
};
export default connect(mapStateToProps, {switchAuthDialog, authModeSelector})(CurrencyConverter);

import React from "react"
import {Fab, Tooltip, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {getLastLiveChat, switchChatBox, switchLiveChatContentStepper} from "../../../actions/SupportResource";
import Wrapper from "./Wrapper";
import ImageStorage from "../../../assets/ImageStorage";

const styles = (theme) => ({
    fabButton: {
        position: "fixed",
        bottom: "40px",
        right: "40px",
        zIndex: "1",
        // backgroundColor: "#7530ff",
        width: "60px",
        height: "60px",
        // border: "1px solid "
    },
    fabIcon: {
        // '& .MuiSvgIcon-root': {
            fontSize: "45px",
            color: "#fafafa"
        // }

    }
});


class LiveChat extends React.PureComponent {
    state = {
        activeDrags: 0,
    };

    onStart = () => {
        this.setState({activeDrags: ++this.state.activeDrags});
    };

    onStop = () => {
        this.setState({activeDrags: --this.state.activeDrags});
    };

    render() {
        const { darkTheme, chatBoxSelectedTicket, fetchingChatboxTicket} = this.props;
        // const dragHandlers = {onStart: this.onStart, onStop: this.onStop};
        return ( 
            <div className="live-chat-wrapper">
                {/*<Draggable bounds={{top: -100,  right: 100}} {...dragHandlers}>*/}
                <Tooltip title="پشتیبانی">
                    <Fab size={"large"} onClick={() => {
                        if(!chatBoxSelectedTicket.id && !fetchingChatboxTicket)
                            this.props.getLastLiveChat(0);
                        this.props.switchChatBox(true)
                    }} variant={"circular"} className="live-chat-fab">
                        <div className="live-chat-img-wrapper">
                            {ImageStorage.liveChatIcon(darkTheme, 'live-chat-img')}
                        </div>
                    </Fab>
                </Tooltip>


                {/*</Draggable>*/}
                <Wrapper/>
            </div>


        )
    }
}

const mapStateToProps = ({settings, support}) => {
    const {darkTheme} = settings;
    const {chatBoxSelectedTicket, fetchingChatboxTicket} = support;
    return {darkTheme, chatBoxSelectedTicket, fetchingChatboxTicket}
};

export default
 connect(mapStateToProps,{switchChatBox, switchLiveChatContentStepper, getLastLiveChat}) (withStyles(styles)(LiveChat));

import {
    CHECKOUT_REFERRAL_REQUESTED,
    FETCHING_INVITED_LIST,
    FETCHING_REFERRAL_PROFIT_LIST,
    INVITED_LIST_FETCHED,
    REFERRAL_PROFIT_LIST_FETCHED,
    REFERRAL_STATS_FETCHED
} from "../constants/ActionTypes";


const INIT_STATE = {
    invitedFriends: [],
    totalInvitedFriends: 0,
    redraw: false,
    loadingInvitedFriends: false,
    totalReferralProfits: 0,
    loadingReferralProfits: false,
    redrawReferralProfitsList: false,
    referralProfits: [],
    referralStats: {}

};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case INVITED_LIST_FETCHED: {
            return {...state, loadingInvitedFriends: false, redraw: false, invitedFriends: action.payload.invitedFriends ?? [], totalInvitedFriends: action.payload.totalInvitedFriends};
        }
        case FETCHING_INVITED_LIST: {
            return {...state, loadingInvitedFriends: true, redraw: false};
        }
        case CHECKOUT_REFERRAL_REQUESTED: {
            return {...state, loadingInvitedFriends: true, redraw: true, redrawReferralProfitsList: true};
        }
        case FETCHING_REFERRAL_PROFIT_LIST: {
            return {...state, loadingReferralProfits: true, redrawReferralProfitsList: false};
        }
        case REFERRAL_PROFIT_LIST_FETCHED: {
            return {...state, loadingReferralProfits: false, redrawReferralProfitsList: false, referralProfits: action.payload.referralProfits ?? [], totalReferralProfits: action.payload.totalReferralProfits};
        }
        case REFERRAL_STATS_FETCHED: {
            return {...state, referralStats: action.payload.referralStats ?? {}}
        }

        default:
            return state;
    }
}


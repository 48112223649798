import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import "./../../../../assets/css/dialog.css"
import {connect} from "react-redux";
import DepositTabsSection from "./DepositTabs";
import DialogTab from "./DepositTabs";
import {switchDepositDialog} from "../../../../actions/Wallet";

class DepositDialog extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            open: false,
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.state.open &&
            (!nextProps.requestingOnline && !nextProps.requestingC2C &&!nextProps.requestingOffline) &&
            (this.props.requestingOnline || this.props.requestingC2C ||this.props.requestingOffline) &&
            !nextProps.hasErrors)
            this.setState({open: false});

    };

    handleRequestCloseDepositDialog = () => {
        this.props.switchDepositDialog({state: false});
    };

    render(){
        const {depositDialogOpened} = this.props;
        return(
            <Dialog open={depositDialogOpened} maxWidth='sm' fullWidth onClose={this.handleRequestCloseDepositDialog}>
                <DialogTab/>
            </Dialog>
        )
    }
}

const mapStateToProps = ({wallet}) => {
    const{depositDialogOpened} = wallet;
    return {depositDialogOpened}
};

export default connect(mapStateToProps, {switchDepositDialog})(DepositDialog);

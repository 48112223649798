import axios from 'axios';
import {DOMAIN} from "../constants/Path";

const ax =  axios.create({
  baseURL: `/core/api/`,//YOUR_API_URL HERE
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  }
});
axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('token');
export default ax;

import React from "react"
import {Popover, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {closeTicket, switchChatBox, switchLiveChatContentStepper} from "../../../actions/SupportResource";
import Button from '@material-ui/core/Button';
import IconButton from "@material-ui/core/IconButton";
import SelectManagementUnit from "./Components/selectManagementUnit";
import ChatWrapper from "./Components/chatWrapper";
import RateStep from "./Components/rateStep";
import CloseIcon from '@material-ui/icons/Close';
import {MOBILE_MAX_WIDTH} from "../../routes/data/constants";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({

    popoverRoot: {
        '& .MuiPopover-paper': {
            width: 450,
            right: "60px !important",
            bottom: "135px",
            top: "unset !important",
            // minHeight: '600px'
            [theme.breakpoints.down(MOBILE_MAX_WIDTH)]: {
                right: "20px !important",
                // width: "unset"// secondary
            }
        },
    },
    cardRoot: {
        '& .MuiCard-root': {
            // minHeight: '400px'
        }
    },
    cardHeaderRoot: {
      textAlign: "center",
      padding: "6px"

    },
    cardContentRoot: {
        padding: 0,
        paddingBottom: "0px !important",
        height: "calc((100vh - 51px) *.7)",

        // minHeight: '400px'
    },
    card: {
            color: '#ffffff',
        backgroundImage: "linear-gradient(90deg, #320097, #8e2de2)"


    }

});

class Wrapper extends React.Component {


    handleCloseLiveChatWrapper = () => {
        const {stepIndex, chatBoxSelectedTicket} = this.props;
        if (stepIndex === 3) {
            this.props.switchLiveChatContentStepper({stepIndex: 1})
            this.props.closeTicket(chatBoxSelectedTicket.id)

        }
        this.props.switchChatBox(false)

    }

    getStepContent() {
        const {stepIndex, fetchingChatboxTicket} = this.props;
        if(fetchingChatboxTicket)
            return <div className="live-chat-loading-wrapper">
                <div className="live-chat-loading-inner-wrapper">
                    <CircularProgress color={"inherit"} size={24}/>
                    <div className="live-chat-loading-content">
                        در حال بارگیری آخرین گفتگو
                    </div>
                </div>

            </div>
        switch (stepIndex) {
            case 1:
                return <SelectManagementUnit/>
            case 2:
                return <ChatWrapper/>
            case 3:
                return <RateStep/>
            default:
                return <></>
        }
    }


    componentWillUnmount() {
        this.props.switchLiveChatContentStepper({stepIndex: 1})
    }
    handleSubmit = (e) => {
        const {stepIndex} = this.props;
        this.props.switchLiveChatContentStepper({stepIndex : stepIndex + 1})
    }


    render() {
        const {chatBoxMessages, liveChatWrapperOpened, stepIndex} = this.props;
        return (
            <Popover
                className={"live-chat-pop-over"}
                // id={id}
                open={liveChatWrapperOpened}
                // anchorEl={this.switchLiveChatWrapper}
                onClose={this.handleCloseLiveChatWrapper}
                anchorReference="anchorPosition"
                anchorPosition={{top: 0, left: 20}}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                <div className="live-chat-card">
                        <div className="live-chat-card-header">
                            <div className="right-side">
                                <div className="close-btn-wrapper">
                                    <IconButton onClick={this.handleCloseLiveChatWrapper}>
                                        <CloseIcon className="close-icon"/>
                                    </IconButton>
                                </div>
                                <div className="live-chat-header-title">
                                    پشتیبانی IR Exchanger
                                </div>
                            </div>
                            {chatBoxMessages.length === 0 || stepIndex === 3 || stepIndex === 1  ? "" :
                                <div className="left-side">
                                    <div className="end-chat-btn-wrapper">
                                        <Button className='end-chat-btn' onClick={this.handleSubmit}>
                                            اتمام مکالمه
                                        </Button>
                                    </div>

                                </div>
                            }

                        </div>
                        <div className="live-chat-content-wrapper" >
                            {this.getStepContent()}
                        </div>

                </div>
            </Popover>
        )
    }
}

const mapStateToProps = ({support}) => {
    const {liveChatWrapperOpened, stepIndex,chatBoxSelectedTicket, chatBoxMessages, fetchingChatboxTicket } = support;
    return {liveChatWrapperOpened, stepIndex, chatBoxSelectedTicket, chatBoxMessages, fetchingChatboxTicket}
};
export default connect(mapStateToProps, {
    switchChatBox,
    switchLiveChatContentStepper,
    closeTicket,

})(withStyles(styles)(Wrapper));

import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import OnlineForm from "./onlineForm";
import OfflineForm from "./offlineForm";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import {getCardsList} from "../../../../actions/CreditCardResource";
import {getGateways} from "../../../../actions/Wallet";
import IdDeposit from "./IdDeposit";


function DepositTabs(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <>{children}</>
                </Box>
            )}
        </div>
    );
}

DepositTabs.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
});

class DepositTabsSection extends React.Component{


    constructor(props) {
        super(props);
        this.state = {
            cards: this.filterVerifiedCards(this.props.cards),
            activeTab: 0
        }
    }

    componentDidMount() {
        this.setCards();
        this.props.getGateways({
            type: 1

        });
    }

    setCards() {
        console.log('request cards in deposit')
        this.props.getCardsList();
    }

    componentWillReceiveProps(nextProps, nextContext) {

        this.setState({
            cards: this.filterVerifiedCards(nextProps.cards),
        });

    }

    filterVerifiedCards(cards) {
        return cards.filter((card) => {
            return card.verified > 10 && card.verified <= 20
        });
    }

    handleChange = (event, newValue) => {
        this.setState({activeTab: newValue});
    };

    render() {
        const {loadingCards, transferID, gateways, loadingGateways, amount, selectedOrder, currentUser, depositIdentities} = this.props;
        const {cards} = this.state;
        const {activeTab} = this.state;
        return (
            <div className="deposit-tabs-section-wrapper">
                <AppBar position="static" >
                    <Tabs variant="fullWidth" className="tabs-wrapper" value={activeTab} onChange={this.handleChange}>
                        <Tab  fullWidth label="درگاه آنلاین" icon={<img alt="alt" src={require("../../../../assets/images/V2/online-deposit.svg")}/>} {...a11yProps(0)} />
                        {/*<Tab  fullWidth label="کارت به کارت" icon={<img alt="alt" src={require("../../../../assets/images/V2/card-direct-deposit.svg")}/>} {...a11yProps(1)} disabled={selectedOrder.payable - currentUser.wallet.balance < 500000 }/>*/}
                        <Tab  fullWidth label="واریز شناسه دار" icon={<img alt="alt" src={require("../../../../assets/images/V2/offline-deposit.svg")}/>} {...a11yProps(1)} disabled={transferID || (selectedOrder.payable - currentUser.wallet.balance < 500000 )}/>
                        <Tab  fullWidth label="درگاه آفلاین" icon={<img alt="alt" src={require("../../../../assets/images/V2/offline-deposit.svg")}/>} {...a11yProps(2)} disabled={transferID || (selectedOrder.payable - currentUser.wallet.balance < 500000 )}/>

                    </Tabs>
                    <div className="gradient-bottom"/>
                </AppBar>
                <div className="wallet-balance-wrapper">
                    <div className="title-wrapper">
                        <img alt="alt" src={require("../../../../assets/images/V2/wallet-bag.svg")}/>
                        <div className="title">
                            موجودی کیف پول :
                        </div>
                    </div>
                    <div className="value-wrapper">
                        <div className="value">
                            {parseInt(currentUser.wallet.balance / 10).toLocaleString()}
                        </div>
                        <div className="metric">
                            تومان
                        </div>
                    </div>
                </div>
                <DepositTabs disableRipple  value={activeTab} index={0} >
                    <OnlineForm loadingCards={loadingCards} cards={cards} transferID={transferID}  amount={amount} gateways={gateways}
                                loadingGateways={loadingGateways} goToOffline={() => {this.handleChange('', 2)}}/>
                </DepositTabs>
                {/*<DepositTabs disableRipple value={activeTab} index={1} >*/}
                {/*    <CardDirect loadingCards={loadingCards} cards={cards} transferID={transferID} amount={amount} gateways={gateways} loadingGateways={loadingGateways}/>*/}
                {/*</DepositTabs>*/}
                <DepositTabs disableRipple value={activeTab} index={1} >
                    <IdDeposit depositIdentities={depositIdentities} loadingCards={loadingCards} cards={cards} transferID={transferID} amount={amount} gateways={gateways} loadingGateways={loadingGateways}/>

                </DepositTabs>
                <DepositTabs disableRipple value={activeTab} index={2} >
                    <OfflineForm depositIdentities={depositIdentities} loadingCards={loadingCards} cards={cards} transferID={transferID} amount={amount} gateways={gateways} loadingGateways={loadingGateways}/>
                </DepositTabs>

            </div>
        );
    }
}

const mapStateToProps = ({creditCard, wallet, currency, auth}) => {
    const{currentUser} = auth;
    const {selectedOrder} = currency;
    const {cards, loadingCards} = creditCard;
    const {gateways, loadingGateways, depositIdentities} = wallet;
    return {cards, loadingCards, gateways, loadingGateways, selectedOrder, currentUser, depositIdentities}
};

export default connect(mapStateToProps, {getCardsList, getGateways})(withStyles(styles)(DepositTabsSection));

import {
    CHANGE_CONTENT_FIXED,
    CHANGE_DIRECTION,
    CHANGE_NAVIGATION_STYLE,
    COMMON_WALLET_ADD_DONE,
    COMMON_WALLET_ADDED,
    COMMON_WALLET_ADDING,
    COMMON_WALLET_REMOVE_DONE,
    COMMON_WALLET_REMOVED,
    COMMON_WALLET_REMOVING,
    COMMON_WALLETS_LIST_FETCHED,
    DARK_THEME,
    DRAWER_TYPE,
    FETCH_ERROR,
    FETCH_SUCCESS,
    FETCHING_USER_SCORES,
    FETCHING_USER_SCORES_FAILED,
    FETCHING_WALLET_ADDRESSES_LIST,
    HORIZONTAL_MENU_POSITION,
    PASSWORD_CHANGED,
    PASSWORD_CHANGING,
    PASSWORD_CHANGING_FAILED,
    SWITCH_ADD_WALLET_DIALOG,
    SWITCH_DELETE_WALLET_DIALOG,
    SWITCH_LANGUAGE,
    THEME_COLOR,
    TOGGLE_COLLAPSED_NAV,
    TWO_STEP_UPDATED,
    TWO_STEP_UPDATING,
    USER_SCORES_FETCHED,
    USER_TOKEN_EXPIRE,
    WINDOW_WIDTH
} from '../constants/ActionTypes';


import axios from "../util/Api";
import {ADD_WALLET_ADDRESS, COMMON_WALLETS_LIST, DELETE_WALLET_ADDRESS, SETTINGS} from "../constants/Units";


export const toggleContentFixed = (isContentFixed) => {
    return (dispatch) => {
        dispatch( {type: CHANGE_CONTENT_FIXED, payload: isContentFixed});
    }
}

export function toggleCollapsedNav(isNavCollapsed) {
    return {type: TOGGLE_COLLAPSED_NAV, isNavCollapsed};
}

export function setDrawerType(drawerType) {
    return {type: DRAWER_TYPE, drawerType};
}

export function updateWindowWidth(width) {
    return {type: WINDOW_WIDTH, width};
}

export function setThemeColor(color) {
    return {type: THEME_COLOR, color};
}

export function setDarkTheme() {
    return {type: DARK_THEME};
}

export function changeDirection() {
    return {type: CHANGE_DIRECTION};
}

export function changeNavigationStyle(layoutType) {
    return {
        type: CHANGE_NAVIGATION_STYLE,
        payload: layoutType
    };
}

export function setHorizontalMenuPosition(navigationPosition) {
    return {
        type: HORIZONTAL_MENU_POSITION,
        payload: navigationPosition
    };
}

export function switchLanguage(locale) {
    return {
        type: SWITCH_LANGUAGE,
        payload: locale
    };
}

export const updateTwoStep = (props) => {
    return (dispatch) => {
        dispatch({type: TWO_STEP_UPDATING});
        axios.post('auth/change-method', props
        ).then(({data}) => {
            if (data.result) {
                dispatch({type: TWO_STEP_UPDATED, payload: {twoStepMethod: props.method}});
                dispatch({type: FETCH_SUCCESS, payload: {messages: {'two_step': data.status}, unit: SETTINGS}});
                } else{
                    dispatch({type: TWO_STEP_UPDATED, payload: {}});
                    dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: SETTINGS}});
                }
        }).catch(function (error) {
            if(error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else if(error.response && error.response.status)
                dispatch({type: FETCH_ERROR, payload: {errors: {server :error.response.status}, unit: SETTINGS}});
            else
                dispatch({type: FETCH_ERROR, payload: {server :error.message}});
        });
    }

};

export const updatePassword = (props) => {
    return (dispatch) => {
        dispatch({type: PASSWORD_CHANGING});
        axios.post('auth/update_password', props
        ).then(({data}) => {
            if (data.result) {
                dispatch({type: PASSWORD_CHANGED, payload: {twoStepMethod: props.method}});
                dispatch({type: FETCH_SUCCESS, payload: {messages: {'two_step': data.status}, unit: SETTINGS}});
            } else{
                dispatch({type: PASSWORD_CHANGING_FAILED, payload: {}});
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: SETTINGS}});
            }
        }).catch(function (error) {
            dispatch({type: PASSWORD_CHANGING_FAILED, payload: {}});
            if(error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else if(error.response && error.response.status)
                dispatch({type: FETCH_ERROR, payload: {errors: {server :error.response.status}, unit: SETTINGS}});
            else
                dispatch({type: FETCH_ERROR, payload: {server :error.message}});
        });
    }

};

export const getCommonWalletsList = ({type, page, count}) => {
    return (dispatch) => {
        dispatch({type: FETCHING_WALLET_ADDRESSES_LIST});
        axios.get('fav_addresses' , {
                params: {
                    type: type,
                    page: page,
                    count: count
                }
            }
        ).then(({data}) => {
            if (data.result) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({type: COMMON_WALLETS_LIST_FETCHED, payload: {commonWallets: data.records, totalCommonWallets: data.count}});
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: COMMON_WALLETS_LIST}});
            }
        }).catch(function (error) {
            if(error.response && error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else {
                dispatch({
                    type: FETCH_ERROR,
                    payload: {errors: {serverError: error.message}, unit: COMMON_WALLETS_LIST}
                });
                dispatch({
                    type: COMMON_WALLETS_LIST_FETCHED,
                    payload: {commonWallets: []}
                });
            }
        });
    }

};

export const getUserScores = () => {
    // console.log("user scores")
    return (dispatch) => {
        dispatch({type: FETCHING_USER_SCORES});
        axios.get( 'scores/info',
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }
        ).then(({data}) => {

            if (data.result) {
                // dispatch({type: FETCH_SUCCESS, payload: {messages: {'shopping_buy': data.status}, unit: USER_SCORES}});
                dispatch({type: USER_SCORES_FETCHED, payload: {scores: data}});
            } else {
                dispatch({type: FETCHING_USER_SCORES_FAILED});
            }
        }).catch(function (error) {
            if(error.response && error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }
            dispatch({type: FETCHING_USER_SCORES_FAILED});
        });
    }
};

export const addCommonWallet = (props) => {
    return (dispatch) => {
        dispatch({type: COMMON_WALLET_ADDING});
        axios.post('fav_addresses', props
        ).then(({data}) => {

            if (data.result) {
                dispatch({type: FETCH_SUCCESS, payload: {messages: {'newAddress': data.message}, unit: ADD_WALLET_ADDRESS}});
                dispatch({type: COMMON_WALLET_ADDED, payload: data.fav_address});
                if (props.redraw) {
                    dispatch(getCommonWalletsList({type: null, page: 1, count: 100}));
                }
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: ADD_WALLET_ADDRESS}});
            }
            dispatch({type: COMMON_WALLET_ADD_DONE});
        }).catch(function (error) {
            dispatch({type: COMMON_WALLET_ADD_DONE });
            if(error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else
                dispatch({type: FETCH_ERROR, payload: {errors: {serverError :error.message}, unit: ADD_WALLET_ADDRESS}});
        });
    }

};

export const deleteCommonWallet = (id) => {
    return (dispatch) => {
        dispatch({type: COMMON_WALLET_REMOVING, payload: {address_id: id}});
        axios.delete('fav_addresses/' + id, {address_id: id}
        ).then(({data}) => {

            if (data.result) {
                dispatch({type: FETCH_SUCCESS, payload: {messages: {'remove_address': data.message}, unit: DELETE_WALLET_ADDRESS}});
                dispatch({type: COMMON_WALLET_REMOVED, payload: data.card});
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: DELETE_WALLET_ADDRESS}});
            }
            dispatch({type: COMMON_WALLET_REMOVE_DONE});
        }).catch(function (error) {
            dispatch({type: COMMON_WALLET_REMOVE_DONE});
            if(error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else
                dispatch({type: FETCH_ERROR, payload: {errors: {serverError :error.message}, unit: DELETE_WALLET_ADDRESS}});
        });
    }

};

export const switchWalletDeleteDialog = (state) => {
    console.log("delete wallet state", state)
    return (dispatch) => {
        dispatch({type: SWITCH_DELETE_WALLET_DIALOG, payload: state});
    }
};

export const switchAddWalletDialog = (state) => {
    console.log("add wallet dialog", state)
    return (dispatch) => {
        dispatch({type: SWITCH_ADD_WALLET_DIALOG, payload: state});
    }
};


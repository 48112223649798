import {
    EMAIL_CODE_SENT,
    EMAIL_VERIFIED,
    FETCH_DONE,
    FETCHING_DATA,
    LAST_AUTH_FETCHED,
    PHONE_CODE_SENT,
    PHONE_VERIFIED,
    SET_AUTH_STEP,
    UPLOAD_PROGRESS
} from "../constants/ActionTypes";

const INIT_STATE = {
    submitting: false,
    success: false,
    activeStep: 0,
    formToken: '',
    progress: 0,
    lastAuthRequest: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case UPLOAD_PROGRESS: {
            return {...state, progress: action.payload.progress };
        }
        case LAST_AUTH_FETCHED: {
            return {...state, lastAuthRequest: action.payload.data ?? {} };
        }
        case FETCHING_DATA: {
            return {...state, submitting: true };
        }
        case FETCH_DONE: {
            return {...state, submitting: false };
        }
        case EMAIL_CODE_SENT: {
            return {...state, submitting: false, success: true, activeStep: 1, formToken: action.payload.formToken};
        }
        case SET_AUTH_STEP: {
            return {...state, activeStep: action.payload.activeStep};
        }

        case EMAIL_VERIFIED: {
            return {...state, submitting: false, success: true, activeStep: 2, formToken: ''};
        }
        case PHONE_CODE_SENT: {
            return {...state, submitting: false, success: true, activeStep: 1, formToken: action.payload.formToken};
        }
        case PHONE_VERIFIED: {
            return {...state, submitting: false, success: true, activeStep: 2, formToken: ''};
        }
        default:
            return state;

    }
}

